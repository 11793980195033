.default-theme .upp__sidebar-wrapper {
  background-color: #1d1d1d; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-item {
    border-top: 1px solid #b6b6b6; }
    .default-theme .upp__sidebar-wrapper .upp__sidebar-item:first-child {
      border-top: none; }
  .default-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
  .default-theme .upp__sidebar-wrapper .upp__sidebar-header,
  .default-theme .upp__sidebar-wrapper .upp__sidebar-search input,
  .default-theme .upp__sidebar-wrapper .upp__sidebar-search i {
    color: #f7f7f7; }
  .default-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
  .default-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
    color: #d8d8d8; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
  .default-theme .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
    background-color: #2b2b2b;
    border: none;
    box-shadow: none;
    margin-left: 1px; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover i,
  .default-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover:before,
  .default-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active a i {
    color: #f19a2d; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
    background-color: #2b2b2b; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
    background-color: #2b2b2b; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-menu .header-menu span {
    color: #868686; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-footer {
    background-color: #2b2b2b;
    box-shadow: 0px -1px 5px #1d1d1d;
    border-top: 1px solid #b6b6b6; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:first-child {
    border-left: none; }
  .default-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:last-child {
    border-right: none; }

.default-theme.toggled #close-sidebar {
  color: #f7f7f7; }

.default-theme.toggled #close-sidebar:hover {
  color: #d8d8d8; }

.default-theme .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.default-theme .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.default-theme .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #525965;
  border-radius: 0; }

.default-theme .mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper:before {
  background-color: rgba(42, 42, 42, 0.9); }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-header,
.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input,
.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search i {
  color: white; }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
  color: #f2f2f2; }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-item {
  border-color: #d0d0d0; }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
  background-color: rgba(81, 81, 81, 0.5); }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
  background-color: rgba(81, 81, 81, 0.5); }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
  background-color: rgba(81, 81, 81, 0.5); }

.default-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-footer {
  background-color: rgba(56, 56, 56, 0.5);
  box-shadow: 0px -1px 5px rgba(29, 29, 29, 0.8);
  border-top: 1px solid #c3c3c3; }

.chiller-theme .upp__sidebar-wrapper {
  background-color: #2A2C2B; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-item {
    border-top: 1px solid #374140; }
    .chiller-theme .upp__sidebar-wrapper .upp__sidebar-item:first-child {
      border-top: none; }
  .chiller-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-header,
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-search input,
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-search i {
    color: #D9CB9E; }
  .chiller-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
    color: #ffe79a; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
    background-color: #374140;
    border: none;
    box-shadow: none;
    margin-left: 1px; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover i,
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover:before,
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active a i {
    color: #ffbe00; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
    background-color: #374140; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
    background-color: #374140; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-menu .header-menu span {
    color: #a29464; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-footer {
    background-color: #374140;
    box-shadow: 0px -1px 5px #2A2C2B;
    border-top: 1px solid #374140; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:first-child {
    border-left: none; }
  .chiller-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:last-child {
    border-right: none; }

.chiller-theme.toggled #close-sidebar {
  color: #D9CB9E; }

.chiller-theme.toggled #close-sidebar:hover {
  color: #ffe79a; }

.chiller-theme .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.chiller-theme .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.chiller-theme .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #525965;
  border-radius: 0; }

.chiller-theme .mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper:before {
  background-color: rgba(54, 57, 56, 0.9); }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-header,
.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input,
.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search i {
  color: #e7dfc3; }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
  color: #fff3cd; }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-item {
  border-color: #4e5d5b; }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
  background-color: rgba(90, 106, 105, 0.5); }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
  background-color: rgba(90, 106, 105, 0.5); }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
  background-color: rgba(90, 106, 105, 0.5); }

.chiller-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-footer {
  background-color: rgba(67, 79, 78, 0.5);
  box-shadow: 0px -1px 5px rgba(42, 44, 43, 0.8);
  border-top: 1px solid #434f4e; }

.legacy-theme .upp__sidebar-wrapper {
  background-color: #1e2229; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-item {
    border-top: 1px solid #2e333c; }
    .legacy-theme .upp__sidebar-wrapper .upp__sidebar-item:first-child {
      border-top: none; }
  .legacy-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-header,
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-search input,
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-search i {
    color: #818896; }
  .legacy-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
    color: #b8bfce; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
    background-color: #2e333c;
    border: none;
    box-shadow: none;
    margin-left: 1px; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover i,
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover:before,
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active a i {
    color: #16c7ff; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
    background-color: #2e333c; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
    background-color: #2e333c; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-menu .header-menu span {
    color: #646e80; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-footer {
    background-color: #22262d;
    box-shadow: 0px -1px 5px #16191f;
    border-top: 1px solid #2e333c; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:first-child {
    border-left: none; }
  .legacy-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:last-child {
    border-right: none; }

.legacy-theme.toggled #close-sidebar {
  color: #818896; }

.legacy-theme.toggled #close-sidebar:hover {
  color: #b8bfce; }

.legacy-theme .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.legacy-theme .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.legacy-theme .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #525965;
  border-radius: 0; }

.legacy-theme .mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper:before {
  background-color: rgba(41, 46, 56, 0.9); }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-header,
.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input,
.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search i {
  color: #9da2ad; }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
  color: #d6dae3; }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-item {
  border-color: #444c59; }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
  background-color: rgba(79, 88, 103, 0.5); }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
  background-color: rgba(79, 88, 103, 0.5); }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
  background-color: rgba(79, 88, 103, 0.5); }

.legacy-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-footer {
  background-color: rgba(45, 50, 60, 0.5);
  box-shadow: 0px -1px 5px rgba(22, 25, 31, 0.8);
  border-top: 1px solid #393f4a; }

.cool-theme .upp__sidebar-wrapper {
  background-color: #38373D; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-item {
    border-top: 1px solid #46454c; }
    .cool-theme .upp__sidebar-wrapper .upp__sidebar-item:first-child {
      border-top: none; }
  .cool-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-header,
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-search input,
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-search i {
    color: #918F9E; }
  .cool-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
    color: #b3b8c1; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
    background-color: #46454c;
    border: none;
    box-shadow: none;
    margin-left: 1px; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover i,
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover:before,
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active a i {
    color: #fe6fff; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
    background-color: #46454c; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
    background-color: #46454c; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-menu .header-menu span {
    color: #747479; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-footer {
    background-color: #414046;
    box-shadow: 0px -1px 5px #2a292d;
    border-top: 1px solid #46454c; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:first-child {
    border-left: none; }
  .cool-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:last-child {
    border-right: none; }

.cool-theme.toggled #close-sidebar {
  color: #918F9E; }

.cool-theme.toggled #close-sidebar:hover {
  color: #b3b8c1; }

.cool-theme .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.cool-theme .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.cool-theme .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #918F9E;
  border-radius: 0; }

.cool-theme .mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper:before {
  background-color: rgba(68, 67, 74, 0.9); }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-header,
.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input,
.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search i {
  color: #acaab6; }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
  color: #cfd2d8; }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-item {
  border-color: #5f5d67; }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
  background-color: rgba(107, 105, 116, 0.5); }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
  background-color: rgba(107, 105, 116, 0.5); }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
  background-color: rgba(107, 105, 116, 0.5); }

.cool-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-footer {
  background-color: rgba(77, 76, 83, 0.5);
  box-shadow: 0px -1px 5px rgba(42, 41, 45, 0.8);
  border-top: 1px solid #525159; }

.ice-theme .upp__sidebar-wrapper {
  background-color: #2B3A42; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-item {
    border-top: 1px solid #3a4d56; }
    .ice-theme .upp__sidebar-wrapper .upp__sidebar-item:first-child {
      border-top: none; }
  .ice-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-header,
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-search input,
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-search i {
    color: #9eb7c3; }
  .ice-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
    color: #EFEFEF; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
    background-color: #3a4d56;
    border: none;
    box-shadow: none;
    margin-left: 1px; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover i,
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover:before,
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active a i {
    color: #38fbc7; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
    background-color: #3a4d56; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
    background-color: #3a4d56; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-menu .header-menu span {
    color: #6c7b88; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-footer {
    background-color: #2f3f48;
    box-shadow: 0px -1px 5px #2b3a42;
    border-top: 1px solid #3a4d56; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:first-child {
    border-left: none; }
  .ice-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:last-child {
    border-right: none; }

.ice-theme.toggled #close-sidebar {
  color: #9eb7c3; }

.ice-theme.toggled #close-sidebar:hover {
  color: #EFEFEF; }

.ice-theme .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.ice-theme .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.ice-theme .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #8998a5;
  border-radius: 0; }

.ice-theme .mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper:before {
  background-color: rgba(53, 72, 81, 0.9); }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-header,
.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input,
.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search i {
  color: #beced6; }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
  color: white; }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-item {
  border-color: #4f6874; }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
  background-color: rgba(89, 118, 132, 0.5); }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
  background-color: rgba(89, 118, 132, 0.5); }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
  background-color: rgba(89, 118, 132, 0.5); }

.ice-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-footer {
  background-color: rgba(57, 77, 87, 0.5);
  box-shadow: 0px -1px 5px rgba(43, 58, 66, 0.8);
  border-top: 1px solid #445b65; }

.light-theme .upp__sidebar-wrapper {
  background-color: #ececec; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-item {
    border-top: 1px solid #f9f9f9; }
    .light-theme .upp__sidebar-wrapper .upp__sidebar-item:first-child {
      border-top: none; }
  .light-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
  .light-theme .upp__sidebar-wrapper .upp__sidebar-header,
  .light-theme .upp__sidebar-wrapper .upp__sidebar-search input,
  .light-theme .upp__sidebar-wrapper .upp__sidebar-search i {
    color: #74726E; }
  .light-theme .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
  .light-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
    color: #424242; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
  .light-theme .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
    background-color: #f9f9f9;
    border: none;
    box-shadow: none;
    margin-left: 1px; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover i,
  .light-theme .upp__sidebar-wrapper .upp__sidebar-menu a:hover:before,
  .light-theme .upp__sidebar-wrapper .upp__sidebar-menu li.active a i {
    color: #00a9fd; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
    background-color: #f9f9f9; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
    background-color: #f9f9f9; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-menu .header-menu span {
    color: #6c7b88; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-footer {
    background-color: #f9f9f9;
    box-shadow: 0px -1px 5px #ececec;
    border-top: 1px solid #f9f9f9; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:first-child {
    border-left: none; }
  .light-theme .upp__sidebar-wrapper .upp__sidebar-footer > div:last-child {
    border-right: none; }

.light-theme.toggled #close-sidebar {
  color: #74726E; }

.light-theme.toggled #close-sidebar:hover {
  color: #424242; }

.light-theme .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.light-theme .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.light-theme .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #A4A29E;
  border-radius: 0; }

.light-theme .mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper:before {
  background-color: rgba(249, 249, 249, 0.9); }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item),
.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-header,
.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input,
.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search i {
  color: #8e8c87; }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper a:not(.dropdown-item):not(.bp3-menu-item):hover,
.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu li.active > a {
  color: #5c5c5c; }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-item {
  border-color: white; }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search input.search-menu,
.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-search .input-group-text {
  background-color: rgba(255, 255, 255, 0.5); }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown div {
  background-color: rgba(255, 255, 255, 0.5); }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
  background-color: rgba(255, 255, 255, 0.5); }

.light-theme.upp__sidebar-bg .upp__sidebar-wrapper .upp__sidebar-footer {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px -1px 5px rgba(236, 236, 236, 0.8);
  border-top: 1px solid white; }
