@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*!

Copyright 2015-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400;
  color: #182026;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif; }

p {
  margin-top: 0;
  margin-bottom: 10px; }

small {
  font-size: 12px; }

strong {
  font-weight: 600; }

::selection {
  background: rgba(125, 188, 255, 0.6); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
Headings

Markup:
<div>
  <h1 class="@ns-heading">H1 heading</h1>
  <h2 class="@ns-heading">H2 heading</h2>
  <h3 class="@ns-heading">H3 heading</h3>
  <h4 class="@ns-heading">H4 heading</h4>
  <h5 class="@ns-heading">H5 heading</h5>
  <h6 class="@ns-heading">H6 heading</h6>
</div>

Styleguide headings
*/
.bp3-heading {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0; }
  .bp3-dark .bp3-heading {
    color: #f5f8fa; }

h1.bp3-heading, .bp3-running-text h1 {
  line-height: 40px;
  font-size: 36px; }

h2.bp3-heading, .bp3-running-text h2 {
  line-height: 32px;
  font-size: 28px; }

h3.bp3-heading, .bp3-running-text h3 {
  line-height: 25px;
  font-size: 22px; }

h4.bp3-heading, .bp3-running-text h4 {
  line-height: 21px;
  font-size: 18px; }

h5.bp3-heading, .bp3-running-text h5 {
  line-height: 19px;
  font-size: 16px; }

h6.bp3-heading, .bp3-running-text h6 {
  line-height: 16px;
  font-size: 14px; }

/*
UI text

Markup:
<div class="{{.modifier}}">
  More than a decade ago, we set out to create products that would transform
  the way organizations use their data. Today, our products are deployed at
  the most critical government, commercial, and non-profit institutions in
  the world to solve problems we hadn’t even dreamed of back then.
</div>

.bp3-ui-text - Default Blueprint font styles, applied to the `<body>` tag and available as a class for nested resets.
.bp3-monospace-text - Use a monospace font (ideal for code).
.bp3-running-text - Increase line height ideal for longform text. See [Running text](#core/typography.running-text) below for additional features.
.bp3-text-large - Use a larger font size.
.bp3-text-small - Use a smaller font size.
.bp3-text-muted - Change text color to a gentler gray.
.bp3-text-disabled - Change text color to a transparent, faded gray.
.bp3-text-overflow-ellipsis - Truncate a single line of text with an ellipsis if it overflows its container.

Styleguide ui-text
*/
.bp3-ui-text {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400; }

.bp3-monospace-text {
  text-transform: none;
  font-family: monospace; }

.bp3-text-muted {
  color: #5c7080; }
  .bp3-dark .bp3-text-muted {
    color: #bfccd6; }

.bp3-text-disabled {
  color: rgba(92, 112, 128, 0.5); }
  .bp3-dark .bp3-text-disabled {
    color: rgba(191, 204, 214, 0.5); }

.bp3-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

/*
Running text

Markup:
<div class="@ns-running-text {{.modifier}}">
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.
  </p>
  <ul>
    <li>Item the <code>first</code>.</li>
    <li>Item the <strong>second</strong></code>.</li>
    <li>Item the <a href="#core/typography.running-text">third</a>.</li>
  </ul>
  <h3>New section</h3>
  <p>
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
    nisi ut aliquip ex ea commodo consequat.
  </p>
</div>

.bp3-text-large - Use larger font size.

Styleguide running-text
*/
.bp3-running-text {
  line-height: 1.5;
  font-size: 14px; }
  .bp3-running-text h1 {
    color: #182026;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px; }
    .bp3-dark .bp3-running-text h1 {
      color: #f5f8fa; }
  .bp3-running-text h2 {
    color: #182026;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px; }
    .bp3-dark .bp3-running-text h2 {
      color: #f5f8fa; }
  .bp3-running-text h3 {
    color: #182026;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px; }
    .bp3-dark .bp3-running-text h3 {
      color: #f5f8fa; }
  .bp3-running-text h4 {
    color: #182026;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px; }
    .bp3-dark .bp3-running-text h4 {
      color: #f5f8fa; }
  .bp3-running-text h5 {
    color: #182026;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px; }
    .bp3-dark .bp3-running-text h5 {
      color: #f5f8fa; }
  .bp3-running-text h6 {
    color: #182026;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px; }
    .bp3-dark .bp3-running-text h6 {
      color: #f5f8fa; }
  .bp3-running-text hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .bp3-dark .bp3-running-text hr {
      border-color: rgba(255, 255, 255, 0.15); }
  .bp3-running-text p {
    margin: 0 0 10px;
    padding: 0; }

.bp3-text-large {
  font-size: 16px; }

.bp3-text-small {
  font-size: 12px; }

/*
Links

Simply use an `<a href="">` tag as you normally would. No class is necessary for Blueprint styles.
Links are underlined only when hovered.

Putting an icon inside a link will cause it to inherit the link's text color.

Styleguide typography.links
*/
a {
  text-decoration: none;
  color: #106ba3; }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #106ba3; }
  a .bp3-icon, a .bp3-icon-standard, a .bp3-icon-large {
    color: inherit; }
  a code,
  .bp3-dark a code {
    color: inherit; }
  .bp3-dark a,
  .bp3-dark a:hover {
    color: #48aff0; }
    .bp3-dark a .bp3-icon, .bp3-dark a .bp3-icon-standard, .bp3-dark a .bp3-icon-large,
    .bp3-dark a:hover .bp3-icon,
    .bp3-dark a:hover .bp3-icon-standard,
    .bp3-dark a:hover .bp3-icon-large {
      color: inherit; }

/*
Preformatted text

Markup:
<div>
  <p>Use the <code class="@ns-code">&lt;code></code> tag for snippets of code.</p>
  <pre class="@ns-code-block">Use the &lt;pre> tag for blocks of code.</pre>
  <pre class="@ns-code-block"><code>// code sample
export function hasModifier(
  modifiers: ts.ModifiersArray,
  ...modifierKinds: ts.SyntaxKind[],
) {
  if (modifiers == null || modifierKinds == null) {
    return false;
  }
  return modifiers.some(m => modifierKinds.some(k => m.kind === k));
}</code></pre>
</div>

Styleguide preformatted
*/
.bp3-running-text code, .bp3-code {
  text-transform: none;
  font-family: monospace;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.7);
  padding: 2px 5px;
  color: #5c7080;
  font-size: smaller; }
  .bp3-dark .bp3-running-text code, .bp3-running-text .bp3-dark code, .bp3-dark .bp3-code {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.3);
    color: #bfccd6; }
  .bp3-running-text a > code, a > .bp3-code {
    color: #137cbd; }
    .bp3-dark .bp3-running-text a > code, .bp3-running-text .bp3-dark a > code, .bp3-dark a > .bp3-code {
      color: inherit; }

.bp3-running-text pre, .bp3-code-block {
  text-transform: none;
  font-family: monospace;
  display: block;
  margin: 10px 0;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 0.7);
  padding: 13px 15px 12px;
  line-height: 1.4;
  color: #182026;
  font-size: 13px;
  word-break: break-all;
  word-wrap: break-word; }
  .bp3-dark .bp3-running-text pre, .bp3-running-text .bp3-dark pre, .bp3-dark .bp3-code-block {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.3);
    color: #f5f8fa; }
  .bp3-running-text pre > code, .bp3-code-block > code {
    box-shadow: none;
    background: none;
    padding: 0;
    color: inherit;
    font-size: inherit; }

.bp3-running-text kbd, .bp3-key {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  min-width: 24px;
  height: 24px;
  padding: 3px 6px;
  vertical-align: middle;
  line-height: 24px;
  color: #5c7080;
  font-family: inherit;
  font-size: 12px; }
  .bp3-running-text kbd .bp3-icon, .bp3-key .bp3-icon, .bp3-running-text kbd .bp3-icon-standard, .bp3-key .bp3-icon-standard, .bp3-running-text kbd .bp3-icon-large, .bp3-key .bp3-icon-large {
    margin-right: 5px; }
  .bp3-dark .bp3-running-text kbd, .bp3-running-text .bp3-dark kbd, .bp3-dark .bp3-key {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.4);
    background: #394b59;
    color: #bfccd6; }

/*
Block quotes

Markup:
<blockquote class="@ns-blockquote">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
  ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
  ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
</blockquote>

Styleguide blockquote
*/
.bp3-running-text blockquote, .bp3-blockquote {
  margin: 0 0 10px;
  border-left: solid 4px rgba(167, 182, 194, 0.5);
  padding: 0 20px; }
  .bp3-dark .bp3-running-text blockquote, .bp3-running-text .bp3-dark blockquote, .bp3-dark .bp3-blockquote {
    border-color: rgba(115, 134, 148, 0.5); }

/*
Lists

Markup:
<ol class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>
    Item the third
    <ul class="{{.modifier}}">
      <li>Item the fourth</li>
      <li>Item the fifth</li>
    </ul>
  </li>
</ol>

.bp3-list - Add a little spacing between items for readability.
.bp3-list-unstyled - Remove all list styling (including indicators) so you can add your own.

Styleguide lists
*/
.bp3-running-text ul,
.bp3-running-text ol, .bp3-list {
  margin: 10px 0;
  padding-left: 30px; }
  .bp3-running-text ul li:not(:last-child), .bp3-running-text ol li:not(:last-child), .bp3-list li:not(:last-child) {
    margin-bottom: 5px; }
  .bp3-running-text ul ol, .bp3-running-text ol ol, .bp3-list ol,
  .bp3-running-text ul ul,
  .bp3-running-text ol ul,
  .bp3-list ul {
    margin-top: 5px; }

.bp3-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none; }
  .bp3-list-unstyled li {
    padding: 0; }

/*
Right-to-left text

Markup:
<h5 class="@ns-heading">Arabic:</h5>
<p class="@ns-rtl">
  لكل لأداء بمحاولة من. مدينة الواقعة يبق أي, وإعلان وقوعها، حول كل, حدى عجّل مشروط الخاسرة قد.
  من الذود تكبّد بين, و لها واحدة الأراضي. عل الصفحة والروسية يتم, أي للحكومة استعملت شيء. أم وصل زهاء اليا
</p>
<h5 class="@ns-heading">Hebrew:</h5>
<p class="@ns-rtl">
  כדי על עזרה יידיש הבהרה, מלא באגים טכניים דת. תנך או ברית ביולי. כתב בה הטבע למנוע, דת כלים פיסיקה החופשית זכר.
  מתן החלל מאמרשיחהצפה ב. הספרות אנציקלופדיה אם זכר, על שימושי שימושיים תאולוגיה עזה
</p>

Styleguide rtl
*/
.bp3-rtl {
  text-align: right; }

.bp3-dark {
  color: #f5f8fa; }

:focus {
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px; }

.bp3-focus-disabled :focus {
  outline: none !important; }
  .bp3-focus-disabled :focus ~ .bp3-control-indicator {
    outline: none !important; }

.bp3-alert {
  max-width: 400px;
  padding: 20px; }

.bp3-alert-body {
  display: flex; }
  .bp3-alert-body .bp3-icon {
    margin-top: 0;
    margin-right: 20px;
    font-size: 40px; }

.bp3-alert-footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px; }
  .bp3-alert-footer .bp3-button {
    margin-left: 10px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
Breadcrumbs

Markup:
<ul class="bp3-breadcrumbs">
  <li><a class="bp3-breadcrumbs-collapsed" href="#"></a></li>
  <li><a class="bp3-breadcrumb bp3-disabled">Folder one</a></li>
  <li><a class="bp3-breadcrumb" href="#">Folder two</a></li>
  <li><a class="bp3-breadcrumb" href="#">Folder three</a></li>
  <li><span class="bp3-breadcrumb bp3-breadcrumb-current">File</span></li>
</ul>

Styleguide breadcrumbs
*/
.bp3-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  cursor: default;
  height: 30px;
  padding: 0;
  list-style: none; }
  .bp3-breadcrumbs > li {
    display: flex;
    align-items: center; }
    .bp3-breadcrumbs > li::after {
      display: block;
      margin: 0 5px;
      background: svg-icon("16px/chevron-right.svg", (path: (fill: #5c7080)));
      width: 16px;
      height: 16px;
      content: ""; }
    .bp3-breadcrumbs > li:last-of-type::after {
      display: none; }

.bp3-breadcrumb,
.bp3-breadcrumb-current,
.bp3-breadcrumbs-collapsed {
  display: inline-block;
  font-size: 16px; }

.bp3-breadcrumb,
.bp3-breadcrumbs-collapsed {
  color: #5c7080; }

.bp3-breadcrumb:hover {
  text-decoration: none; }

.bp3-breadcrumb.bp3-disabled {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.bp3-breadcrumb-current {
  color: inherit;
  font-weight: 600; }
  .bp3-breadcrumb-current .bp3-input {
    vertical-align: baseline;
    font-size: inherit;
    font-weight: inherit; }

.bp3-breadcrumbs-collapsed {
  margin-right: 2px;
  border: none;
  border-radius: 3px;
  background: #ced9e0;
  cursor: pointer;
  padding: 1px 5px;
  vertical-align: text-bottom; }
  .bp3-breadcrumbs-collapsed::before {
    display: block;
    background: svg-icon("16px/more.svg", (circle: (fill: #5c7080))) center no-repeat;
    width: 16px;
    height: 16px;
    content: ""; }
  .bp3-breadcrumbs-collapsed:hover {
    background: #bfccd6;
    text-decoration: none;
    color: #182026; }

.bp3-dark .bp3-breadcrumb,
.bp3-dark .bp3-breadcrumbs-collapsed {
  color: #bfccd6; }

.bp3-dark .bp3-breadcrumbs > li::after {
  color: #bfccd6; }

.bp3-dark .bp3-breadcrumb.bp3-disabled {
  color: rgba(191, 204, 214, 0.5); }

.bp3-dark .bp3-breadcrumb-current {
  color: #f5f8fa; }

.bp3-dark .bp3-breadcrumbs-collapsed {
  background: rgba(0, 0, 0, 0.4); }
  .bp3-dark .bp3-breadcrumbs-collapsed:hover {
    background: rgba(0, 0, 0, 0.6);
    color: #f5f8fa; }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button

Markup:
<a role="button" class="bp3-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="bp3-button bp3-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.bp3-active - Active appearance
.bp3-disabled - Disabled appearance
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger - Danger intent
.bp3-minimal - More subtle appearance
.bp3-large - Larger size
.bp3-small - Smaller size
.bp3-fill - Fill parent container

Styleguide button
*/
.bp3-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px; }
  .bp3-button > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-button > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-button::before,
  .bp3-button > * {
    margin-right: 7px; }
  .bp3-button:empty::before,
  .bp3-button > :last-child {
    margin-right: 0; }
  .bp3-button:empty {
    padding: 0 !important; }
  .bp3-button:disabled, .bp3-button.bp3-disabled {
    cursor: not-allowed; }
  .bp3-button.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-button.bp3-align-right,
  .bp3-align-right .bp3-button {
    text-align: right; }
  .bp3-button:not([class*="bp3-intent-"]) {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    background-color: #f5f8fa;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    color: #182026; }
    .bp3-button:not([class*="bp3-intent-"]):hover {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
      background-clip: padding-box;
      background-color: #ebf1f5; }
    .bp3-button:not([class*="bp3-intent-"]):active, .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #d8e1e8;
      background-image: none; }
    .bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
      outline: none;
      box-shadow: none;
      background-color: rgba(206, 217, 224, 0.5);
      background-image: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
      .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active:hover, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active:hover {
        background: rgba(206, 217, 224, 0.7); }
  .bp3-button.bp3-intent-primary {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #137cbd;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #fff; }
    .bp3-button.bp3-intent-primary:hover, .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
      color: #fff; }
    .bp3-button.bp3-intent-primary:hover {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
      background-color: #106ba3; }
    .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #0e5a8a;
      background-image: none; }
    .bp3-button.bp3-intent-primary:disabled, .bp3-button.bp3-intent-primary.bp3-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(19, 124, 189, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-success {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #0f9960;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #fff; }
    .bp3-button.bp3-intent-success:hover, .bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
      color: #fff; }
    .bp3-button.bp3-intent-success:hover {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
      background-color: #0d8050; }
    .bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #0a6640;
      background-image: none; }
    .bp3-button.bp3-intent-success:disabled, .bp3-button.bp3-intent-success.bp3-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(15, 153, 96, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-warning {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #d9822b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #fff; }
    .bp3-button.bp3-intent-warning:hover, .bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
      color: #fff; }
    .bp3-button.bp3-intent-warning:hover {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
      background-color: #bf7326; }
    .bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #a66321;
      background-image: none; }
    .bp3-button.bp3-intent-warning:disabled, .bp3-button.bp3-intent-warning.bp3-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(217, 130, 43, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-danger {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #db3737;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #fff; }
    .bp3-button.bp3-intent-danger:hover, .bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
      color: #fff; }
    .bp3-button.bp3-intent-danger:hover {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
      background-color: #c23030; }
    .bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #a82a2a;
      background-image: none; }
    .bp3-button.bp3-intent-danger:disabled, .bp3-button.bp3-intent-danger.bp3-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(219, 55, 55, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
    stroke: #fff; }
  .bp3-button.bp3-large,
  .bp3-large .bp3-button {
    min-width: 40px;
    min-height: 40px;
    padding: 5px 15px;
    font-size: 16px; }
    .bp3-button.bp3-large::before,
    .bp3-button.bp3-large > *,
    .bp3-large .bp3-button::before,
    .bp3-large .bp3-button > * {
      margin-right: 10px; }
    .bp3-button.bp3-large:empty::before,
    .bp3-button.bp3-large > :last-child,
    .bp3-large .bp3-button:empty::before,
    .bp3-large .bp3-button > :last-child {
      margin-right: 0; }
  .bp3-button.bp3-small,
  .bp3-small .bp3-button {
    min-width: 24px;
    min-height: 24px;
    padding: 0 7px; }
  .bp3-button.bp3-loading {
    position: relative; }
    .bp3-button.bp3-loading[class*="bp3-icon-"]::before {
      visibility: hidden; }
    .bp3-button.bp3-loading .bp3-button-spinner {
      position: absolute;
      margin: 0; }
    .bp3-button.bp3-loading > :not(.bp3-button-spinner) {
      visibility: hidden; }
  .bp3-button[class*="bp3-icon-"]::before {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #5c7080; }
  .bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large {
    color: #5c7080; }
    .bp3-button .bp3-icon.bp3-align-right, .bp3-button .bp3-icon-standard.bp3-align-right, .bp3-button .bp3-icon-large.bp3-align-right {
      margin-left: 7px; }
  .bp3-button .bp3-icon:first-child:last-child,
  .bp3-button .bp3-spinner + .bp3-icon:last-child {
    margin: 0 -7px; }
  .bp3-dark .bp3-button:not([class*="bp3-intent-"]) {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
    background-color: #394b59;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    color: #f5f8fa; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover, .bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      color: #f5f8fa; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
      background-color: #30404d; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #202b33;
      background-image: none; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
      box-shadow: none;
      background-color: rgba(57, 75, 89, 0.5);
      background-image: none;
      color: rgba(191, 204, 214, 0.5); }
      .bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active {
        background: rgba(57, 75, 89, 0.7); }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-button-spinner .bp3-spinner-head {
      background: rgba(0, 0, 0, 0.5);
      stroke: #8a9ba8; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"])[class*="bp3-icon-"]::before {
      color: #bfccd6; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large {
      color: #bfccd6; }
  .bp3-dark .bp3-button[class*="bp3-intent-"] {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:hover {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:active, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-active {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:disabled, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-disabled {
      box-shadow: none;
      background-image: none;
      color: rgba(255, 255, 255, 0.3); }
    .bp3-dark .bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
      stroke: #8a9ba8; }
  .bp3-button:disabled::before,
  .bp3-button:disabled .bp3-icon, .bp3-button:disabled .bp3-icon-standard, .bp3-button:disabled .bp3-icon-large, .bp3-button.bp3-disabled::before,
  .bp3-button.bp3-disabled .bp3-icon, .bp3-button.bp3-disabled .bp3-icon-standard, .bp3-button.bp3-disabled .bp3-icon-large, .bp3-button[class*="bp3-intent-"]::before,
  .bp3-button[class*="bp3-intent-"] .bp3-icon, .bp3-button[class*="bp3-intent-"] .bp3-icon-standard, .bp3-button[class*="bp3-intent-"] .bp3-icon-large {
    color: inherit !important; }
  .bp3-button.bp3-minimal {
    box-shadow: none;
    background: none; }
    .bp3-button.bp3-minimal:hover {
      box-shadow: none;
      background: rgba(167, 182, 194, 0.3);
      text-decoration: none;
      color: #182026; }
    .bp3-button.bp3-minimal:active, .bp3-button.bp3-minimal.bp3-active {
      box-shadow: none;
      background: rgba(115, 134, 148, 0.3);
      color: #182026; }
    .bp3-button.bp3-minimal:disabled, .bp3-button.bp3-minimal:disabled:hover, .bp3-button.bp3-minimal.bp3-disabled, .bp3-button.bp3-minimal.bp3-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
      .bp3-button.bp3-minimal:disabled.bp3-active, .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
        background: rgba(115, 134, 148, 0.3); }
    .bp3-dark .bp3-button.bp3-minimal {
      box-shadow: none;
      background: none;
      color: inherit; }
      .bp3-dark .bp3-button.bp3-minimal:hover, .bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
        box-shadow: none;
        background: none; }
      .bp3-dark .bp3-button.bp3-minimal:hover {
        background: rgba(138, 155, 168, 0.15); }
      .bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
        background: rgba(138, 155, 168, 0.3);
        color: #f5f8fa; }
      .bp3-dark .bp3-button.bp3-minimal:disabled, .bp3-dark .bp3-button.bp3-minimal:disabled:hover, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover {
        background: none;
        cursor: not-allowed;
        color: rgba(191, 204, 214, 0.5); }
        .bp3-dark .bp3-button.bp3-minimal:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
          background: rgba(138, 155, 168, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-primary {
      color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:hover, .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
        box-shadow: none;
        background: none;
        color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15);
        color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(19, 124, 189, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #106ba3; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary {
        color: #48aff0; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #48aff0; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
          background: rgba(19, 124, 189, 0.3);
          color: #48aff0; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(72, 175, 240, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(19, 124, 189, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-success {
      color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:hover, .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
        box-shadow: none;
        background: none;
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:hover, .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
        box-shadow: none;
        background: none;
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-danger {
      color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:hover, .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
        box-shadow: none;
        background: none;
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }

a.bp3-button {
  text-align: center;
  text-decoration: none;
  transition: none; }
  a.bp3-button, a.bp3-button:hover, a.bp3-button:active {
    color: #182026; }
  a.bp3-button.bp3-disabled {
    color: rgba(92, 112, 128, 0.5); }

.bp3-button-text {
  flex: 0 1 auto; }

.bp3-button.bp3-align-left .bp3-button-text, .bp3-button.bp3-align-right .bp3-button-text,
.bp3-button-group.bp3-align-left .bp3-button-text,
.bp3-button-group.bp3-align-right .bp3-button-text {
  flex: 1 1 auto; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button groups

Markup:
<div class="bp3-button-group {{.modifier}}">
  <button type="button" class="bp3-button bp3-intent-success">Save</button>
  <button type="button" class="bp3-button bp3-intent-success bp3-icon-caret-down"></button>
</div>
<div class="bp3-button-group {{.modifier}}">
  <a class="bp3-button bp3-icon-database" tabindex="0" role="button">Queries</a>
  <a class="bp3-button bp3-icon-function" tabindex="0" role="button">Functions</a>
  <a class="bp3-button" tabindex="0" role="button">
    Options <span class="bp3-icon-standard bp3-icon-caret-down bp3-align-right"></span>
  </a>
</div>
<div class="bp3-button-group {{.modifier}}">
  <a class="bp3-button bp3-icon-chart" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-control" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-graph" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-camera" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-map" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-code" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-th" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-time" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-compressed" tabindex="0" role="button"></a>
</div>

.bp3-fill - Buttons expand equally to fill container
.bp3-large - Use large buttons
.bp3-minimal - Use minimal buttons
.bp3-vertical - Vertical layout

Styleguide button-group
*/
.bp3-button-group {
  display: inline-flex;
  /*
  Responsive

  Markup:
  <div class="bp3-button-group bp3-large bp3-fill">
    <a class="bp3-button bp3-intent-primary bp3-fixed" tabindex="0" role="button">Start</a>
    <a class="bp3-button bp3-intent-primary" tabindex="0" role="button">Left</a>
    <a class="bp3-button bp3-intent-primary bp3-active" tabindex="0" role="button">Middle</a>
    <a class="bp3-button bp3-intent-primary" tabindex="0" role="button">Right</a>
    <a class="bp3-button bp3-intent-primary bp3-fixed" tabindex="0" role="button">End</a>
  </div>
  <br />
  <div class="bp3-button-group bp3-fill">
    <button class="bp3-button bp3-icon-arrow-left"></button>
    <button class="bp3-button bp3-fill">Middle</button>
    <button class="bp3-button bp3-icon-arrow-right"></button>
  </div>

  Styleguide button-group}-fill
  */
  /*
  Vertical button groups

  Markup:
  <div class="bp3-button-group bp3-vertical">
    <a class="bp3-button bp3-icon-search-template" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-in" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-out" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-to-fit" role="button" tabindex="0"></a>
  </div>
  <div class="bp3-button-group bp3-vertical">
    <button type="button" class="bp3-button bp3-active">Home</button>
    <button type="button" class="bp3-button">Pages</button>
    <button type="button" class="bp3-button">Blog</button>
    <button type="button" class="bp3-button">Calendar</button>
  </div>
  <div class="bp3-button-group bp3-vertical bp3-align-left bp3-large">
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-document">Text</button>
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-media bp3-active">Media</button>
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-link">Sources</button>
  </div>

  Styleguide button-group-vertical
  */ }
  .bp3-button-group .bp3-button {
    flex: 0 0 auto;
    position: relative;
    z-index: 4; }
    .bp3-button-group .bp3-button:focus {
      z-index: 5; }
    .bp3-button-group .bp3-button:hover {
      z-index: 6; }
    .bp3-button-group .bp3-button:active, .bp3-button-group .bp3-button.bp3-active {
      z-index: 7; }
    .bp3-button-group .bp3-button:disabled, .bp3-button-group .bp3-button.bp3-disabled {
      z-index: 3; }
    .bp3-button-group .bp3-button[class*="bp3-intent-"] {
      z-index: 9; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:focus {
        z-index: 10; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:hover {
        z-index: 11; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:active, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-active {
        z-index: 12; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:disabled, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-disabled {
        z-index: 8; }
  .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:first-child) .bp3-button,
  .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bp3-button-group.bp3-minimal .bp3-button {
    box-shadow: none;
    background: none; }
    .bp3-button-group.bp3-minimal .bp3-button:hover {
      box-shadow: none;
      background: rgba(167, 182, 194, 0.3);
      text-decoration: none;
      color: #182026; }
    .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
      box-shadow: none;
      background: rgba(115, 134, 148, 0.3);
      color: #182026; }
    .bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
      .bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
        background: rgba(115, 134, 148, 0.3); }
    .bp3-dark .bp3-button-group.bp3-minimal .bp3-button {
      box-shadow: none;
      background: none;
      color: inherit; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
        box-shadow: none;
        background: none; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover {
        background: rgba(138, 155, 168, 0.15); }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
        background: rgba(138, 155, 168, 0.3);
        color: #f5f8fa; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
        background: none;
        cursor: not-allowed;
        color: rgba(191, 204, 214, 0.5); }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
          background: rgba(138, 155, 168, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
      color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
        box-shadow: none;
        background: none;
        color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15);
        color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(19, 124, 189, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #106ba3; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
        color: #48aff0; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #48aff0; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
          background: rgba(19, 124, 189, 0.3);
          color: #48aff0; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(72, 175, 240, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(19, 124, 189, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
      color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
        box-shadow: none;
        background: none;
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
        box-shadow: none;
        background: none;
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
      color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
        box-shadow: none;
        background: none;
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }
  .bp3-button-group .bp3-popover-wrapper,
  .bp3-button-group .bp3-popover-target {
    display: flex;
    flex: 1 1 auto; }
  .bp3-button-group.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-button-group .bp3-button.bp3-fill,
  .bp3-button-group.bp3-fill .bp3-button:not(.bp3-fixed) {
    flex: 1 1 auto; }
  .bp3-button-group.bp3-vertical {
    flex-direction: column;
    align-items: stretch;
    vertical-align: top; }
    .bp3-button-group.bp3-vertical.bp3-fill {
      width: unset;
      height: 100%; }
    .bp3-button-group.bp3-vertical .bp3-button {
      margin-right: 0 !important;
      width: 100%; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:first-child .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:first-child {
      border-radius: 3px 3px 0 0; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:last-child .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:last-child {
      border-radius: 0 0 3px 3px; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:not(:last-child) {
      margin-bottom: -1px; }
  .bp3-button-group.bp3-align-left .bp3-button {
    text-align: left; }
  .bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
    margin-right: 1px; }
  .bp3-dark .bp3-button-group.bp3-vertical > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-dark .bp3-button-group.bp3-vertical > .bp3-button:not(:last-child) {
    margin-bottom: 1px; }

/*
Callout

Markup:
<div class="bp3-callout {{.modifier}}">
  <h4 class="bp3-heading">Callout Heading</h4>
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex, delectus!
</div>

.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger  - Danger intent
.bp3-icon-info-sign - With an icon

Styleguide callout
*/
.bp3-callout {
  line-height: 1.5;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  background-color: rgba(138, 155, 168, 0.15);
  width: 100%;
  padding: 10px 12px 9px; }
  .bp3-callout[class*="bp3-icon-"] {
    padding-left: 40px; }
    .bp3-callout[class*="bp3-icon-"]::before {
      line-height: 1;
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: 10px;
      left: 10px;
      color: #5c7080; }
  .bp3-callout.bp3-callout-icon {
    padding-left: 40px; }
    .bp3-callout.bp3-callout-icon > .bp3-icon:first-child {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #5c7080; }
  .bp3-callout .bp3-heading {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 20px; }
    .bp3-callout .bp3-heading:last-child {
      margin-bottom: 0; }
  .bp3-dark .bp3-callout {
    background-color: rgba(138, 155, 168, 0.2); }
    .bp3-dark .bp3-callout[class*="bp3-icon-"]::before {
      color: #bfccd6; }
  .bp3-callout.bp3-intent-primary {
    background-color: rgba(19, 124, 189, 0.15); }
    .bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-primary .bp3-heading {
      color: #106ba3; }
    .bp3-dark .bp3-callout.bp3-intent-primary {
      background-color: rgba(19, 124, 189, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-primary .bp3-heading {
        color: #48aff0; }
  .bp3-callout.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.15); }
    .bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-success > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-success .bp3-heading {
      color: #0d8050; }
    .bp3-dark .bp3-callout.bp3-intent-success {
      background-color: rgba(15, 153, 96, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-success > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-success .bp3-heading {
        color: #3dcc91; }
  .bp3-callout.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.15); }
    .bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-warning .bp3-heading {
      color: #bf7326; }
    .bp3-dark .bp3-callout.bp3-intent-warning {
      background-color: rgba(217, 130, 43, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-warning .bp3-heading {
        color: #ffb366; }
  .bp3-callout.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.15); }
    .bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-danger .bp3-heading {
      color: #c23030; }
    .bp3-dark .bp3-callout.bp3-intent-danger {
      background-color: rgba(219, 55, 55, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-danger .bp3-heading {
        color: #ff7373; }
  .bp3-running-text .bp3-callout {
    margin: 20px 0; }

/*
Cards

Markup:
<div class="bp3-card {{.modifier}}">
  We build products that make people better at their most important work.
</div>

.bp3-elevation-0 - Ground floor. This level provides just a gentle border shadow.
.bp3-elevation-1 - First. Subtle drop shadow intended for static containers.
.bp3-elevation-2 - Second. An even stronger shadow, moving on up.
.bp3-elevation-3 - Third. For containers overlaying content temporarily.
.bp3-elevation-4 - Fourth. The strongest shadow, usually for overlay containers on top of backdrops.
.bp3-interactive - On hover, increase elevation and use pointer cursor.

Styleguide card
*/
.bp3-card {
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  padding: 20px;
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-card.bp3-dark,
  .bp3-dark .bp3-card {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4), 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
    background-color: #30404d; }

.bp3-elevation-0 {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0); }
  .bp3-elevation-0.bp3-dark,
  .bp3-dark .bp3-elevation-0 {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4), 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0); }

.bp3-elevation-1 {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.2); }
  .bp3-elevation-1.bp3-dark,
  .bp3-dark .bp3-elevation-1 {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.4); }

.bp3-elevation-2 {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.2); }
  .bp3-elevation-2.bp3-dark,
  .bp3-dark .bp3-elevation-2 {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.4), 0 2px 6px rgba(0, 0, 0, 0.4); }

.bp3-elevation-3 {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.2); }
  .bp3-elevation-3.bp3-dark,
  .bp3-dark .bp3-elevation-3 {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4); }

.bp3-elevation-4 {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2), 0 18px 46px 6px rgba(0, 0, 0, 0.2); }
  .bp3-elevation-4.bp3-dark,
  .bp3-dark .bp3-elevation-4 {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.4), 0 18px 46px 6px rgba(0, 0, 0, 0.4); }

.bp3-card.bp3-interactive:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.2);
  cursor: pointer; }
  .bp3-card.bp3-interactive:hover.bp3-dark,
  .bp3-dark .bp3-card.bp3-interactive:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4); }

.bp3-card.bp3-interactive:active {
  opacity: 0.9;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.2);
  transition-duration: 0; }
  .bp3-card.bp3-interactive:active.bp3-dark,
  .bp3-dark .bp3-card.bp3-interactive:active {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.4); }

.bp3-collapse {
  height: 0;
  overflow-y: hidden;
  transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-collapse .bp3-collapse-body {
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-collapse .bp3-collapse-body[aria-hidden="true"] {
      display: none; }

.bp3-context-menu .bp3-popover-target {
  display: block; }

.bp3-context-menu-popover-target {
  position: fixed; }

.bp3-divider {
  margin: 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .bp3-dark .bp3-divider {
    border-color: rgba(0, 0, 0, 0.4); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Dialog

Markup:
<!-- this container element fills its parent and centers the .bp3-dialog within it -->
<div class="bp3-dialog-container">
  <div class="bp3-dialog">
    <div class="bp3-dialog-header">
      <span class="bp3-icon-large bp3-icon-inbox"></span>
      <h4 class="bp3-heading">Dialog header</h4>
      <button aria-label="Close" class="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"></button>
    </div>
    <div class="bp3-dialog-body">
      This dialog hasn't been wired up with any open or close interactions.
      It's just an example of markup and styles.
    </div>
    <div class="bp3-dialog-footer">
      <div class="bp3-dialog-footer-actions">
        <button type="button" class="bp3-button">Secondary button</button>
        <button type="submit" class="bp3-button bp3-intent-primary">Primary button</button>
      </div>
    </div>
  </div>
</div>

Styleguide dialog
*/
.bp3-dialog-container {
  opacity: 1;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  pointer-events: none;
  user-select: none; }
  .bp3-dialog-container.bp3-overlay-enter > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear > .bp3-dialog {
    opacity: 0;
    transform: scale(0.5); }
  .bp3-dialog-container.bp3-overlay-enter-active > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear-active > .bp3-dialog {
    opacity: 1;
    transform: scale(1);
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .bp3-dialog-container.bp3-overlay-exit > .bp3-dialog {
    opacity: 1;
    transform: scale(1); }
  .bp3-dialog-container.bp3-overlay-exit-active > .bp3-dialog {
    opacity: 0;
    transform: scale(0.5);
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }

.bp3-dialog {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2), 0 18px 46px 6px rgba(0, 0, 0, 0.2);
  background: #ebf1f5;
  width: 500px;
  padding-bottom: 20px;
  pointer-events: all;
  user-select: text; }
  .bp3-dialog:focus {
    outline: 0; }
  .bp3-dialog.bp3-dark,
  .bp3-dark .bp3-dialog {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.4), 0 18px 46px 6px rgba(0, 0, 0, 0.4);
    background: #293742;
    color: #f5f8fa; }

.bp3-dialog-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  min-height: 40px;
  padding-right: 5px;
  padding-left: 20px; }
  .bp3-dialog-header .bp3-icon-large,
  .bp3-dialog-header .bp3-icon {
    flex: 0 0 auto;
    margin-right: 10px;
    color: #5c7080; }
  .bp3-dialog-header .bp3-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    margin: 0;
    line-height: inherit; }
    .bp3-dialog-header .bp3-heading:last-child {
      margin-right: 20px; }
  .bp3-dark .bp3-dialog-header {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    background: #30404d; }
    .bp3-dark .bp3-dialog-header .bp3-icon-large,
    .bp3-dark .bp3-dialog-header .bp3-icon {
      color: #bfccd6; }

.bp3-dialog-body {
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px; }

.bp3-dialog-footer {
  flex: 0 0 auto;
  margin: 0 20px; }

.bp3-dialog-footer-actions {
  display: flex;
  justify-content: flex-end; }
  .bp3-dialog-footer-actions .bp3-button {
    margin-left: 10px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-drawer {
  display: flex;
  flex-direction: column;
  margin: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2), 0 18px 46px 6px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 0; }
  .bp3-drawer:focus {
    outline: 0; }
  .bp3-drawer.bp3-position-top {
    top: 0;
    right: 0;
    left: 0;
    height: 50%; }
    .bp3-drawer.bp3-position-top.bp3-overlay-enter, .bp3-drawer.bp3-position-top.bp3-overlay-appear {
      transform: translateY(-100%); }
    .bp3-drawer.bp3-position-top.bp3-overlay-enter-active, .bp3-drawer.bp3-position-top.bp3-overlay-appear-active {
      transform: translateY(0);
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
    .bp3-drawer.bp3-position-top.bp3-overlay-exit {
      transform: translateY(0); }
    .bp3-drawer.bp3-position-top.bp3-overlay-exit-active {
      transform: translateY(-100%);
      transition-property: transform;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
  .bp3-drawer.bp3-position-bottom {
    right: 0;
    bottom: 0;
    left: 0;
    height: 50%; }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-enter, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear {
      transform: translateY(100%); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-enter-active, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear-active {
      transform: translateY(0);
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-exit {
      transform: translateY(0); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-exit-active {
      transform: translateY(100%);
      transition-property: transform;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
  .bp3-drawer.bp3-position-left {
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%; }
    .bp3-drawer.bp3-position-left.bp3-overlay-enter, .bp3-drawer.bp3-position-left.bp3-overlay-appear {
      transform: translateX(-100%); }
    .bp3-drawer.bp3-position-left.bp3-overlay-enter-active, .bp3-drawer.bp3-position-left.bp3-overlay-appear-active {
      transform: translateX(0);
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
    .bp3-drawer.bp3-position-left.bp3-overlay-exit {
      transform: translateX(0); }
    .bp3-drawer.bp3-position-left.bp3-overlay-exit-active {
      transform: translateX(-100%);
      transition-property: transform;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
  .bp3-drawer.bp3-position-right {
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%; }
    .bp3-drawer.bp3-position-right.bp3-overlay-enter, .bp3-drawer.bp3-position-right.bp3-overlay-appear {
      transform: translateX(100%); }
    .bp3-drawer.bp3-position-right.bp3-overlay-enter-active, .bp3-drawer.bp3-position-right.bp3-overlay-appear-active {
      transform: translateX(0);
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
    .bp3-drawer.bp3-position-right.bp3-overlay-exit {
      transform: translateX(0); }
    .bp3-drawer.bp3-position-right.bp3-overlay-exit-active {
      transform: translateX(100%);
      transition-property: transform;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
  .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
  .bp3-position-right):not(.bp3-vertical) {
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-appear {
      transform: translateX(100%); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-appear-active {
      transform: translateX(0);
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-exit {
      transform: translateX(0); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-exit-active {
      transform: translateX(100%);
      transition-property: transform;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
  .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
  .bp3-position-right).bp3-vertical {
    right: 0;
    bottom: 0;
    left: 0;
    height: 50%; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-appear {
      transform: translateY(100%); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-appear-active {
      transform: translateY(0);
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-exit {
      transform: translateY(0); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-exit-active {
      transform: translateY(100%);
      transition-property: transform;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
      transition-delay: 0; }
  .bp3-drawer.bp3-dark,
  .bp3-dark .bp3-drawer {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.4), 0 18px 46px 6px rgba(0, 0, 0, 0.4);
    background: #30404d;
    color: #f5f8fa; }

.bp3-drawer-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  position: relative;
  border-radius: 0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  min-height: 40px;
  padding: 5px;
  padding-left: 20px; }
  .bp3-drawer-header .bp3-icon-large,
  .bp3-drawer-header .bp3-icon {
    flex: 0 0 auto;
    margin-right: 10px;
    color: #5c7080; }
  .bp3-drawer-header .bp3-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    margin: 0;
    line-height: inherit; }
    .bp3-drawer-header .bp3-heading:last-child {
      margin-right: 20px; }
  .bp3-dark .bp3-drawer-header {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }
    .bp3-dark .bp3-drawer-header .bp3-icon-large,
    .bp3-dark .bp3-drawer-header .bp3-icon {
      color: #bfccd6; }

.bp3-drawer-body {
  flex: 1 1 auto;
  overflow: auto;
  line-height: 18px; }

.bp3-drawer-footer {
  flex: 0 0 auto;
  position: relative;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.15);
  padding: 10px 20px; }
  .bp3-dark .bp3-drawer-footer {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.4); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-editable-text {
  display: inline-block;
  position: relative;
  cursor: text;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap; }
  .bp3-editable-text::before {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border-radius: 3px;
    content: "";
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(0, 0, 0, 0.15); }
  .bp3-editable-text.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #fff; }
  .bp3-editable-text.bp3-disabled::before {
    box-shadow: none; }
  .bp3-editable-text.bp3-intent-primary .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
    color: #137cbd; }
  .bp3-editable-text.bp3-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(19, 124, 189, 0.4); }
  .bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
  .bp3-editable-text.bp3-intent-success .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
    color: #0f9960; }
  .bp3-editable-text.bp3-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4); }
  .bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
  .bp3-editable-text.bp3-intent-warning .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
    color: #d9822b; }
  .bp3-editable-text.bp3-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4); }
  .bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
  .bp3-editable-text.bp3-intent-danger .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
    color: #db3737; }
  .bp3-editable-text.bp3-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4); }
  .bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
  .bp3-dark .bp3-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15); }
  .bp3-dark .bp3-editable-text.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.3); }
  .bp3-dark .bp3-editable-text.bp3-disabled::before {
    box-shadow: none; }
  .bp3-dark .bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
    color: #48aff0; }
  .bp3-dark .bp3-editable-text.bp3-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(72, 175, 240, 0), 0 0 0 0 rgba(72, 175, 240, 0), inset 0 0 0 1px rgba(72, 175, 240, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #48aff0, 0 0 0 3px rgba(72, 175, 240, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
    color: #3dcc91; }
  .bp3-dark .bp3-editable-text.bp3-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(61, 204, 145, 0), 0 0 0 0 rgba(61, 204, 145, 0), inset 0 0 0 1px rgba(61, 204, 145, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #3dcc91, 0 0 0 3px rgba(61, 204, 145, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
    color: #ffb366; }
  .bp3-dark .bp3-editable-text.bp3-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 179, 102, 0), 0 0 0 0 rgba(255, 179, 102, 0), inset 0 0 0 1px rgba(255, 179, 102, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #ffb366, 0 0 0 3px rgba(255, 179, 102, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
    color: #ff7373; }
  .bp3-dark .bp3-editable-text.bp3-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 115, 115, 0), 0 0 0 0 rgba(255, 115, 115, 0), inset 0 0 0 1px rgba(255, 115, 115, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #ff7373, 0 0 0 3px rgba(255, 115, 115, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }

.bp3-editable-text-input,
.bp3-editable-text-content {
  display: inherit;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none; }

.bp3-editable-text-input {
  border: none;
  box-shadow: none;
  background: none;
  width: 100%;
  padding: 0;
  white-space: pre-wrap; }
  .bp3-editable-text-input::placeholder {
    opacity: 1;
    color: #6c757d; }
  .bp3-editable-text-input:focus {
    outline: none; }
  .bp3-editable-text-input::-ms-clear {
    display: none; }

.bp3-editable-text-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre; }
  .bp3-editable-text-editing > .bp3-editable-text-content {
    position: absolute;
    left: 0;
    visibility: hidden; }
  .bp3-editable-text-placeholder > .bp3-editable-text-content {
    color: #6c757d; }
    .bp3-dark .bp3-editable-text-placeholder > .bp3-editable-text-content {
      color: rgba(191, 204, 214, 0.5); }

.bp3-editable-text.bp3-multiline {
  display: block; }
  .bp3-editable-text.bp3-multiline .bp3-editable-text-content {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Control groups

Markup:
<div class="bp3-control-group">
  <button class="bp3-button bp3-icon-filter">Filter</button>
  <input type="text" class="bp3-input" placeholder="Find filters..." />
</div>
<div class="bp3-control-group">
  <div class="bp3-select">
    <select>
      <option selected>Filter...</option>
      <option value="1">Issues</option>
      <option value="2">Requests</option>
      <option value="3">Projects</option>
    </select>
  </div>
  <div class="bp3-input-group">
    <span class="bp3-icon bp3-icon-search"></span>
    <input type="text" class="bp3-input" value="from:ggray to:allorca" />
  </div>
</div>
<div class="bp3-control-group">
  <div class="bp3-input-group">
    <span class="bp3-icon bp3-icon-people"></span>
    <input type="text" class="bp3-input" placeholder="Find collaborators..." style="padding-right:94px" />
    <div class="bp3-input-action">
      <button class="bp3-button bp3-minimal bp3-intent-primary">
        can view<span class="bp3-icon-standard bp3-icon-caret-down bp3-align-right"></span>
      </button>
    </div>
  </div>
  <button class="bp3-button bp3-intent-primary">Add</button>
</div>

Styleguide control-group
*/
.bp3-control-group {
  transform: translateZ(0);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*
  Responsive control groups

  Markup:
  <div class="bp3-control-group">
    <div class="bp3-input-group bp3-fill">
      <span class="bp3-icon bp3-icon-people"></span>
      <input type="text" class="bp3-input" placeholder="Find collaborators..." />
    </div>
    <button class="bp3-button bp3-intent-primary">Add</button>
  </div>
  <div class="bp3-control-group bp3-fill">
    <button class="bp3-button bp3-icon-minus bp3-fixed"></button>
    <input type="text" class="bp3-input" placeholder="Enter a value..." />
    <button class="bp3-button bp3-icon-plus bp3-fixed"></button>
  </div>

  Styleguide control-group-fill
  */
  /*
  Vertical control groups

  Markup:
  <div class="bp3-control-group bp3-vertical" style="width: 300px;">
    <div class="bp3-input-group bp3-large">
      <span class="bp3-icon bp3-icon-person"></span>
      <input type="text" class="bp3-input" placeholder="Username" />
    </div>
    <div class="bp3-input-group bp3-large">
      <span class="bp3-icon bp3-icon-lock"></span>
      <input type="password" class="bp3-input" placeholder="Password" />
    </div>
    <button class="bp3-button bp3-large bp3-intent-primary">Login</button>
  </div>

  Styleguide control-group-vertical
  */ }
  .bp3-control-group > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-control-group > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-control-group .bp3-button,
  .bp3-control-group .bp3-html-select,
  .bp3-control-group .bp3-input,
  .bp3-control-group .bp3-select {
    position: relative; }
  .bp3-control-group .bp3-input {
    z-index: 2;
    border-radius: inherit; }
    .bp3-control-group .bp3-input:focus {
      z-index: 14;
      border-radius: 3px; }
    .bp3-control-group .bp3-input[class*="bp3-intent"] {
      z-index: 13; }
      .bp3-control-group .bp3-input[class*="bp3-intent"]:focus {
        z-index: 15; }
    .bp3-control-group .bp3-input[readonly], .bp3-control-group .bp3-input:disabled, .bp3-control-group .bp3-input.bp3-disabled {
      z-index: 1; }
  .bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input {
    z-index: 13; }
    .bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input:focus {
      z-index: 15; }
  .bp3-control-group .bp3-button,
  .bp3-control-group .bp3-html-select select,
  .bp3-control-group .bp3-select select {
    z-index: 4;
    border-radius: inherit; }
    .bp3-control-group .bp3-button:focus,
    .bp3-control-group .bp3-html-select select:focus,
    .bp3-control-group .bp3-select select:focus {
      position: relative;
      z-index: 5; }
    .bp3-control-group .bp3-button:hover,
    .bp3-control-group .bp3-html-select select:hover,
    .bp3-control-group .bp3-select select:hover {
      z-index: 6; }
    .bp3-control-group .bp3-button:active,
    .bp3-control-group .bp3-html-select select:active,
    .bp3-control-group .bp3-select select:active {
      z-index: 7; }
    .bp3-control-group .bp3-button[readonly], .bp3-control-group .bp3-button:disabled, .bp3-control-group .bp3-button.bp3-disabled,
    .bp3-control-group .bp3-html-select select[readonly],
    .bp3-control-group .bp3-html-select select:disabled,
    .bp3-control-group .bp3-html-select select.bp3-disabled,
    .bp3-control-group .bp3-select select[readonly],
    .bp3-control-group .bp3-select select:disabled,
    .bp3-control-group .bp3-select select.bp3-disabled {
      z-index: 3; }
    .bp3-control-group .bp3-button[class*="bp3-intent"],
    .bp3-control-group .bp3-html-select select[class*="bp3-intent"],
    .bp3-control-group .bp3-select select[class*="bp3-intent"] {
      z-index: 9; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:focus,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:focus,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:focus {
        z-index: 10; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:hover,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:hover,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:hover {
        z-index: 11; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:active,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:active,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:active {
        z-index: 12; }
      .bp3-control-group .bp3-button[class*="bp3-intent"][readonly], .bp3-control-group .bp3-button[class*="bp3-intent"]:disabled, .bp3-control-group .bp3-button[class*="bp3-intent"].bp3-disabled,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"][readonly],
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:disabled,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"].bp3-disabled,
      .bp3-control-group .bp3-select select[class*="bp3-intent"][readonly],
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:disabled,
      .bp3-control-group .bp3-select select[class*="bp3-intent"].bp3-disabled {
        z-index: 8; }
  .bp3-control-group .bp3-input-group > .bp3-icon,
  .bp3-control-group .bp3-input-group > .bp3-button,
  .bp3-control-group .bp3-input-group > .bp3-input-action {
    z-index: 16; }
  .bp3-control-group .bp3-select::after,
  .bp3-control-group .bp3-html-select::after,
  .bp3-control-group .bp3-select > .bp3-icon,
  .bp3-control-group .bp3-html-select > .bp3-icon {
    z-index: 17; }
  .bp3-control-group:not(.bp3-vertical) > * {
    margin-right: -1px; }
  .bp3-dark .bp3-control-group:not(.bp3-vertical) > * {
    margin-right: 0; }
  .bp3-dark .bp3-control-group:not(.bp3-vertical) > .bp3-button + .bp3-button {
    margin-left: 1px; }
  .bp3-control-group .bp3-popover-wrapper,
  .bp3-control-group .bp3-popover-target {
    border-radius: inherit; }
  .bp3-control-group > :first-child {
    border-radius: 3px 0 0 3px; }
  .bp3-control-group > :last-child {
    margin-right: 0;
    border-radius: 0 3px 3px 0; }
  .bp3-control-group > :only-child {
    margin-right: 0;
    border-radius: 3px; }
  .bp3-control-group .bp3-input-group .bp3-button {
    border-radius: 3px; }
  .bp3-control-group > .bp3-fill {
    flex: 1 1 auto; }
  .bp3-control-group.bp3-fill > *:not(.bp3-fixed) {
    flex: 1 1 auto; }
  .bp3-control-group.bp3-vertical {
    flex-direction: column; }
    .bp3-control-group.bp3-vertical > * {
      margin-top: -1px; }
    .bp3-control-group.bp3-vertical > :first-child {
      margin-top: 0;
      border-radius: 3px 3px 0 0; }
    .bp3-control-group.bp3-vertical > :last-child {
      border-radius: 0 0 3px 3px; }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-control {
  display: block;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: none;
  /*
  Checkbox

  Markup:
  <label class="bp3-control bp3-checkbox {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Checkbox
  </label>

  :checked  - Checked
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  :indeterminate - Indeterminate. Note that this style can only be achieved via JavaScript
                   <code>input.indeterminate = true</code>.
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide checkbox
  */
  /*
  Radio

  Markup:
  <label class="bp3-control bp3-radio {{.modifier}}">
    <input type="radio" name="docs-radio-regular" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Radio
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide radio
  */
  /*
  Switch

  Markup:
  <label class="bp3-control bp3-switch {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Switch
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide switch
  */
  /*
  Inline labels

  Markup:
  <div>
    <label class="bp3-label">A group of related options</label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      First
    </label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      Second
    </label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      Third
    </label>
  </div>

  Styleguide checkbox-inline
  */ }
  .bp3-control input:checked ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #137cbd;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #fff; }
  .bp3-control:hover input:checked ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #106ba3; }
  .bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background: #0e5a8a; }
  .bp3-control input:disabled:checked ~ .bp3-control-indicator {
    box-shadow: none;
    background: rgba(19, 124, 189, 0.5); }
  .bp3-dark .bp3-control input:checked ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4); }
  .bp3-dark .bp3-control:hover input:checked ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
    background-color: #106ba3; }
  .bp3-dark .bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #0e5a8a; }
  .bp3-dark .bp3-control input:disabled:checked ~ .bp3-control-indicator {
    box-shadow: none;
    background: rgba(14, 90, 138, 0.5); }
  .bp3-control:not(.bp3-align-right) {
    padding-left: 26px; }
    .bp3-control:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -26px; }
  .bp3-control.bp3-align-right {
    padding-right: 26px; }
    .bp3-control.bp3-align-right .bp3-control-indicator {
      margin-right: -26px; }
  .bp3-control.bp3-disabled {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .bp3-control.bp3-inline {
    display: inline-block;
    margin-right: 20px; }
  .bp3-control input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1; }
  .bp3-control .bp3-control-indicator {
    display: inline-block;
    position: relative;
    margin-top: -3px;
    margin-right: 10px;
    border: none;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    background-color: #f5f8fa;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    cursor: pointer;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    font-size: 16px;
    user-select: none; }
    .bp3-control .bp3-control-indicator::before {
      display: block;
      width: 1em;
      height: 1em;
      content: ""; }
  .bp3-control:hover .bp3-control-indicator {
    background-color: #ebf1f5; }
  .bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background: #d8e1e8; }
  .bp3-control input:disabled ~ .bp3-control-indicator {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed; }
  .bp3-control input:focus ~ .bp3-control-indicator {
    outline: rgba(19, 124, 189, 0.6) auto 2px;
    outline-offset: 2px;
    -moz-outline-radius: 6px; }
  .bp3-control.bp3-align-right .bp3-control-indicator {
    float: right;
    margin-top: 1px;
    margin-left: 10px; }
  .bp3-control.bp3-large {
    font-size: 16px; }
    .bp3-control.bp3-large:not(.bp3-align-right) {
      padding-left: 30px; }
      .bp3-control.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
        margin-left: -30px; }
    .bp3-control.bp3-large.bp3-align-right {
      padding-right: 30px; }
      .bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
        margin-right: -30px; }
    .bp3-control.bp3-large .bp3-control-indicator {
      font-size: 20px; }
    .bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
      margin-top: 0; }
  .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #137cbd;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #fff; }
  .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #106ba3; }
  .bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background: #0e5a8a; }
  .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
    box-shadow: none;
    background: rgba(19, 124, 189, 0.5); }
  .bp3-dark .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4); }
  .bp3-dark .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
    background-color: #106ba3; }
  .bp3-dark .bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #0e5a8a; }
  .bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
    box-shadow: none;
    background: rgba(14, 90, 138, 0.5); }
  .bp3-control.bp3-checkbox .bp3-control-indicator {
    border-radius: 3px; }
  .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
    background-image: svg-icon("16px/small-tick.svg", (path: (fill: #fff))); }
  .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator::before {
    background-image: svg-icon("16px/small-minus.svg", (path: (fill: #fff))); }
  .bp3-control.bp3-radio .bp3-control-indicator {
    border-radius: 50%; }
  .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
    background-image: radial-gradient(#fff, #fff 28%, transparent 32%); }
  .bp3-control.bp3-radio input:checked:disabled ~ .bp3-control-indicator::before {
    opacity: 0.5; }
  .bp3-control.bp3-radio input:focus ~ .bp3-control-indicator {
    -moz-outline-radius: 16px; }
  .bp3-control.bp3-switch input ~ .bp3-control-indicator {
    background: rgba(167, 182, 194, 0.5); }
  .bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
    background: rgba(115, 134, 148, 0.5); }
  .bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
    background: rgba(92, 112, 128, 0.5); }
  .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
    background: rgba(206, 217, 224, 0.5); }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #137cbd; }
  .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #106ba3; }
  .bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
    background: #0e5a8a; }
  .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
    background: rgba(19, 124, 189, 0.5); }
  .bp3-control.bp3-switch:not(.bp3-align-right) {
    padding-left: 38px; }
    .bp3-control.bp3-switch:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -38px; }
  .bp3-control.bp3-switch.bp3-align-right {
    padding-right: 38px; }
    .bp3-control.bp3-switch.bp3-align-right .bp3-control-indicator {
      margin-right: -38px; }
  .bp3-control.bp3-switch .bp3-control-indicator {
    border: none;
    border-radius: 1.75em;
    box-shadow: none !important;
    width: auto;
    min-width: 1.75em;
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-control.bp3-switch .bp3-control-indicator::before {
      position: absolute;
      left: 0;
      margin: 2px;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.2);
      background: #fff;
      width: calc(1em - 4px);
      height: calc(1em - 4px);
      transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
    left: calc(100% - 1em); }
  .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) {
    padding-left: 45px; }
    .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -45px; }
  .bp3-control.bp3-switch.bp3-large.bp3-align-right {
    padding-right: 45px; }
    .bp3-control.bp3-switch.bp3-large.bp3-align-right .bp3-control-indicator {
      margin-right: -45px; }
  .bp3-dark .bp3-control.bp3-switch input ~ .bp3-control-indicator {
    background: rgba(0, 0, 0, 0.5); }
  .bp3-dark .bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
    background: rgba(0, 0, 0, 0.7); }
  .bp3-dark .bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
    background: rgba(0, 0, 0, 0.9); }
  .bp3-dark .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
    background: rgba(57, 75, 89, 0.5); }
  .bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #137cbd; }
  .bp3-dark .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #2b95d6; }
  .bp3-dark .bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
    background: #48aff0; }
  .bp3-dark .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
    background: rgba(14, 90, 138, 0.5); }
  .bp3-dark .bp3-control.bp3-switch .bp3-control-indicator::before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
    background: #394b59; }
  .bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4); }
  .bp3-control.bp3-switch .bp3-switch-inner-text {
    text-align: center;
    font-size: 0.7em; }
  .bp3-control.bp3-switch .bp3-control-indicator-child:first-child {
    visibility: hidden;
    margin-right: 1.2em;
    margin-left: 0.5em;
    line-height: 0; }
  .bp3-control.bp3-switch .bp3-control-indicator-child:last-child {
    visibility: visible;
    margin-right: 0.5em;
    margin-left: 1.2em;
    line-height: 1em; }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:first-child {
    visibility: visible;
    line-height: 1em; }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:last-child {
    visibility: hidden;
    line-height: 0; }
  .bp3-dark .bp3-control {
    color: #f5f8fa; }
    .bp3-dark .bp3-control.bp3-disabled {
      color: rgba(191, 204, 214, 0.5); }
    .bp3-dark .bp3-control .bp3-control-indicator {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
      background-color: #394b59;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0)); }
    .bp3-dark .bp3-control:hover .bp3-control-indicator {
      background-color: #30404d; }
    .bp3-dark .bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background: #202b33; }
    .bp3-dark .bp3-control input:disabled ~ .bp3-control-indicator {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      cursor: not-allowed; }
    .bp3-dark .bp3-control.bp3-checkbox input:disabled:checked ~ .bp3-control-indicator, .bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
      color: rgba(191, 204, 214, 0.5); }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
File input

Markup:
<label class="bp3-file-input {{.modifier}}">
  <input type="file" {{:modifier}}/>
  <span class="bp3-file-upload-input">Choose file...</span>
</label>

:disabled - Disabled
.bp3-large - Larger size
.bp3-fill - Take up full width of parent element
.bp3-file-input-has-selection - User has made a selection

Styleguide file-input
*/
.bp3-file-input {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 30px; }
  .bp3-file-input input {
    opacity: 0;
    margin: 0;
    min-width: 200px; }
    .bp3-file-input input:disabled + .bp3-file-upload-input,
    .bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
      box-shadow: none;
      background: rgba(206, 217, 224, 0.5);
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5);
      resize: none; }
      .bp3-file-input input:disabled + .bp3-file-upload-input::after,
      .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
        outline: none;
        box-shadow: none;
        background-color: rgba(206, 217, 224, 0.5);
        background-image: none;
        cursor: not-allowed;
        color: rgba(92, 112, 128, 0.5); }
        .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active:hover,
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active,
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active:hover {
          background: rgba(206, 217, 224, 0.7); }
      .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input, .bp3-dark
      .bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
        box-shadow: none;
        background: rgba(57, 75, 89, 0.5);
        color: rgba(191, 204, 214, 0.5); }
        .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after, .bp3-dark
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
          box-shadow: none;
          background-color: rgba(57, 75, 89, 0.5);
          background-image: none;
          color: rgba(191, 204, 214, 0.5); }
          .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-dark
          .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active {
            background: rgba(57, 75, 89, 0.7); }
  .bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
    color: #182026; }
  .bp3-dark .bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
    color: #f5f8fa; }
  .bp3-file-input.bp3-fill {
    width: 100%; }
  .bp3-file-input.bp3-large,
  .bp3-large .bp3-file-input {
    height: 40px; }

.bp3-file-upload-input {
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-right: 80px;
  color: rgba(92, 112, 128, 0.5);
  user-select: none; }
  .bp3-file-upload-input::placeholder {
    opacity: 1;
    color: #6c757d; }
  .bp3-file-upload-input:focus, .bp3-file-upload-input.bp3-active {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
  .bp3-file-upload-input[type="search"], .bp3-file-upload-input.bp3-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .bp3-file-upload-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15); }
  .bp3-file-upload-input:disabled, .bp3-file-upload-input.bp3-disabled {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5);
    resize: none; }
  .bp3-file-upload-input::after {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    background-color: #f5f8fa;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    color: #182026;
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 3px;
    border-radius: 3px;
    width: 70px;
    text-align: center;
    line-height: 24px;
    content: "Browse"; }
    .bp3-file-upload-input::after:hover {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
      background-clip: padding-box;
      background-color: #ebf1f5; }
    .bp3-file-upload-input::after:active, .bp3-file-upload-input::after.bp3-active {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #d8e1e8;
      background-image: none; }
    .bp3-file-upload-input::after:disabled, .bp3-file-upload-input::after.bp3-disabled {
      outline: none;
      box-shadow: none;
      background-color: rgba(206, 217, 224, 0.5);
      background-image: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
      .bp3-file-upload-input::after:disabled.bp3-active, .bp3-file-upload-input::after:disabled.bp3-active:hover, .bp3-file-upload-input::after.bp3-disabled.bp3-active, .bp3-file-upload-input::after.bp3-disabled.bp3-active:hover {
        background: rgba(206, 217, 224, 0.7); }
  .bp3-file-upload-input:hover::after {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .bp3-file-upload-input:active::after {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .bp3-large .bp3-file-upload-input {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding-right: 95px; }
    .bp3-large .bp3-file-upload-input[type="search"], .bp3-large .bp3-file-upload-input.bp3-round {
      padding: 0 15px; }
    .bp3-large .bp3-file-upload-input::after {
      min-width: 30px;
      min-height: 30px;
      margin: 5px;
      width: 85px;
      line-height: 30px; }
  .bp3-dark .bp3-file-upload-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.3);
    color: #f5f8fa;
    color: rgba(191, 204, 214, 0.5); }
    .bp3-dark .bp3-file-upload-input::placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .bp3-dark .bp3-file-upload-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark .bp3-file-upload-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark .bp3-file-upload-input:disabled, .bp3-dark .bp3-file-upload-input.bp3-disabled {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      color: rgba(191, 204, 214, 0.5); }
    .bp3-dark .bp3-file-upload-input::after {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
      background-color: #394b59;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
      color: #f5f8fa; }
      .bp3-dark .bp3-file-upload-input::after:hover, .bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
        color: #f5f8fa; }
      .bp3-dark .bp3-file-upload-input::after:hover {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
        background-color: #30404d; }
      .bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6), inset 0 1px 2px rgba(0, 0, 0, 0.2);
        background-color: #202b33;
        background-image: none; }
      .bp3-dark .bp3-file-upload-input::after:disabled, .bp3-dark .bp3-file-upload-input::after.bp3-disabled {
        box-shadow: none;
        background-color: rgba(57, 75, 89, 0.5);
        background-image: none;
        color: rgba(191, 204, 214, 0.5); }
        .bp3-dark .bp3-file-upload-input::after:disabled.bp3-active, .bp3-dark .bp3-file-upload-input::after.bp3-disabled.bp3-active {
          background: rgba(57, 75, 89, 0.7); }
      .bp3-dark .bp3-file-upload-input::after .bp3-button-spinner .bp3-spinner-head {
        background: rgba(0, 0, 0, 0.5);
        stroke: #8a9ba8; }
    .bp3-dark .bp3-file-upload-input:hover::after {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
      background-color: #30404d; }
    .bp3-dark .bp3-file-upload-input:active::after {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #202b33;
      background-image: none; }

.bp3-file-upload-input::after {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Form groups

Markup:
<div class="bp3-form-group {{.modifier}}">
  <label class="bp3-label" for="form-group-input">
    Label
    <span class="bp3-text-muted">(required)</span>
  </label>
  <div class="bp3-form-content">
    <div class="bp3-input-group {{.modifier}}">
      <span class="bp3-icon bp3-icon-calendar"></span>
      <input id="form-group-input" type="text" {{:modifier}} class="bp3-input"
             style="width: 200px;" placeholder="Placeholder text" dir="auto" />
    </div>
    <div class="bp3-form-helper-text">Please enter a value</div>
  </div>
</div>

:disabled - Disable the input.
.bp3-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp3-inline - Label and content appear side by side.
.bp3-intent-primary - Apply intent to form group and input separately.

Styleguide form-group
*/
.bp3-form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px; }
  .bp3-form-group label.bp3-label {
    margin-bottom: 5px; }
  .bp3-form-group .bp3-control {
    margin-top: 7px; }
  .bp3-form-group .bp3-form-helper-text {
    margin-top: 5px;
    color: #5c7080;
    font-size: 12px; }
  .bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
    color: #106ba3; }
  .bp3-form-group.bp3-intent-success .bp3-form-helper-text {
    color: #0d8050; }
  .bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
    color: #bf7326; }
  .bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
    color: #c23030; }
  .bp3-form-group.bp3-inline {
    flex-direction: row;
    align-items: flex-start; }
    .bp3-form-group.bp3-inline.bp3-large label.bp3-label {
      margin: 0 10px 0 0;
      line-height: 40px; }
    .bp3-form-group.bp3-inline label.bp3-label {
      margin: 0 10px 0 0;
      line-height: 30px; }
  .bp3-form-group.bp3-disabled .bp3-label,
  .bp3-form-group.bp3-disabled .bp3-text-muted,
  .bp3-form-group.bp3-disabled .bp3-form-helper-text {
    color: rgba(92, 112, 128, 0.5) !important; }
  .bp3-dark .bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
    color: #48aff0; }
  .bp3-dark .bp3-form-group.bp3-intent-success .bp3-form-helper-text {
    color: #3dcc91; }
  .bp3-dark .bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
    color: #ffb366; }
  .bp3-dark .bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
    color: #ff7373; }
  .bp3-dark .bp3-form-group .bp3-form-helper-text {
    color: #bfccd6; }
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-label,
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-text-muted,
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-form-helper-text {
    color: rgba(191, 204, 214, 0.5) !important; }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Input groups

Markup:
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-filter"></span>
  <input type="text" class="bp3-input" {{:modifier}} placeholder="Filter histogram..." />
</div>
<div class="bp3-input-group {{.modifier}}">
  <input type="password" class="bp3-input" {{:modifier}} placeholder="Enter your password..." />
  <button class="bp3-button bp3-minimal bp3-intent-warning bp3-icon-lock" {{:modifier}}></button>
</div>
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-search"></span>
  <input type="text" class="bp3-input" {{:modifier}} placeholder="Search" />
  <button class="bp3-button bp3-minimal bp3-intent-primary bp3-icon-arrow-right" {{:modifier}}></button>
</div>

:disabled - Disabled input. Must be added separately to the <code>&#60;input&#62;</code> and <code>&#60;button&#62;</code>. Also add <code>.bp3-disabled</code> to <code>.bp3-input-group</code> for icon coloring (not shown below).
.bp3-round - Rounded caps. Button will also be rounded.
.bp3-large - Large group. Children will adjust size accordingly.
.bp3-small - Small group. Children will adjust size accordingly.
.bp3-intent-primary - Primary intent. (All 4 intents are supported.)
.bp3-fill - Take up full width of parent element.

Styleguide input-group
*/
.bp3-input-group {
  display: block;
  position: relative; }
  .bp3-input-group .bp3-input {
    position: relative;
    width: 100%; }
    .bp3-input-group .bp3-input:not(:first-child) {
      padding-left: 30px; }
    .bp3-input-group .bp3-input:not(:last-child) {
      padding-right: 30px; }
  .bp3-input-group .bp3-input-action,
  .bp3-input-group > .bp3-button,
  .bp3-input-group > .bp3-icon {
    position: absolute;
    top: 0; }
    .bp3-input-group .bp3-input-action:first-child,
    .bp3-input-group > .bp3-button:first-child,
    .bp3-input-group > .bp3-icon:first-child {
      left: 0; }
    .bp3-input-group .bp3-input-action:last-child,
    .bp3-input-group > .bp3-button:last-child,
    .bp3-input-group > .bp3-icon:last-child {
      right: 0; }
  .bp3-input-group .bp3-button {
    min-width: 24px;
    min-height: 24px;
    margin: 3px;
    padding: 0 7px; }
    .bp3-input-group .bp3-button:empty {
      padding: 0; }
  .bp3-input-group > .bp3-icon {
    z-index: 1;
    color: #5c7080; }
    .bp3-input-group > .bp3-icon:empty {
      line-height: 1;
      font-family: "Icons16", sans-serif;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased; }
  .bp3-input-group > .bp3-icon,
  .bp3-input-group .bp3-input-action > .bp3-spinner {
    margin: 7px; }
  .bp3-input-group .bp3-tag {
    margin: 5px; }
  .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus),
  .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
    color: #5c7080; }
    .bp3-dark .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus), .bp3-dark
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
      color: #bfccd6; }
    .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large {
      color: #5c7080; }
  .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled,
  .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled {
    color: rgba(92, 112, 128, 0.5) !important; }
    .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-large,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-standard,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-large {
      color: rgba(92, 112, 128, 0.5) !important; }
  .bp3-input-group.bp3-disabled {
    cursor: not-allowed; }
    .bp3-input-group.bp3-disabled .bp3-icon {
      color: rgba(92, 112, 128, 0.5); }
  .bp3-input-group.bp3-large .bp3-button {
    min-width: 30px;
    min-height: 30px;
    margin: 5px; }
  .bp3-input-group.bp3-large > .bp3-icon,
  .bp3-input-group.bp3-large .bp3-input-action > .bp3-spinner {
    margin: 12px; }
  .bp3-input-group.bp3-large .bp3-input {
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
    .bp3-input-group.bp3-large .bp3-input[type="search"], .bp3-input-group.bp3-large .bp3-input.bp3-round {
      padding: 0 15px; }
    .bp3-input-group.bp3-large .bp3-input:not(:first-child) {
      padding-left: 40px; }
    .bp3-input-group.bp3-large .bp3-input:not(:last-child) {
      padding-right: 40px; }
  .bp3-input-group.bp3-small .bp3-button {
    min-width: 20px;
    min-height: 20px;
    margin: 2px; }
  .bp3-input-group.bp3-small .bp3-tag {
    min-width: 20px;
    min-height: 20px;
    margin: 2px; }
  .bp3-input-group.bp3-small > .bp3-icon,
  .bp3-input-group.bp3-small .bp3-input-action > .bp3-spinner {
    margin: 4px; }
  .bp3-input-group.bp3-small .bp3-input {
    height: 24px;
    padding-right: 8px;
    padding-left: 8px;
    line-height: 24px;
    font-size: 12px; }
    .bp3-input-group.bp3-small .bp3-input[type="search"], .bp3-input-group.bp3-small .bp3-input.bp3-round {
      padding: 0 12px; }
    .bp3-input-group.bp3-small .bp3-input:not(:first-child) {
      padding-left: 24px; }
    .bp3-input-group.bp3-small .bp3-input:not(:last-child) {
      padding-right: 24px; }
  .bp3-input-group.bp3-fill {
    flex: 1 1 auto;
    width: 100%; }
  .bp3-input-group.bp3-round .bp3-button,
  .bp3-input-group.bp3-round .bp3-input,
  .bp3-input-group.bp3-round .bp3-tag {
    border-radius: 30px; }
  .bp3-dark .bp3-input-group .bp3-icon {
    color: #bfccd6; }
  .bp3-dark .bp3-input-group.bp3-disabled .bp3-icon {
    color: rgba(191, 204, 214, 0.5); }
  .bp3-input-group.bp3-intent-primary .bp3-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-primary .bp3-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-primary .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
    .bp3-input-group.bp3-intent-primary .bp3-input:disabled, .bp3-input-group.bp3-intent-primary .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-primary > .bp3-icon {
    color: #106ba3; }
    .bp3-dark .bp3-input-group.bp3-intent-primary > .bp3-icon {
      color: #48aff0; }
  .bp3-input-group.bp3-intent-success .bp3-input {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-success .bp3-input:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-success .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .bp3-input-group.bp3-intent-success .bp3-input:disabled, .bp3-input-group.bp3-intent-success .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-success > .bp3-icon {
    color: #0d8050; }
    .bp3-dark .bp3-input-group.bp3-intent-success > .bp3-icon {
      color: #3dcc91; }
  .bp3-input-group.bp3-intent-warning .bp3-input {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-warning .bp3-input:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-warning .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .bp3-input-group.bp3-intent-warning .bp3-input:disabled, .bp3-input-group.bp3-intent-warning .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-warning > .bp3-icon {
    color: #bf7326; }
    .bp3-dark .bp3-input-group.bp3-intent-warning > .bp3-icon {
      color: #ffb366; }
  .bp3-input-group.bp3-intent-danger .bp3-input {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-danger .bp3-input:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input-group.bp3-intent-danger .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .bp3-input-group.bp3-intent-danger .bp3-input:disabled, .bp3-input-group.bp3-intent-danger .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-danger > .bp3-icon {
    color: #c23030; }
    .bp3-dark .bp3-input-group.bp3-intent-danger > .bp3-icon {
      color: #ff7373; }

/*
Text inputs

Markup:
<input class="bp3-input {{.modifier}}" {{:modifier}} type="text" placeholder="Text input" dir="auto" />

:disabled - Disabled
:readonly - Readonly
.bp3-round - Rounded ends
.bp3-large - Larger size
.bp3-small - Small size
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger - Danger intent
.bp3-fill - Take up full width of parent element

Styleguide input
*/
.bp3-input {
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  appearance: none; }
  .bp3-input::placeholder {
    opacity: 1;
    color: #6c757d; }
  .bp3-input:focus, .bp3-input.bp3-active {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
  .bp3-input[type="search"], .bp3-input.bp3-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .bp3-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15); }
  .bp3-input:disabled, .bp3-input.bp3-disabled {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5);
    resize: none; }
  .bp3-input.bp3-large {
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
    .bp3-input.bp3-large[type="search"], .bp3-input.bp3-large.bp3-round {
      padding: 0 15px; }
  .bp3-input.bp3-small {
    height: 24px;
    padding-right: 8px;
    padding-left: 8px;
    line-height: 24px;
    font-size: 12px; }
    .bp3-input.bp3-small[type="search"], .bp3-input.bp3-small.bp3-round {
      padding: 0 12px; }
  .bp3-input.bp3-fill {
    flex: 1 1 auto;
    width: 100%; }
  .bp3-dark .bp3-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.3);
    color: #f5f8fa; }
    .bp3-dark .bp3-input::placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .bp3-dark .bp3-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark .bp3-input:disabled, .bp3-dark .bp3-input.bp3-disabled {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      color: rgba(191, 204, 214, 0.5); }
  .bp3-input.bp3-intent-primary {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-primary:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-primary[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
    .bp3-input.bp3-intent-primary:disabled, .bp3-input.bp3-intent-primary.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-primary {
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-primary:focus {
        box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-primary[readonly] {
        box-shadow: inset 0 0 0 1px #137cbd; }
      .bp3-dark .bp3-input.bp3-intent-primary:disabled, .bp3-dark .bp3-input.bp3-intent-primary.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-success {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-success:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-success[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .bp3-input.bp3-intent-success:disabled, .bp3-input.bp3-intent-success.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-success {
      box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-success:focus {
        box-shadow: 0 0 0 1px #0f9960, 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-success[readonly] {
        box-shadow: inset 0 0 0 1px #0f9960; }
      .bp3-dark .bp3-input.bp3-intent-success:disabled, .bp3-dark .bp3-input.bp3-intent-success.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-warning {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-warning:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-warning[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .bp3-input.bp3-intent-warning:disabled, .bp3-input.bp3-intent-warning.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-warning {
      box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-warning:focus {
        box-shadow: 0 0 0 1px #d9822b, 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-warning[readonly] {
        box-shadow: inset 0 0 0 1px #d9822b; }
      .bp3-dark .bp3-input.bp3-intent-warning:disabled, .bp3-dark .bp3-input.bp3-intent-warning.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-danger {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-danger:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2); }
    .bp3-input.bp3-intent-danger[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .bp3-input.bp3-intent-danger:disabled, .bp3-input.bp3-intent-danger.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-danger {
      box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-danger:focus {
        box-shadow: 0 0 0 1px #db3737, 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-danger[readonly] {
        box-shadow: inset 0 0 0 1px #db3737; }
      .bp3-dark .bp3-input.bp3-intent-danger:disabled, .bp3-dark .bp3-input.bp3-intent-danger.bp3-disabled {
        box-shadow: none; }
  .bp3-input::-ms-clear {
    display: none; }

/*
Search inputs

Markup:
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-search"></span>
  <input class="bp3-input" {{:modifier}} type="search" placeholder="Search input" dir="auto" />
</div>

:disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-input-group</code> for icon coloring (not shown below).
.bp3-large - Large
.bp3-small - Small

Styleguide input-search
*/
/*
Textareas

Markup:
<textarea class="bp3-input {{.modifier}}" {{:modifier}} dir="auto"></textarea>

:disabled - Disabled
:readonly - Readonly
.bp3-large - Larger font size
.bp3-small - Small font size
.bp3-intent-primary - Primary intent
.bp3-intent-danger  - Danger intent
.bp3-fill  - Take up full width of parent element

Styleguide textarea
*/
textarea.bp3-input {
  max-width: 100%;
  padding: 10px; }
  textarea.bp3-input, textarea.bp3-input.bp3-large, textarea.bp3-input.bp3-small {
    height: auto;
    line-height: inherit; }
  textarea.bp3-input.bp3-small {
    padding: 8px; }
  .bp3-dark textarea.bp3-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.3);
    color: #f5f8fa; }
    .bp3-dark textarea.bp3-input::placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .bp3-dark textarea.bp3-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark textarea.bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4); }
    .bp3-dark textarea.bp3-input:disabled, .bp3-dark textarea.bp3-input.bp3-disabled {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      color: rgba(191, 204, 214, 0.5); }

/*
Labels

Markup:
<div>
  <label class="bp3-label {{.modifier}}">
    Label A
    <span class="bp3-text-muted">(optional)</span>
    <input {{:modifier}} class="bp3-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
  </label>
  <label class="bp3-label {{.modifier}}">
    Label B
    <div class="bp3-input-group {{.modifier}}">
      <span class="bp3-icon bp3-icon-calendar"></span>
      <input {{:modifier}} class="bp3-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
    </div>
  </label>
  <label class="bp3-label {{.modifier}}">
    Label C
    <div class="bp3-select {{.modifier}}">
      <select {{:modifier}}>
        <option selected>Choose an item...</option>
        <option value="1">One</option>
      </select>
    </div>
  </label>
</div>

:disabled - Disable the input.
.bp3-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp3-inline - Label and content appear side by side.

Styleguide label
*/
label.bp3-label {
  display: block;
  margin-top: 0;
  margin-bottom: 15px; }
  label.bp3-label .bp3-html-select,
  label.bp3-label .bp3-input,
  label.bp3-label .bp3-select,
  label.bp3-label .bp3-slider,
  label.bp3-label .bp3-popover-wrapper {
    display: block;
    margin-top: 5px;
    text-transform: none; }
  label.bp3-label .bp3-select select,
  label.bp3-label .bp3-html-select select {
    width: 100%;
    vertical-align: top;
    font-weight: 400; }
  label.bp3-label.bp3-disabled,
  label.bp3-label.bp3-disabled .bp3-text-muted {
    color: rgba(92, 112, 128, 0.5); }
  label.bp3-label.bp3-inline {
    line-height: 30px; }
    label.bp3-label.bp3-inline .bp3-html-select,
    label.bp3-label.bp3-inline .bp3-input,
    label.bp3-label.bp3-inline .bp3-input-group,
    label.bp3-label.bp3-inline .bp3-select,
    label.bp3-label.bp3-inline .bp3-popover-wrapper {
      display: inline-block;
      margin: 0 0 0 5px;
      vertical-align: top; }
    label.bp3-label.bp3-inline .bp3-input-group .bp3-input {
      margin-left: 0; }
    label.bp3-label.bp3-inline.bp3-large {
      line-height: 40px; }
  label.bp3-label:not(.bp3-inline) .bp3-popover-target {
    display: block; }
  .bp3-dark label.bp3-label {
    color: #f5f8fa; }
    .bp3-dark label.bp3-label.bp3-disabled,
    .bp3-dark label.bp3-label.bp3-disabled .bp3-text-muted {
      color: rgba(191, 204, 214, 0.5); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button {
  flex: 1 1 14px;
  width: 30px;
  min-height: 0;
  padding: 0; }
  .bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:first-child {
    border-radius: 0 3px 0 0; }
  .bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:last-child {
    border-radius: 0 0 3px 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:first-child {
  border-radius: 3px 0 0 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:last-child {
  border-radius: 0 0 0 3px; }

.bp3-numeric-input.bp3-large .bp3-button-group.bp3-vertical > .bp3-button {
  width: 40px; }

form {
  display: block; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-html-select select,
.bp3-select select {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  border-radius: 3px;
  width: 100%;
  height: 30px;
  padding: 0 25px 0 10px;
  -moz-appearance: none;
  -webkit-appearance: none; }
  .bp3-html-select select > *, .bp3-select select > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-html-select select > .bp3-fill, .bp3-select select > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-html-select select::before,
  .bp3-select select::before, .bp3-html-select select > *, .bp3-select select > * {
    margin-right: 7px; }
  .bp3-html-select select:empty::before,
  .bp3-select select:empty::before,
  .bp3-html-select select > :last-child,
  .bp3-select select > :last-child {
    margin-right: 0; }
  .bp3-html-select select:hover,
  .bp3-select select:hover {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .bp3-html-select select:active,
  .bp3-select select:active, .bp3-html-select select.bp3-active,
  .bp3-select select.bp3-active {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .bp3-html-select select:disabled,
  .bp3-select select:disabled, .bp3-html-select select.bp3-disabled,
  .bp3-select select.bp3-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .bp3-html-select select:disabled.bp3-active,
    .bp3-select select:disabled.bp3-active, .bp3-html-select select:disabled.bp3-active:hover,
    .bp3-select select:disabled.bp3-active:hover, .bp3-html-select select.bp3-disabled.bp3-active,
    .bp3-select select.bp3-disabled.bp3-active, .bp3-html-select select.bp3-disabled.bp3-active:hover,
    .bp3-select select.bp3-disabled.bp3-active:hover {
      background: rgba(206, 217, 224, 0.7); }

.bp3-html-select.bp3-minimal select,
.bp3-select.bp3-minimal select {
  box-shadow: none;
  background: none; }
  .bp3-html-select.bp3-minimal select:hover,
  .bp3-select.bp3-minimal select:hover {
    box-shadow: none;
    background: rgba(167, 182, 194, 0.3);
    text-decoration: none;
    color: #182026; }
  .bp3-html-select.bp3-minimal select:active,
  .bp3-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal select.bp3-active,
  .bp3-select.bp3-minimal select.bp3-active {
    box-shadow: none;
    background: rgba(115, 134, 148, 0.3);
    color: #182026; }
  .bp3-html-select.bp3-minimal select:disabled,
  .bp3-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal select:disabled:hover,
  .bp3-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal select.bp3-disabled,
  .bp3-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal select.bp3-disabled:hover,
  .bp3-select.bp3-minimal select.bp3-disabled:hover {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .bp3-html-select.bp3-minimal select:disabled.bp3-active,
    .bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active,
    .bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active,
    .bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active,
    .bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active {
      background: rgba(115, 134, 148, 0.3); }
  .bp3-dark .bp3-html-select.bp3-minimal select, .bp3-html-select.bp3-minimal .bp3-dark select,
  .bp3-dark .bp3-select.bp3-minimal select, .bp3-select.bp3-minimal .bp3-dark select {
    box-shadow: none;
    background: none;
    color: inherit; }
    .bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
    .bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover, .bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
    .bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
      box-shadow: none;
      background: none; }
    .bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
    .bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover {
      background: rgba(138, 155, 168, 0.15); }
    .bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
    .bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
      background: rgba(138, 155, 168, 0.3);
      color: #f5f8fa; }
    .bp3-dark .bp3-html-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal .bp3-dark select:disabled,
    .bp3-dark .bp3-select.bp3-minimal select:disabled, .bp3-select.bp3-minimal .bp3-dark select:disabled, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover,
    .bp3-dark .bp3-select.bp3-minimal select:disabled:hover, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(191, 204, 214, 0.5); }
      .bp3-dark .bp3-html-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active {
        background: rgba(138, 155, 168, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-primary,
  .bp3-select.bp3-minimal select.bp3-intent-primary {
    color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
    .bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
    .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
      box-shadow: none;
      background: none;
      color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
    .bp3-select.bp3-minimal select.bp3-intent-primary:hover {
      background: rgba(19, 124, 189, 0.15);
      color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
    .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
      background: rgba(19, 124, 189, 0.3);
      color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled {
      background: none;
      color: rgba(16, 107, 163, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active {
        background: rgba(19, 124, 189, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
      stroke: #106ba3; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary {
      color: #48aff0; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover {
        background: rgba(19, 124, 189, 0.2);
        color: #48aff0; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active {
        background: rgba(19, 124, 189, 0.3);
        color: #48aff0; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(72, 175, 240, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(19, 124, 189, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-success,
  .bp3-select.bp3-minimal select.bp3-intent-success {
    color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
    .bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal select.bp3-intent-success:active,
    .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
      box-shadow: none;
      background: none;
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
    .bp3-select.bp3-minimal select.bp3-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:active,
    .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active {
        background: rgba(15, 153, 96, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
      stroke: #0d8050; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success {
      color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-warning,
  .bp3-select.bp3-minimal select.bp3-intent-warning {
    color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
    .bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
    .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
      box-shadow: none;
      background: none;
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
    .bp3-select.bp3-minimal select.bp3-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
    .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active {
        background: rgba(217, 130, 43, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
      stroke: #bf7326; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning {
      color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-danger,
  .bp3-select.bp3-minimal select.bp3-intent-danger {
    color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
    .bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
    .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
      box-shadow: none;
      background: none;
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
    .bp3-select.bp3-minimal select.bp3-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
    .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active {
        background: rgba(219, 55, 55, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
      stroke: #c23030; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger {
      color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }

.bp3-html-select.bp3-large select,
.bp3-select.bp3-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.bp3-dark .bp3-html-select select, .bp3-dark .bp3-select select {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #394b59;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }
  .bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover, .bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
    color: #f5f8fa; }
  .bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
    background-color: #30404d; }
  .bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #202b33;
    background-image: none; }
  .bp3-dark .bp3-html-select select:disabled, .bp3-dark .bp3-select select:disabled, .bp3-dark .bp3-html-select select.bp3-disabled, .bp3-dark .bp3-select select.bp3-disabled {
    box-shadow: none;
    background-color: rgba(57, 75, 89, 0.5);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
    .bp3-dark .bp3-html-select select:disabled.bp3-active, .bp3-dark .bp3-select select:disabled.bp3-active, .bp3-dark .bp3-html-select select.bp3-disabled.bp3-active, .bp3-dark .bp3-select select.bp3-disabled.bp3-active {
      background: rgba(57, 75, 89, 0.7); }
  .bp3-dark .bp3-html-select select .bp3-button-spinner .bp3-spinner-head, .bp3-dark .bp3-select select .bp3-button-spinner .bp3-spinner-head {
    background: rgba(0, 0, 0, 0.5);
    stroke: #8a9ba8; }

.bp3-html-select select:disabled,
.bp3-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.bp3-html-select .bp3-icon,
.bp3-select .bp3-icon, .bp3-select::after {
  position: absolute;
  top: 7px;
  right: 7px;
  color: #5c7080;
  pointer-events: none; }
  .bp3-html-select .bp3-disabled.bp3-icon,
  .bp3-select .bp3-disabled.bp3-icon, .bp3-disabled.bp3-select::after {
    color: rgba(92, 112, 128, 0.5); }

/*
HTML select

Markup:
<div class="bp3-select {{.modifier}}">
  <select {{:modifier}}>
    <option selected>Choose an item...</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    <option value="4">Four</option>
  </select>
</div>

:disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-select</code> for icon coloring (not shown below).
.bp3-fill - Expand to fill parent container
.bp3-large - Large size
.bp3-minimal - Minimal appearance

Styleguide select
*/
.bp3-html-select,
.bp3-select {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: normal; }
  .bp3-html-select select::-ms-expand,
  .bp3-select select::-ms-expand {
    display: none; }
  .bp3-html-select .bp3-icon,
  .bp3-select .bp3-icon {
    color: #5c7080; }
    .bp3-html-select .bp3-icon:hover,
    .bp3-select .bp3-icon:hover {
      color: #182026; }
    .bp3-dark .bp3-html-select .bp3-icon, .bp3-dark
    .bp3-select .bp3-icon {
      color: #bfccd6; }
      .bp3-dark .bp3-html-select .bp3-icon:hover, .bp3-dark
      .bp3-select .bp3-icon:hover {
        color: #f5f8fa; }
  .bp3-html-select.bp3-large::after,
  .bp3-html-select.bp3-large .bp3-icon,
  .bp3-select.bp3-large::after,
  .bp3-select.bp3-large .bp3-icon {
    top: 12px;
    right: 12px; }
  .bp3-html-select.bp3-fill,
  .bp3-html-select.bp3-fill select,
  .bp3-select.bp3-fill,
  .bp3-select.bp3-fill select {
    width: 100%; }
  .bp3-dark .bp3-html-select option, .bp3-dark
  .bp3-select option {
    background-color: #30404d;
    color: #f5f8fa; }
  .bp3-dark .bp3-html-select::after, .bp3-dark
  .bp3-select::after {
    color: #bfccd6; }

.bp3-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "\E6C6"; }

/*
Tables

Markup:
<table class="bp3-html-table {{.modifier}}">
  <thead>
    <tr>
      <th>Project</th>
      <th>Description</th>
      <th>Technologies</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Blueprint</td>
      <td>CSS framework and UI toolkit</td>
      <td>Sass, TypeScript, React</td>
    </tr>
    <tr>
      <td>TSLint</td>
      <td>Static analysis linter for TypeScript</td>
      <td>TypeScript</td>
    </tr>
    <tr>
      <td>Plottable</td>
      <td>Composable charting library built on top of D3</td>
      <td>SVG, TypeScript, D3</td>
    </tr>
  </tbody>
</table>

.bp3-html-table-bordered - Bordered appearance
.bp3-html-table-condensed - Condensed smaller appearance
.bp3-html-table-striped - Striped appearance
.bp3-interactive - Enables hover styles on rows
.bp3-small - Small, condensed appearance for this element _and all child elements_

Styleguide html-table
*/
.bp3-running-text table, table.bp3-html-table {
  border-spacing: 0;
  font-size: 14px; }
  .bp3-running-text table th, table.bp3-html-table th,
  .bp3-running-text table td,
  table.bp3-html-table td {
    padding: 11px;
    vertical-align: top;
    text-align: left; }
  .bp3-running-text table th, table.bp3-html-table th {
    color: #182026;
    font-weight: 600; }
  
  .bp3-running-text table td,
  table.bp3-html-table td {
    color: #182026; }
  .bp3-running-text table tbody tr:first-child th, table.bp3-html-table tbody tr:first-child th,
  .bp3-running-text table tbody tr:first-child td,
  table.bp3-html-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 #dee2e6; }
  .bp3-dark .bp3-running-text table th, .bp3-running-text .bp3-dark table th, .bp3-dark table.bp3-html-table th {
    color: #f5f8fa; }
  .bp3-dark .bp3-running-text table td, .bp3-running-text .bp3-dark table td, .bp3-dark table.bp3-html-table td {
    color: #f5f8fa; }
  .bp3-dark .bp3-running-text table tbody tr:first-child th, .bp3-running-text .bp3-dark table tbody tr:first-child th, .bp3-dark table.bp3-html-table tbody tr:first-child th,
  .bp3-dark .bp3-running-text table tbody tr:first-child td,
  .bp3-running-text .bp3-dark table tbody tr:first-child td,
  .bp3-dark table.bp3-html-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }

table.bp3-html-table.bp3-html-table-condensed th,
table.bp3-html-table.bp3-html-table-condensed td, table.bp3-html-table.bp3-small th,
table.bp3-html-table.bp3-small td {
  padding-top: 6px;
  padding-bottom: 6px; }

table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(191, 204, 214, 0.15); }

table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 0 0 #dee2e6; }

table.bp3-html-table.bp3-html-table-bordered tbody tr td {
  box-shadow: inset 0 1px 0 0 #dee2e6; }
  table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child) {
    box-shadow: inset 1px 1px 0 0 #dee2e6; }

table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: none; }
  table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:not(:first-child) {
    box-shadow: inset 1px 0 0 0 #dee2e6; }

table.bp3-html-table.bp3-interactive tbody tr:hover td {
  background-color: rgba(191, 204, 214, 0.3);
  cursor: pointer; }

table.bp3-html-table.bp3-interactive tbody tr:active td {
  background-color: rgba(191, 204, 214, 0.4); }

.bp3-dark table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(92, 112, 128, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child) {
    box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:first-child {
    box-shadow: none; }

.bp3-dark table.bp3-html-table.bp3-interactive tbody tr:hover td {
  background-color: rgba(92, 112, 128, 0.3);
  cursor: pointer; }

.bp3-dark table.bp3-html-table.bp3-interactive tbody tr:active td {
  background-color: rgba(92, 112, 128, 0.4); }

.bp3-key-combo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .bp3-key-combo > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-key-combo > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-key-combo::before,
  .bp3-key-combo > * {
    margin-right: 5px; }
  .bp3-key-combo:empty::before,
  .bp3-key-combo > :last-child {
    margin-right: 0; }

.bp3-hotkey-dialog {
  top: 40px;
  padding-bottom: 0; }
  .bp3-hotkey-dialog .bp3-dialog-body {
    margin: 0;
    padding: 0; }
  .bp3-hotkey-dialog .bp3-hotkey-label {
    flex-grow: 1; }

.bp3-hotkey-column {
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px; }
  .bp3-hotkey-column .bp3-heading {
    margin-bottom: 20px; }
    .bp3-hotkey-column .bp3-heading:not(:first-child) {
      margin-top: 40px; }

.bp3-hotkey {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
  margin-left: 0; }
  .bp3-hotkey:not(:last-child) {
    margin-bottom: 10px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.bp3-icon {
  display: inline-block;
  flex: 0 0 auto;
  vertical-align: text-bottom; }
  .bp3-icon:not(:empty)::before {
    content: "" !important;
    content: unset !important; }
  .bp3-icon > svg {
    display: block; }
    .bp3-icon > svg:not([fill]) {
      fill: currentColor; }

.bp3-icon.bp3-intent-primary, .bp3-icon-standard.bp3-intent-primary, .bp3-icon-large.bp3-intent-primary {
  color: #106ba3; }
  .bp3-dark .bp3-icon.bp3-intent-primary, .bp3-dark .bp3-icon-standard.bp3-intent-primary, .bp3-dark .bp3-icon-large.bp3-intent-primary {
    color: #48aff0; }

.bp3-icon.bp3-intent-success, .bp3-icon-standard.bp3-intent-success, .bp3-icon-large.bp3-intent-success {
  color: #0d8050; }
  .bp3-dark .bp3-icon.bp3-intent-success, .bp3-dark .bp3-icon-standard.bp3-intent-success, .bp3-dark .bp3-icon-large.bp3-intent-success {
    color: #3dcc91; }

.bp3-icon.bp3-intent-warning, .bp3-icon-standard.bp3-intent-warning, .bp3-icon-large.bp3-intent-warning {
  color: #bf7326; }
  .bp3-dark .bp3-icon.bp3-intent-warning, .bp3-dark .bp3-icon-standard.bp3-intent-warning, .bp3-dark .bp3-icon-large.bp3-intent-warning {
    color: #ffb366; }

.bp3-icon.bp3-intent-danger, .bp3-icon-standard.bp3-intent-danger, .bp3-icon-large.bp3-intent-danger {
  color: #c23030; }
  .bp3-dark .bp3-icon.bp3-intent-danger, .bp3-dark .bp3-icon-standard.bp3-intent-danger, .bp3-dark .bp3-icon-large.bp3-intent-danger {
    color: #ff7373; }

span.bp3-icon-standard {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon-large {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon:empty {
  line-height: 1;
  font-family: "Icons20";
  font-size: inherit;
  font-weight: 400;
  font-style: normal; }
  span.bp3-icon:empty::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

.bp3-icon-add::before {
  content: "\E63E"; }

.bp3-icon-add-column-left::before {
  content: "\E6F9"; }

.bp3-icon-add-column-right::before {
  content: "\E6FA"; }

.bp3-icon-add-row-bottom::before {
  content: "\E6F8"; }

.bp3-icon-add-row-top::before {
  content: "\E6F7"; }

.bp3-icon-add-to-artifact::before {
  content: "\E67C"; }

.bp3-icon-add-to-folder::before {
  content: "\E6D2"; }

.bp3-icon-airplane::before {
  content: "\E74B"; }

.bp3-icon-align-center::before {
  content: "\E603"; }

.bp3-icon-align-justify::before {
  content: "\E605"; }

.bp3-icon-align-left::before {
  content: "\E602"; }

.bp3-icon-align-right::before {
  content: "\E604"; }

.bp3-icon-alignment-bottom::before {
  content: "\E727"; }

.bp3-icon-alignment-horizontal-center::before {
  content: "\E726"; }

.bp3-icon-alignment-left::before {
  content: "\E722"; }

.bp3-icon-alignment-right::before {
  content: "\E724"; }

.bp3-icon-alignment-top::before {
  content: "\E725"; }

.bp3-icon-alignment-vertical-center::before {
  content: "\E723"; }

.bp3-icon-annotation::before {
  content: "\E6F0"; }

.bp3-icon-application::before {
  content: "\E735"; }

.bp3-icon-applications::before {
  content: "\E621"; }

.bp3-icon-arrow-bottom-left::before {
  content: "\2199"; }

.bp3-icon-arrow-bottom-right::before {
  content: "\2198"; }

.bp3-icon-arrow-down::before {
  content: "\2193"; }

.bp3-icon-arrow-left::before {
  content: "\2190"; }

.bp3-icon-arrow-right::before {
  content: "\2192"; }

.bp3-icon-arrow-top-left::before {
  content: "\2196"; }

.bp3-icon-arrow-top-right::before {
  content: "\2197"; }

.bp3-icon-arrow-up::before {
  content: "\2191"; }

.bp3-icon-arrows-horizontal::before {
  content: "\2194"; }

.bp3-icon-arrows-vertical::before {
  content: "\2195"; }

.bp3-icon-asterisk::before {
  content: "*"; }

.bp3-icon-automatic-updates::before {
  content: "\E65F"; }

.bp3-icon-badge::before {
  content: "\E6E3"; }

.bp3-icon-ban-circle::before {
  content: "\E69D"; }

.bp3-icon-bank-account::before {
  content: "\E76F"; }

.bp3-icon-barcode::before {
  content: "\E676"; }

.bp3-icon-blank::before {
  content: "\E900"; }

.bp3-icon-blocked-person::before {
  content: "\E768"; }

.bp3-icon-bold::before {
  content: "\E606"; }

.bp3-icon-book::before {
  content: "\E6B8"; }

.bp3-icon-bookmark::before {
  content: "\E61A"; }

.bp3-icon-box::before {
  content: "\E6BF"; }

.bp3-icon-briefcase::before {
  content: "\E674"; }

.bp3-icon-build::before {
  content: "\E72D"; }

.bp3-icon-calculator::before {
  content: "\E70B"; }

.bp3-icon-calendar::before {
  content: "\E62B"; }

.bp3-icon-camera::before {
  content: "\E69E"; }

.bp3-icon-caret-down::before {
  content: "\2304"; }

.bp3-icon-caret-left::before {
  content: "\2329"; }

.bp3-icon-caret-right::before {
  content: "\232A"; }

.bp3-icon-caret-up::before {
  content: "\2303"; }

.bp3-icon-cell-tower::before {
  content: "\E770"; }

.bp3-icon-changes::before {
  content: "\E623"; }

.bp3-icon-chart::before {
  content: "\E67E"; }

.bp3-icon-chat::before {
  content: "\E689"; }

.bp3-icon-chevron-backward::before {
  content: "\E6DF"; }

.bp3-icon-chevron-down::before {
  content: "\E697"; }

.bp3-icon-chevron-forward::before {
  content: "\E6E0"; }

.bp3-icon-chevron-left::before {
  content: "\E694"; }

.bp3-icon-chevron-right::before {
  content: "\E695"; }

.bp3-icon-chevron-up::before {
  content: "\E696"; }

.bp3-icon-circle::before {
  content: "\E66A"; }

.bp3-icon-circle-arrow-down::before {
  content: "\E68E"; }

.bp3-icon-circle-arrow-left::before {
  content: "\E68C"; }

.bp3-icon-circle-arrow-right::before {
  content: "\E68B"; }

.bp3-icon-circle-arrow-up::before {
  content: "\E68D"; }

.bp3-icon-citation::before {
  content: "\E61B"; }

.bp3-icon-clean::before {
  content: "\E7C5"; }

.bp3-icon-clipboard::before {
  content: "\E61D"; }

.bp3-icon-cloud::before {
  content: "\2601"; }

.bp3-icon-cloud-download::before {
  content: "\E690"; }

.bp3-icon-cloud-upload::before {
  content: "\E691"; }

.bp3-icon-code::before {
  content: "\E661"; }

.bp3-icon-code-block::before {
  content: "\E6C5"; }

.bp3-icon-cog::before {
  content: "\E645"; }

.bp3-icon-collapse-all::before {
  content: "\E763"; }

.bp3-icon-column-layout::before {
  content: "\E6DA"; }

.bp3-icon-comment::before {
  content: "\E68A"; }

.bp3-icon-comparison::before {
  content: "\E637"; }

.bp3-icon-compass::before {
  content: "\E79C"; }

.bp3-icon-compressed::before {
  content: "\E6C0"; }

.bp3-icon-confirm::before {
  content: "\E639"; }

.bp3-icon-console::before {
  content: "\E79B"; }

.bp3-icon-contrast::before {
  content: "\E6CB"; }

.bp3-icon-control::before {
  content: "\E67F"; }

.bp3-icon-credit-card::before {
  content: "\E649"; }

.bp3-icon-cross::before {
  content: "\2717"; }

.bp3-icon-crown::before {
  content: "\E7B4"; }

.bp3-icon-cube::before {
  content: "\E7C8"; }

.bp3-icon-cube-add::before {
  content: "\E7C9"; }

.bp3-icon-cube-remove::before {
  content: "\E7D0"; }

.bp3-icon-curved-range-chart::before {
  content: "\E71B"; }

.bp3-icon-cut::before {
  content: "\E6EF"; }

.bp3-icon-dashboard::before {
  content: "\E751"; }

.bp3-icon-database::before {
  content: "\E683"; }

.bp3-icon-delete::before {
  content: "\E644"; }

.bp3-icon-delta::before {
  content: "\394"; }

.bp3-icon-derive-column::before {
  content: "\E739"; }

.bp3-icon-desktop::before {
  content: "\E6AF"; }

.bp3-icon-diagram-tree::before {
  content: "\E7B3"; }

.bp3-icon-direction-left::before {
  content: "\E681"; }

.bp3-icon-direction-right::before {
  content: "\E682"; }

.bp3-icon-disable::before {
  content: "\E600"; }

.bp3-icon-document::before {
  content: "\E630"; }

.bp3-icon-document-open::before {
  content: "\E71E"; }

.bp3-icon-document-share::before {
  content: "\E71F"; }

.bp3-icon-dollar::before {
  content: "$"; }

.bp3-icon-dot::before {
  content: "\2022"; }

.bp3-icon-double-caret-horizontal::before {
  content: "\E6C7"; }

.bp3-icon-double-caret-vertical::before {
  content: "\E6C6"; }

.bp3-icon-double-chevron-down::before {
  content: "\E703"; }

.bp3-icon-double-chevron-left::before {
  content: "\E6FF"; }

.bp3-icon-double-chevron-right::before {
  content: "\E701"; }

.bp3-icon-double-chevron-up::before {
  content: "\E702"; }

.bp3-icon-doughnut-chart::before {
  content: "\E6CE"; }

.bp3-icon-download::before {
  content: "\E62F"; }

.bp3-icon-drag-handle-horizontal::before {
  content: "\E716"; }

.bp3-icon-drag-handle-vertical::before {
  content: "\E715"; }

.bp3-icon-draw::before {
  content: "\E66B"; }

.bp3-icon-drive-time::before {
  content: "\E615"; }

.bp3-icon-duplicate::before {
  content: "\E69C"; }

.bp3-icon-edit::before {
  content: "\270E"; }

.bp3-icon-eject::before {
  content: "\23CF"; }

.bp3-icon-endorsed::before {
  content: "\E75F"; }

.bp3-icon-envelope::before {
  content: "\2709"; }

.bp3-icon-eraser::before {
  content: "\E773"; }

.bp3-icon-error::before {
  content: "\E648"; }

.bp3-icon-euro::before {
  content: "\20AC"; }

.bp3-icon-exchange::before {
  content: "\E636"; }

.bp3-icon-exclude-row::before {
  content: "\E6EA"; }

.bp3-icon-expand-all::before {
  content: "\E764"; }

.bp3-icon-export::before {
  content: "\E633"; }

.bp3-icon-eye-off::before {
  content: "\E6CC"; }

.bp3-icon-eye-on::before {
  content: "\E75A"; }

.bp3-icon-eye-open::before {
  content: "\E66F"; }

.bp3-icon-fast-backward::before {
  content: "\E6A8"; }

.bp3-icon-fast-forward::before {
  content: "\E6AC"; }

.bp3-icon-feed::before {
  content: "\E656"; }

.bp3-icon-feed-subscribed::before {
  content: "\E78F"; }

.bp3-icon-film::before {
  content: "\E6A1"; }

.bp3-icon-filter::before {
  content: "\E638"; }

.bp3-icon-filter-keep::before {
  content: "\E78C"; }

.bp3-icon-filter-list::before {
  content: "\E6EE"; }

.bp3-icon-filter-remove::before {
  content: "\E78D"; }

.bp3-icon-flag::before {
  content: "\2691"; }

.bp3-icon-flame::before {
  content: "\E7A9"; }

.bp3-icon-flash::before {
  content: "\E6B3"; }

.bp3-icon-floppy-disk::before {
  content: "\E6B7"; }

.bp3-icon-flow-branch::before {
  content: "\E7C1"; }

.bp3-icon-flow-end::before {
  content: "\E7C4"; }

.bp3-icon-flow-linear::before {
  content: "\E7C0"; }

.bp3-icon-flow-review::before {
  content: "\E7C2"; }

.bp3-icon-flow-review-branch::before {
  content: "\E7C3"; }

.bp3-icon-flows::before {
  content: "\E659"; }

.bp3-icon-folder-close::before {
  content: "\E652"; }

.bp3-icon-folder-new::before {
  content: "\E7B0"; }

.bp3-icon-folder-open::before {
  content: "\E651"; }

.bp3-icon-folder-shared::before {
  content: "\E653"; }

.bp3-icon-folder-shared-open::before {
  content: "\E670"; }

.bp3-icon-follower::before {
  content: "\E760"; }

.bp3-icon-following::before {
  content: "\E761"; }

.bp3-icon-font::before {
  content: "\E6B4"; }

.bp3-icon-fork::before {
  content: "\E63A"; }

.bp3-icon-form::before {
  content: "\E795"; }

.bp3-icon-full-circle::before {
  content: "\E685"; }

.bp3-icon-full-stacked-chart::before {
  content: "\E75E"; }

.bp3-icon-fullscreen::before {
  content: "\E699"; }

.bp3-icon-function::before {
  content: "\E6E5"; }

.bp3-icon-gantt-chart::before {
  content: "\E6F4"; }

.bp3-icon-geolocation::before {
  content: "\E640"; }

.bp3-icon-geosearch::before {
  content: "\E613"; }

.bp3-icon-git-branch::before {
  content: "\E72A"; }

.bp3-icon-git-commit::before {
  content: "\E72B"; }

.bp3-icon-git-merge::before {
  content: "\E729"; }

.bp3-icon-git-new-branch::before {
  content: "\E749"; }

.bp3-icon-git-pull::before {
  content: "\E728"; }

.bp3-icon-git-push::before {
  content: "\E72C"; }

.bp3-icon-git-repo::before {
  content: "\E748"; }

.bp3-icon-glass::before {
  content: "\E6B1"; }

.bp3-icon-globe::before {
  content: "\E666"; }

.bp3-icon-globe-network::before {
  content: "\E7B5"; }

.bp3-icon-graph::before {
  content: "\E673"; }

.bp3-icon-graph-remove::before {
  content: "\E609"; }

.bp3-icon-grid::before {
  content: "\E6D0"; }

.bp3-icon-grid-view::before {
  content: "\E6E4"; }

.bp3-icon-group-objects::before {
  content: "\E60A"; }

.bp3-icon-grouped-bar-chart::before {
  content: "\E75D"; }

.bp3-icon-hand::before {
  content: "\E6DE"; }

.bp3-icon-hand-down::before {
  content: "\E6BB"; }

.bp3-icon-hand-left::before {
  content: "\E6BC"; }

.bp3-icon-hand-right::before {
  content: "\E6B9"; }

.bp3-icon-hand-up::before {
  content: "\E6BA"; }

.bp3-icon-header::before {
  content: "\E6B5"; }

.bp3-icon-header-one::before {
  content: "\E793"; }

.bp3-icon-header-two::before {
  content: "\E794"; }

.bp3-icon-headset::before {
  content: "\E6DC"; }

.bp3-icon-heart::before {
  content: "\2665"; }

.bp3-icon-heart-broken::before {
  content: "\E7A2"; }

.bp3-icon-heat-grid::before {
  content: "\E6F3"; }

.bp3-icon-heatmap::before {
  content: "\E614"; }

.bp3-icon-help::before {
  content: "?"; }

.bp3-icon-helper-management::before {
  content: "\E66D"; }

.bp3-icon-highlight::before {
  content: "\E6ED"; }

.bp3-icon-history::before {
  content: "\E64A"; }

.bp3-icon-home::before {
  content: "\2302"; }

.bp3-icon-horizontal-bar-chart::before {
  content: "\E70C"; }

.bp3-icon-horizontal-bar-chart-asc::before {
  content: "\E75C"; }

.bp3-icon-horizontal-bar-chart-desc::before {
  content: "\E71D"; }

.bp3-icon-horizontal-distribution::before {
  content: "\E720"; }

.bp3-icon-id-number::before {
  content: "\E771"; }

.bp3-icon-image-rotate-left::before {
  content: "\E73A"; }

.bp3-icon-image-rotate-right::before {
  content: "\E73B"; }

.bp3-icon-import::before {
  content: "\E632"; }

.bp3-icon-inbox::before {
  content: "\E629"; }

.bp3-icon-inbox-filtered::before {
  content: "\E7D1"; }

.bp3-icon-inbox-geo::before {
  content: "\E7D2"; }

.bp3-icon-inbox-search::before {
  content: "\E7D3"; }

.bp3-icon-inbox-update::before {
  content: "\E7D4"; }

.bp3-icon-info-sign::before {
  content: "\2139"; }

.bp3-icon-inheritance::before {
  content: "\E7D5"; }

.bp3-icon-inner-join::before {
  content: "\E7A3"; }

.bp3-icon-insert::before {
  content: "\E66C"; }

.bp3-icon-intersection::before {
  content: "\E765"; }

.bp3-icon-ip-address::before {
  content: "\E772"; }

.bp3-icon-issue::before {
  content: "\E774"; }

.bp3-icon-issue-closed::before {
  content: "\E776"; }

.bp3-icon-issue-new::before {
  content: "\E775"; }

.bp3-icon-italic::before {
  content: "\E607"; }

.bp3-icon-join-table::before {
  content: "\E738"; }

.bp3-icon-key::before {
  content: "\E78E"; }

.bp3-icon-key-backspace::before {
  content: "\E707"; }

.bp3-icon-key-command::before {
  content: "\E705"; }

.bp3-icon-key-control::before {
  content: "\E704"; }

.bp3-icon-key-delete::before {
  content: "\E708"; }

.bp3-icon-key-enter::before {
  content: "\E70A"; }

.bp3-icon-key-escape::before {
  content: "\E709"; }

.bp3-icon-key-option::before {
  content: "\E742"; }

.bp3-icon-key-shift::before {
  content: "\E706"; }

.bp3-icon-key-tab::before {
  content: "\E757"; }

.bp3-icon-known-vehicle::before {
  content: "\E73C"; }

.bp3-icon-label::before {
  content: "\E665"; }

.bp3-icon-layer::before {
  content: "\E6CF"; }

.bp3-icon-layers::before {
  content: "\E618"; }

.bp3-icon-layout::before {
  content: "\E60C"; }

.bp3-icon-layout-auto::before {
  content: "\E60D"; }

.bp3-icon-layout-balloon::before {
  content: "\E6D3"; }

.bp3-icon-layout-circle::before {
  content: "\E60E"; }

.bp3-icon-layout-grid::before {
  content: "\E610"; }

.bp3-icon-layout-group-by::before {
  content: "\E611"; }

.bp3-icon-layout-hierarchy::before {
  content: "\E60F"; }

.bp3-icon-layout-linear::before {
  content: "\E6C3"; }

.bp3-icon-layout-skew-grid::before {
  content: "\E612"; }

.bp3-icon-layout-sorted-clusters::before {
  content: "\E6D4"; }

.bp3-icon-left-join::before {
  content: "\E7A4"; }

.bp3-icon-lifesaver::before {
  content: "\E7C7"; }

.bp3-icon-lightbulb::before {
  content: "\E6B0"; }

.bp3-icon-link::before {
  content: "\E62D"; }

.bp3-icon-list::before {
  content: "\2630"; }

.bp3-icon-list-columns::before {
  content: "\E7B9"; }

.bp3-icon-list-detail-view::before {
  content: "\E743"; }

.bp3-icon-locate::before {
  content: "\E619"; }

.bp3-icon-lock::before {
  content: "\E625"; }

.bp3-icon-log-in::before {
  content: "\E69A"; }

.bp3-icon-log-out::before {
  content: "\E64C"; }

.bp3-icon-manual::before {
  content: "\E6F6"; }

.bp3-icon-manually-entered-data::before {
  content: "\E74A"; }

.bp3-icon-map::before {
  content: "\E662"; }

.bp3-icon-map-create::before {
  content: "\E741"; }

.bp3-icon-map-marker::before {
  content: "\E67D"; }

.bp3-icon-maximize::before {
  content: "\E635"; }

.bp3-icon-media::before {
  content: "\E62C"; }

.bp3-icon-menu::before {
  content: "\E762"; }

.bp3-icon-menu-closed::before {
  content: "\E655"; }

.bp3-icon-menu-open::before {
  content: "\E654"; }

.bp3-icon-merge-columns::before {
  content: "\E74F"; }

.bp3-icon-merge-links::before {
  content: "\E60B"; }

.bp3-icon-minimize::before {
  content: "\E634"; }

.bp3-icon-minus::before {
  content: "\2212"; }

.bp3-icon-mobile-phone::before {
  content: "\E717"; }

.bp3-icon-mobile-video::before {
  content: "\E69F"; }

.bp3-icon-moon::before {
  content: "\E754"; }

.bp3-icon-more::before {
  content: "\E62A"; }

.bp3-icon-mountain::before {
  content: "\E7B1"; }

.bp3-icon-move::before {
  content: "\E693"; }

.bp3-icon-mugshot::before {
  content: "\E6DB"; }

.bp3-icon-multi-select::before {
  content: "\E680"; }

.bp3-icon-music::before {
  content: "\E6A6"; }

.bp3-icon-new-grid-item::before {
  content: "\E747"; }

.bp3-icon-new-link::before {
  content: "\E65C"; }

.bp3-icon-new-object::before {
  content: "\E65D"; }

.bp3-icon-new-person::before {
  content: "\E6E9"; }

.bp3-icon-new-prescription::before {
  content: "\E78B"; }

.bp3-icon-new-text-box::before {
  content: "\E65B"; }

.bp3-icon-ninja::before {
  content: "\E675"; }

.bp3-icon-notifications::before {
  content: "\E624"; }

.bp3-icon-notifications-updated::before {
  content: "\E7B8"; }

.bp3-icon-numbered-list::before {
  content: "\E746"; }

.bp3-icon-numerical::before {
  content: "\E756"; }

.bp3-icon-office::before {
  content: "\E69B"; }

.bp3-icon-offline::before {
  content: "\E67A"; }

.bp3-icon-oil-field::before {
  content: "\E73F"; }

.bp3-icon-one-column::before {
  content: "\E658"; }

.bp3-icon-outdated::before {
  content: "\E7A8"; }

.bp3-icon-page-layout::before {
  content: "\E660"; }

.bp3-icon-panel-stats::before {
  content: "\E777"; }

.bp3-icon-panel-table::before {
  content: "\E778"; }

.bp3-icon-paperclip::before {
  content: "\E664"; }

.bp3-icon-paragraph::before {
  content: "\E76C"; }

.bp3-icon-path::before {
  content: "\E753"; }

.bp3-icon-path-search::before {
  content: "\E65E"; }

.bp3-icon-pause::before {
  content: "\E6A9"; }

.bp3-icon-people::before {
  content: "\E63D"; }

.bp3-icon-percentage::before {
  content: "\E76A"; }

.bp3-icon-person::before {
  content: "\E63C"; }

.bp3-icon-phone::before {
  content: "\260E"; }

.bp3-icon-pie-chart::before {
  content: "\E684"; }

.bp3-icon-pin::before {
  content: "\E646"; }

.bp3-icon-pivot::before {
  content: "\E6F1"; }

.bp3-icon-pivot-table::before {
  content: "\E6EB"; }

.bp3-icon-play::before {
  content: "\E6AB"; }

.bp3-icon-plus::before {
  content: "+"; }

.bp3-icon-polygon-filter::before {
  content: "\E6D1"; }

.bp3-icon-power::before {
  content: "\E6D9"; }

.bp3-icon-predictive-analysis::before {
  content: "\E617"; }

.bp3-icon-prescription::before {
  content: "\E78A"; }

.bp3-icon-presentation::before {
  content: "\E687"; }

.bp3-icon-print::before {
  content: "\2399"; }

.bp3-icon-projects::before {
  content: "\E622"; }

.bp3-icon-properties::before {
  content: "\E631"; }

.bp3-icon-property::before {
  content: "\E65A"; }

.bp3-icon-publish-function::before {
  content: "\E752"; }

.bp3-icon-pulse::before {
  content: "\E6E8"; }

.bp3-icon-random::before {
  content: "\E698"; }

.bp3-icon-record::before {
  content: "\E6AE"; }

.bp3-icon-redo::before {
  content: "\E6C4"; }

.bp3-icon-refresh::before {
  content: "\E643"; }

.bp3-icon-regression-chart::before {
  content: "\E758"; }

.bp3-icon-remove::before {
  content: "\E63F"; }

.bp3-icon-remove-column::before {
  content: "\E755"; }

.bp3-icon-remove-column-left::before {
  content: "\E6FD"; }

.bp3-icon-remove-column-right::before {
  content: "\E6FE"; }

.bp3-icon-remove-row-bottom::before {
  content: "\E6FC"; }

.bp3-icon-remove-row-top::before {
  content: "\E6FB"; }

.bp3-icon-repeat::before {
  content: "\E692"; }

.bp3-icon-resolve::before {
  content: "\E672"; }

.bp3-icon-rig::before {
  content: "\E740"; }

.bp3-icon-right-join::before {
  content: "\E7A5"; }

.bp3-icon-ring::before {
  content: "\E6F2"; }

.bp3-icon-rotate-document::before {
  content: "\E6E1"; }

.bp3-icon-rotate-page::before {
  content: "\E6E2"; }

.bp3-icon-satellite::before {
  content: "\E76B"; }

.bp3-icon-saved::before {
  content: "\E6B6"; }

.bp3-icon-scatter-plot::before {
  content: "\E73E"; }

.bp3-icon-search::before {
  content: "\E64B"; }

.bp3-icon-search-around::before {
  content: "\E608"; }

.bp3-icon-search-template::before {
  content: "\E628"; }

.bp3-icon-search-text::before {
  content: "\E663"; }

.bp3-icon-segmented-control::before {
  content: "\E6EC"; }

.bp3-icon-select::before {
  content: "\E616"; }

.bp3-icon-selection::before {
  content: "\29BF"; }

.bp3-icon-send-to::before {
  content: "\E66E"; }

.bp3-icon-send-to-graph::before {
  content: "\E736"; }

.bp3-icon-send-to-map::before {
  content: "\E737"; }

.bp3-icon-series-add::before {
  content: "\E796"; }

.bp3-icon-series-configuration::before {
  content: "\E79A"; }

.bp3-icon-series-derived::before {
  content: "\E799"; }

.bp3-icon-series-filtered::before {
  content: "\E798"; }

.bp3-icon-series-search::before {
  content: "\E797"; }

.bp3-icon-settings::before {
  content: "\E6A2"; }

.bp3-icon-share::before {
  content: "\E62E"; }

.bp3-icon-shield::before {
  content: "\E7B2"; }

.bp3-icon-shop::before {
  content: "\E6C2"; }

.bp3-icon-shopping-cart::before {
  content: "\E6C1"; }

.bp3-icon-sim-card::before {
  content: "\E718"; }

.bp3-icon-slash::before {
  content: "\E769"; }

.bp3-icon-small-cross::before {
  content: "\E6D7"; }

.bp3-icon-small-minus::before {
  content: "\E70E"; }

.bp3-icon-small-plus::before {
  content: "\E70D"; }

.bp3-icon-small-tick::before {
  content: "\E6D8"; }

.bp3-icon-snowflake::before {
  content: "\E7B6"; }

.bp3-icon-social-media::before {
  content: "\E671"; }

.bp3-icon-sort::before {
  content: "\E64F"; }

.bp3-icon-sort-alphabetical::before {
  content: "\E64D"; }

.bp3-icon-sort-alphabetical-desc::before {
  content: "\E6C8"; }

.bp3-icon-sort-asc::before {
  content: "\E6D5"; }

.bp3-icon-sort-desc::before {
  content: "\E6D6"; }

.bp3-icon-sort-numerical::before {
  content: "\E64E"; }

.bp3-icon-sort-numerical-desc::before {
  content: "\E6C9"; }

.bp3-icon-split-columns::before {
  content: "\E750"; }

.bp3-icon-square::before {
  content: "\E686"; }

.bp3-icon-stacked-chart::before {
  content: "\E6E7"; }

.bp3-icon-star::before {
  content: "\2605"; }

.bp3-icon-star-empty::before {
  content: "\2606"; }

.bp3-icon-step-backward::before {
  content: "\E6A7"; }

.bp3-icon-step-chart::before {
  content: "\E70F"; }

.bp3-icon-step-forward::before {
  content: "\E6AD"; }

.bp3-icon-stop::before {
  content: "\E6AA"; }

.bp3-icon-strikethrough::before {
  content: "\E7A6"; }

.bp3-icon-style::before {
  content: "\E601"; }

.bp3-icon-swap-horizontal::before {
  content: "\E745"; }

.bp3-icon-swap-vertical::before {
  content: "\E744"; }

.bp3-icon-symbol-circle::before {
  content: "\E72E"; }

.bp3-icon-symbol-cross::before {
  content: "\E731"; }

.bp3-icon-symbol-diamond::before {
  content: "\E730"; }

.bp3-icon-symbol-square::before {
  content: "\E72F"; }

.bp3-icon-symbol-triangle-down::before {
  content: "\E733"; }

.bp3-icon-symbol-triangle-up::before {
  content: "\E732"; }

.bp3-icon-tag::before {
  content: "\E61C"; }

.bp3-icon-take-action::before {
  content: "\E6CA"; }

.bp3-icon-taxi::before {
  content: "\E79E"; }

.bp3-icon-text-highlight::before {
  content: "\E6DD"; }

.bp3-icon-th::before {
  content: "\E667"; }

.bp3-icon-th-derived::before {
  content: "\E669"; }

.bp3-icon-th-filtered::before {
  content: "\E7C6"; }

.bp3-icon-th-list::before {
  content: "\E668"; }

.bp3-icon-thumbs-down::before {
  content: "\E6BE"; }

.bp3-icon-thumbs-up::before {
  content: "\E6BD"; }

.bp3-icon-tick::before {
  content: "\2713"; }

.bp3-icon-tick-circle::before {
  content: "\E779"; }

.bp3-icon-time::before {
  content: "\23F2"; }

.bp3-icon-timeline-area-chart::before {
  content: "\E6CD"; }

.bp3-icon-timeline-bar-chart::before {
  content: "\E620"; }

.bp3-icon-timeline-events::before {
  content: "\E61E"; }

.bp3-icon-timeline-line-chart::before {
  content: "\E61F"; }

.bp3-icon-tint::before {
  content: "\E6B2"; }

.bp3-icon-torch::before {
  content: "\E677"; }

.bp3-icon-train::before {
  content: "\E79F"; }

.bp3-icon-translate::before {
  content: "\E759"; }

.bp3-icon-trash::before {
  content: "\E63B"; }

.bp3-icon-tree::before {
  content: "\E7B7"; }

.bp3-icon-trending-down::before {
  content: "\E71A"; }

.bp3-icon-trending-up::before {
  content: "\E719"; }

.bp3-icon-two-columns::before {
  content: "\E657"; }

.bp3-icon-underline::before {
  content: "\2381"; }

.bp3-icon-undo::before {
  content: "\238C"; }

.bp3-icon-ungroup-objects::before {
  content: "\E688"; }

.bp3-icon-unknown-vehicle::before {
  content: "\E73D"; }

.bp3-icon-unlock::before {
  content: "\E626"; }

.bp3-icon-unpin::before {
  content: "\E650"; }

.bp3-icon-unresolve::before {
  content: "\E679"; }

.bp3-icon-updated::before {
  content: "\E7A7"; }

.bp3-icon-upload::before {
  content: "\E68F"; }

.bp3-icon-user::before {
  content: "\E627"; }

.bp3-icon-variable::before {
  content: "\E6F5"; }

.bp3-icon-vertical-bar-chart-asc::before {
  content: "\E75B"; }

.bp3-icon-vertical-bar-chart-desc::before {
  content: "\E71C"; }

.bp3-icon-vertical-distribution::before {
  content: "\E721"; }

.bp3-icon-video::before {
  content: "\E6A0"; }

.bp3-icon-volume-down::before {
  content: "\E6A4"; }

.bp3-icon-volume-off::before {
  content: "\E6A3"; }

.bp3-icon-volume-up::before {
  content: "\E6A5"; }

.bp3-icon-walk::before {
  content: "\E79D"; }

.bp3-icon-warning-sign::before {
  content: "\E647"; }

.bp3-icon-waterfall-chart::before {
  content: "\E6E6"; }

.bp3-icon-widget::before {
  content: "\E678"; }

.bp3-icon-widget-button::before {
  content: "\E790"; }

.bp3-icon-widget-footer::before {
  content: "\E792"; }

.bp3-icon-widget-header::before {
  content: "\E791"; }

.bp3-icon-wrench::before {
  content: "\E734"; }

.bp3-icon-zoom-in::before {
  content: "\E641"; }

.bp3-icon-zoom-out::before {
  content: "\E642"; }

.bp3-icon-zoom-to-fit::before {
  content: "\E67B"; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.bp3-submenu > .bp3-popover-wrapper {
  display: block; }

.bp3-submenu .bp3-popover-target {
  display: block; }

.bp3-submenu.bp3-popover {
  box-shadow: none;
  padding: 0 5px; }
  .bp3-submenu.bp3-popover > .bp3-popover-content {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.2); }
  .bp3-dark .bp3-submenu.bp3-popover, .bp3-submenu.bp3-popover.bp3-dark {
    box-shadow: none; }
    .bp3-dark .bp3-submenu.bp3-popover > .bp3-popover-content, .bp3-submenu.bp3-popover.bp3-dark > .bp3-popover-content {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4); }

/*
Menus

Markup:
<ul class="bp3-menu {{.modifier}} bp3-elevation-1">
  <li>
    <a class="bp3-menu-item bp3-icon-people" tabindex="0">Share...</a>
  </li>
  <li>
    <a class="bp3-menu-item bp3-icon-circle-arrow-right" tabindex="0">Move...</a>
  </li>
  <li>
    <a class="bp3-menu-item bp3-icon-edit" tabindex="0">Rename</a>
  </li>
  <li class="bp3-menu-divider"></li>
  <li>
    <a class="bp3-menu-item bp3-icon-trash bp3-intent-danger" tabindex="0">Delete</a>
  </li>
</ul>

.bp3-large - Large size (only supported on <code>.bp3-menu</code>)

Styleguide menu
*/
.bp3-menu {
  margin: 0;
  border-radius: 3px;
  background: #fff;
  min-width: 180px;
  padding: 5px;
  list-style: none;
  text-align: left;
  color: #182026; }

.bp3-menu-divider {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .bp3-dark .bp3-menu-divider {
    border-top-color: rgba(255, 255, 255, 0.15); }

.bp3-menu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 2px;
  padding: 5px 7px;
  text-decoration: none;
  line-height: 20px;
  color: inherit;
  user-select: none; }
  .bp3-menu-item > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-menu-item > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-menu-item::before,
  .bp3-menu-item > * {
    margin-right: 7px; }
  .bp3-menu-item:empty::before,
  .bp3-menu-item > :last-child {
    margin-right: 0; }
  .bp3-menu-item > .bp3-fill {
    word-break: break-word; }
  .bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
    background-color: rgba(167, 182, 194, 0.3);
    cursor: pointer;
    text-decoration: none; }
  .bp3-menu-item.bp3-disabled {
    background-color: inherit;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .bp3-dark .bp3-menu-item {
    color: inherit; }
    .bp3-dark .bp3-menu-item:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
      background-color: rgba(138, 155, 168, 0.15);
      color: inherit; }
    .bp3-dark .bp3-menu-item.bp3-disabled {
      background-color: inherit;
      color: rgba(191, 204, 214, 0.5); }
  .bp3-menu-item.bp3-intent-primary {
    color: #106ba3; }
    .bp3-menu-item.bp3-intent-primary .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-primary::before, .bp3-menu-item.bp3-intent-primary::after,
    .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
      color: #106ba3; }
    .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary.bp3-active {
      background-color: #137cbd; }
    .bp3-menu-item.bp3-intent-primary:active {
      background-color: #106ba3; }
    .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary:active, .bp3-menu-item.bp3-intent-primary:active::before, .bp3-menu-item.bp3-intent-primary:active::after,
    .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-menu-item.bp3-intent-primary.bp3-active::after,
    .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
      color: #fff; }
  .bp3-menu-item.bp3-intent-success {
    color: #0d8050; }
    .bp3-menu-item.bp3-intent-success .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-success::before, .bp3-menu-item.bp3-intent-success::after,
    .bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
      color: #0d8050; }
    .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success.bp3-active {
      background-color: #0f9960; }
    .bp3-menu-item.bp3-intent-success:active {
      background-color: #0d8050; }
    .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-menu-item.bp3-intent-success:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success:active, .bp3-menu-item.bp3-intent-success:active::before, .bp3-menu-item.bp3-intent-success:active::after,
    .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-menu-item.bp3-intent-success.bp3-active::after,
    .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
      color: #fff; }
  .bp3-menu-item.bp3-intent-warning {
    color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-warning::before, .bp3-menu-item.bp3-intent-warning::after,
    .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
      color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning.bp3-active {
      background-color: #d9822b; }
    .bp3-menu-item.bp3-intent-warning:active {
      background-color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning:active, .bp3-menu-item.bp3-intent-warning:active::before, .bp3-menu-item.bp3-intent-warning:active::after,
    .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-menu-item.bp3-intent-warning.bp3-active::after,
    .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
      color: #fff; }
  .bp3-menu-item.bp3-intent-danger {
    color: #c23030; }
    .bp3-menu-item.bp3-intent-danger .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-danger::before, .bp3-menu-item.bp3-intent-danger::after,
    .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
      color: #c23030; }
    .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger.bp3-active {
      background-color: #db3737; }
    .bp3-menu-item.bp3-intent-danger:active {
      background-color: #c23030; }
    .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger:active, .bp3-menu-item.bp3-intent-danger:active::before, .bp3-menu-item.bp3-intent-danger:active::after,
    .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-menu-item.bp3-intent-danger.bp3-active::after,
    .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
      color: #fff; }
  .bp3-menu-item::before {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin-right: 7px; }
  .bp3-menu-item::before,
  .bp3-menu-item > .bp3-icon {
    margin-top: 2px;
    color: #5c7080; }
  .bp3-menu-item .bp3-menu-item-label {
    color: #5c7080; }
  .bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
    color: inherit; }
  .bp3-menu-item.bp3-active, .bp3-menu-item:active {
    background-color: rgba(115, 134, 148, 0.3); }
  .bp3-menu-item.bp3-disabled {
    outline: none !important;
    background-color: inherit !important;
    cursor: not-allowed !important;
    color: rgba(92, 112, 128, 0.5) !important; }
    .bp3-menu-item.bp3-disabled::before,
    .bp3-menu-item.bp3-disabled > .bp3-icon,
    .bp3-menu-item.bp3-disabled .bp3-menu-item-label {
      color: rgba(92, 112, 128, 0.5) !important; }
  .bp3-large .bp3-menu-item {
    padding: 9px 7px;
    line-height: 22px;
    font-size: 16px; }
    .bp3-large .bp3-menu-item .bp3-icon {
      margin-top: 3px; }
    .bp3-large .bp3-menu-item::before {
      line-height: 1;
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      margin-top: 1px;
      margin-right: 10px; }

button.bp3-menu-item {
  border: none;
  background: none;
  width: 100%;
  text-align: left; }

/*
Menu headers

Markup:
<ul class="bp3-menu bp3-elevation-1">
  <li class="bp3-menu-header"><h6 class="bp3-heading">Layouts</h6></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-auto">Auto</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-circle">Circle</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-grid">Grid</button></li>
  <li class="bp3-menu-header"><h6 class="bp3-heading">Views</h6></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-history">History</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-star">Favorites</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-envelope">Messages</button></li>
</ul>

Styleguide menu-header
*/
.bp3-menu-header {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  cursor: default;
  padding-left: 2px; }
  .bp3-dark .bp3-menu-header {
    border-top-color: rgba(255, 255, 255, 0.15); }
  .bp3-menu-header:first-of-type {
    border-top: none; }
  .bp3-menu-header > h6 {
    color: #182026;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    margin: 0;
    padding: 10px 7px 0 1px;
    line-height: 17px; }
    .bp3-dark .bp3-menu-header > h6 {
      color: #f5f8fa; }
  .bp3-menu-header:first-of-type > h6 {
    padding-top: 0; }
  .bp3-large .bp3-menu-header > h6 {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 18px; }
  .bp3-large .bp3-menu-header:first-of-type > h6 {
    padding-top: 0; }

.bp3-dark .bp3-menu {
  background: #30404d;
  color: #f5f8fa; }

.bp3-dark .bp3-menu-item.bp3-intent-primary {
  color: #48aff0; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary::before, .bp3-dark .bp3-menu-item.bp3-intent-primary::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
    color: #48aff0; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active {
    background-color: #137cbd; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary:active {
    background-color: #106ba3; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary:active, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
    color: #fff; }

.bp3-dark .bp3-menu-item.bp3-intent-success {
  color: #3dcc91; }
  .bp3-dark .bp3-menu-item.bp3-intent-success .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-success::before, .bp3-dark .bp3-menu-item.bp3-intent-success::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
    color: #3dcc91; }
  .bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active {
    background-color: #0f9960; }
  .bp3-dark .bp3-menu-item.bp3-intent-success:active {
    background-color: #0d8050; }
  .bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success:active, .bp3-dark .bp3-menu-item.bp3-intent-success:active::before, .bp3-dark .bp3-menu-item.bp3-intent-success:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
    color: #fff; }

.bp3-dark .bp3-menu-item.bp3-intent-warning {
  color: #ffb366; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning::before, .bp3-dark .bp3-menu-item.bp3-intent-warning::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
    color: #ffb366; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active {
    background-color: #d9822b; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning:active {
    background-color: #bf7326; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning:active, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
    color: #fff; }

.bp3-dark .bp3-menu-item.bp3-intent-danger {
  color: #ff7373; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger::before, .bp3-dark .bp3-menu-item.bp3-intent-danger::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
    color: #ff7373; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active {
    background-color: #db3737; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger:active {
    background-color: #c23030; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger:active, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
    color: #fff; }

.bp3-dark .bp3-menu-item::before,
.bp3-dark .bp3-menu-item > .bp3-icon {
  color: #bfccd6; }

.bp3-dark .bp3-menu-item .bp3-menu-item-label {
  color: #bfccd6; }

.bp3-dark .bp3-menu-item.bp3-active, .bp3-dark .bp3-menu-item:active {
  background-color: rgba(138, 155, 168, 0.3); }

.bp3-dark .bp3-menu-item.bp3-disabled {
  color: rgba(191, 204, 214, 0.5) !important; }
  .bp3-dark .bp3-menu-item.bp3-disabled::before,
  .bp3-dark .bp3-menu-item.bp3-disabled > .bp3-icon,
  .bp3-dark .bp3-menu-item.bp3-disabled .bp3-menu-item-label {
    color: rgba(191, 204, 214, 0.5) !important; }

.bp3-dark .bp3-menu-divider,
.bp3-dark .bp3-menu-header {
  border-color: rgba(255, 255, 255, 0.15); }

.bp3-dark .bp3-menu-header > h6 {
  color: #f5f8fa; }

.bp3-label .bp3-menu {
  margin-top: 5px; }

/*
Navbars

Markup:
<nav class="bp3-navbar {{.modifier}}">
  <div class="bp3-navbar-group bp3-align-left">
    <div class="bp3-navbar-heading">Blueprint</div>
    <input class="bp3-input" placeholder="Search files..." type="text" />
  </div>
  <div class="bp3-navbar-group bp3-align-right">
    <button class="bp3-button bp3-minimal bp3-icon-home">Home</button>
    <button class="bp3-button bp3-minimal bp3-icon-document">Files</button>
    <span class="bp3-navbar-divider"></span>
    <button class="bp3-button bp3-minimal bp3-icon-user"></button>
    <button class="bp3-button bp3-minimal bp3-icon-notifications"></button>
    <button class="bp3-button bp3-minimal bp3-icon-cog"></button>
  </div>
</nav>

.bp3-dark - Dark theme

Styleguide navbar
*/
.bp3-navbar {
  position: relative;
  z-index: 10;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 100%;
  height: 50px;
  padding: 0 15px; }
  .bp3-navbar.bp3-dark,
  .bp3-dark .bp3-navbar {
    background-color: #394b59; }
  .bp3-navbar.bp3-dark {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.4); }
  .bp3-dark .bp3-navbar {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0), 0 1px 1px rgba(0, 0, 0, 0.4); }
  .bp3-navbar.bp3-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0; }

.bp3-navbar-heading {
  margin-right: 15px;
  font-size: 16px; }

.bp3-navbar-group {
  display: flex;
  align-items: center;
  height: 50px; }
  .bp3-navbar-group.bp3-align-left {
    float: left; }
  .bp3-navbar-group.bp3-align-right {
    float: right; }

.bp3-navbar-divider {
  margin: 0 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 20px; }
  .bp3-dark .bp3-navbar-divider {
    border-left-color: rgba(255, 255, 255, 0.15); }

/*
Fixed width

Markup:
<nav class="bp3-navbar bp3-dark">
  <div style="margin: 0 auto; width: 480px;"> <!-- ADD ME -->
    <div class="bp3-navbar-group bp3-align-left">
      <div class="bp3-navbar-heading">Blueprint</div>
    </div>
    <div class="bp3-navbar-group bp3-align-right">
      <button class="bp3-button bp3-minimal bp3-icon-home">Home</button>
      <button class="bp3-button bp3-minimal bp3-icon-document">Files</button>
      <span class="bp3-navbar-divider"></span>
      <button class="bp3-button bp3-minimal bp3-icon-user"></button>
      <button class="bp3-button bp3-minimal bp3-icon-notifications"></button>
      <button class="bp3-button bp3-minimal bp3-icon-cog"></button>
    </div>
  </div>
</nav>

Styleguide navbar-container
*/
/*
Non-ideal state

Markup:
<div class="bp3-non-ideal-state">
  <div class="bp3-non-ideal-state-visual">
    <span class="bp3-icon bp3-icon-folder-open"></span>
  </div>
  <h4 class="bp3-heading">This folder is empty</h4>
  <div>Create a new file to populate the folder.</div>
  <button class="bp3-button bp3-intent-primary">Create</button>
</div>

Styleguide non-ideal-state
*/
.bp3-non-ideal-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center; }
  .bp3-non-ideal-state > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-non-ideal-state > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-non-ideal-state::before,
  .bp3-non-ideal-state > * {
    margin-bottom: 20px; }
  .bp3-non-ideal-state:empty::before,
  .bp3-non-ideal-state > :last-child {
    margin-bottom: 0; }
  .bp3-non-ideal-state > * {
    max-width: 400px; }

.bp3-non-ideal-state-visual {
  color: rgba(92, 112, 128, 0.5);
  font-size: 60px; }
  .bp3-dark .bp3-non-ideal-state-visual {
    color: rgba(191, 204, 214, 0.5); }

.bp3-overflow-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0; }

.bp3-overflow-list-spacer {
  flex-shrink: 1;
  width: 1px; }

body.bp3-overlay-open {
  overflow: hidden; }

.bp3-overlay {
  position: static;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20; }
  .bp3-overlay:not(.bp3-overlay-open) {
    pointer-events: none; }
  .bp3-overlay.bp3-overlay-container {
    position: fixed;
    overflow: hidden; }
    .bp3-overlay.bp3-overlay-container.bp3-overlay-inline {
      position: absolute; }
  .bp3-overlay.bp3-overlay-scroll-container {
    position: fixed;
    overflow: auto; }
    .bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {
      position: absolute; }
  .bp3-overlay.bp3-overlay-inline {
    display: inline;
    overflow: visible; }

.bp3-overlay-content {
  position: fixed;
  z-index: 20; }
  .bp3-overlay-inline .bp3-overlay-content,
  .bp3-overlay-scroll-container .bp3-overlay-content {
    position: absolute; }

.bp3-overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
  user-select: none; }
  .bp3-overlay-backdrop.bp3-overlay-enter, .bp3-overlay-backdrop.bp3-overlay-appear {
    opacity: 0; }
  .bp3-overlay-backdrop.bp3-overlay-enter-active, .bp3-overlay-backdrop.bp3-overlay-appear-active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .bp3-overlay-backdrop.bp3-overlay-exit {
    opacity: 1; }
  .bp3-overlay-backdrop.bp3-overlay-exit-active {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .bp3-overlay-backdrop:focus {
    outline: none; }
  .bp3-overlay-inline .bp3-overlay-backdrop {
    position: absolute; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-panel-stack {
  position: relative;
  overflow: hidden; }

.bp3-panel-stack-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  z-index: 1;
  box-shadow: 0 1px rgba(0, 0, 0, 0.15);
  height: 30px; }
  .bp3-dark .bp3-panel-stack-header {
    box-shadow: 0 1px rgba(255, 255, 255, 0.15); }
  .bp3-panel-stack-header > span {
    display: flex;
    flex: 1;
    align-items: stretch; }
  .bp3-panel-stack-header .bp3-heading {
    margin: 0 5px; }

.bp3-button.bp3-panel-stack-header-back {
  margin-left: 5px;
  padding-left: 0;
  white-space: nowrap; }
  .bp3-button.bp3-panel-stack-header-back .bp3-icon {
    margin: 0 2px; }

.bp3-panel-stack-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  overflow-y: auto; }
  .bp3-dark .bp3-panel-stack-view {
    background-color: #30404d; }

.bp3-panel-stack-push .bp3-panel-stack-enter, .bp3-panel-stack-push .bp3-panel-stack-appear {
  transform: translateX(100%);
  opacity: 0; }

.bp3-panel-stack-push .bp3-panel-stack-enter-active, .bp3-panel-stack-push .bp3-panel-stack-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
  transition-delay: 0; }

.bp3-panel-stack-push .bp3-panel-stack-exit {
  transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-push .bp3-panel-stack-exit-active {
  transform: translateX(-50%);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
  transition-delay: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-enter, .bp3-panel-stack-pop .bp3-panel-stack-appear {
  transform: translateX(-50%);
  opacity: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-enter-active, .bp3-panel-stack-pop .bp3-panel-stack-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
  transition-delay: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-exit {
  transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-pop .bp3-panel-stack-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
  transition-delay: 0; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-popover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.2);
  transform: scale(1);
  display: inline-block;
  z-index: 20;
  border-radius: 3px; }
  .bp3-popover .bp3-popover-arrow {
    position: absolute;
    width: 30px;
    height: 30px; }
    .bp3-popover .bp3-popover-arrow::before {
      margin: 5px;
      width: 20px;
      height: 20px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover {
    margin-top: -17px;
    margin-bottom: 17px; }
    .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
      bottom: -11px; }
      .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(-90deg); }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover {
    margin-left: 17px; }
    .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
      left: -11px; }
      .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(0); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover {
    margin-top: 17px; }
    .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
      top: -11px; }
      .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(90deg); }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover {
    margin-right: 17px;
    margin-left: -17px; }
    .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
      right: -11px; }
      .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(180deg); }
  .bp3-tether-element-attached-middle > .bp3-popover > .bp3-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .bp3-tether-element-attached-center > .bp3-popover > .bp3-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
    top: -0.3934px; }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
    right: -0.3934px; }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
    left: -0.3934px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
    bottom: -0.3934px; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-popover {
    transform-origin: top left; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-popover {
    transform-origin: top center; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-popover {
    transform-origin: top right; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-popover {
    transform-origin: center left; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-popover {
    transform-origin: center center; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-popover {
    transform-origin: center right; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-popover {
    transform-origin: bottom left; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-popover {
    transform-origin: bottom center; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-popover {
    transform-origin: bottom right; }
  .bp3-popover .bp3-popover-content {
    background: #fff;
    color: inherit; }
  .bp3-popover .bp3-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2); }
  .bp3-popover .bp3-popover-arrow-border {
    fill: #000;
    fill-opacity: 0.1; }
  .bp3-popover .bp3-popover-arrow-fill {
    fill: #fff; }
  .bp3-popover-enter > .bp3-popover, .bp3-popover-appear > .bp3-popover {
    transform: scale(0.3); }
  .bp3-popover-enter-active > .bp3-popover, .bp3-popover-appear-active > .bp3-popover {
    transform: scale(1);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .bp3-popover-exit > .bp3-popover {
    transform: scale(1); }
  .bp3-popover-exit-active > .bp3-popover {
    transform: scale(0.3);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .bp3-popover .bp3-popover-content {
    position: relative;
    border-radius: 3px; }
  .bp3-popover.bp3-popover-content-sizing .bp3-popover-content {
    max-width: 350px;
    padding: 20px; }
  .bp3-popover-target + .bp3-overlay .bp3-popover.bp3-popover-content-sizing {
    width: 350px; }
  .bp3-popover.bp3-minimal {
    margin: 0 !important; }
    .bp3-popover.bp3-minimal .bp3-popover-arrow {
      display: none; }
    .bp3-popover.bp3-minimal.bp3-popover {
      transform: scale(1); }
      .bp3-popover-enter > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1); }
      .bp3-popover-enter-active > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear-active > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1);
        transition-property: transform;
        transition-duration: 100ms;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
        transition-delay: 0; }
      .bp3-popover-exit > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1); }
      .bp3-popover-exit-active > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1);
        transition-property: transform;
        transition-duration: 100ms;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
        transition-delay: 0; }
  .bp3-popover.bp3-dark,
  .bp3-dark .bp3-popover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4); }
    .bp3-popover.bp3-dark .bp3-popover-content,
    .bp3-dark .bp3-popover .bp3-popover-content {
      background: #30404d;
      color: inherit; }
    .bp3-popover.bp3-dark .bp3-popover-arrow::before,
    .bp3-dark .bp3-popover .bp3-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4); }
    .bp3-popover.bp3-dark .bp3-popover-arrow-border,
    .bp3-dark .bp3-popover .bp3-popover-arrow-border {
      fill: #000;
      fill-opacity: 0.2; }
    .bp3-popover.bp3-dark .bp3-popover-arrow-fill,
    .bp3-dark .bp3-popover .bp3-popover-arrow-fill {
      fill: #30404d; }

.bp3-popover-arrow::before {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 2px;
  content: ""; }

.bp3-tether-pinned .bp3-popover-arrow {
  display: none; }

.bp3-popover-backdrop {
  background: rgba(255, 255, 255, 0); }

.bp3-transition-container {
  opacity: 1;
  display: flex;
  z-index: 20; }
  .bp3-transition-container.bp3-popover-enter, .bp3-transition-container.bp3-popover-appear {
    opacity: 0; }
  .bp3-transition-container.bp3-popover-enter-active, .bp3-transition-container.bp3-popover-appear-active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .bp3-transition-container.bp3-popover-exit {
    opacity: 1; }
  .bp3-transition-container.bp3-popover-exit-active {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .bp3-transition-container:focus {
    outline: none; }
  .bp3-transition-container.bp3-popover-leave .bp3-popover-content {
    pointer-events: none; }
  .bp3-transition-container[data-x-out-of-boundaries] {
    display: none; }

span.bp3-popover-target {
  display: inline-block; }

.bp3-portal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }

/*
Progress bars

Markup:
<div class="bp3-progress-bar {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 25%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-primary {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 50%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-success {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 75%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-danger {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 100%"></div>
</div>

.bp3-no-stripes   - No stripes
.bp3-no-animation - No animation

Styleguide progress-bar
*/
@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.bp3-progress-bar {
  display: block;
  position: relative;
  border-radius: 40px;
  background: rgba(92, 112, 128, 0.2);
  width: 100%;
  height: 8px;
  overflow: hidden; }
  .bp3-progress-bar .bp3-progress-meter {
    position: absolute;
    border-radius: 40px;
    background: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
    background-color: rgba(92, 112, 128, 0.8);
    background-size: 30px 30px;
    width: 100%;
    height: 100%;
    transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-progress-bar:not(.bp3-no-animation):not(.bp3-no-stripes) .bp3-progress-meter {
    animation: linear-progress-bar-stripes 300ms linear infinite reverse; }
  .bp3-progress-bar.bp3-no-stripes .bp3-progress-meter {
    background-image: none; }

.bp3-dark .bp3-progress-bar {
  background: rgba(0, 0, 0, 0.5); }
  .bp3-dark .bp3-progress-bar .bp3-progress-meter {
    background-color: #8a9ba8; }

.bp3-progress-bar.bp3-intent-primary .bp3-progress-meter {
  background-color: #137cbd; }

.bp3-progress-bar.bp3-intent-success .bp3-progress-meter {
  background-color: #0f9960; }

.bp3-progress-bar.bp3-intent-warning .bp3-progress-meter {
  background-color: #d9822b; }

.bp3-progress-bar.bp3-intent-danger .bp3-progress-meter {
  background-color: #db3737; }

/*
Skeletons

Markup:
<div class="bp3-card">
  <h5 class="bp3-heading"><a class="{{.modifier}}" href="#" tabindex="-1">Card heading</a></h5>
  <p class="{{.modifier}}">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis.
    Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
  </p>
  <button type="button" class="bp3-button bp3-icon-add {{.modifier}}" tabindex="-1">Submit</button>
</div>

.bp3-skeleton - Render this element as a skeleton, an outline of its true self.

Styleguide skeleton
*/
@keyframes skeleton-glow {
  from {
    border-color: rgba(206, 217, 224, 0.2);
    background: rgba(206, 217, 224, 0.2); }
  to {
    border-color: rgba(92, 112, 128, 0.2);
    background: rgba(92, 112, 128, 0.2); } }

/* stylelint-disable declaration-no-important */
.bp3-skeleton {
  border-color: rgba(206, 217, 224, 0.2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: rgba(206, 217, 224, 0.2);
  background-clip: padding-box !important;
  cursor: default;
  color: transparent !important;
  animation: 1000ms linear infinite alternate skeleton-glow;
  pointer-events: none;
  user-select: none; }
  .bp3-skeleton::before, .bp3-skeleton::after,
  .bp3-skeleton * {
    visibility: hidden !important; }

/* stylelint-enable declaration-no-important */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-slider {
  width: 100%;
  min-width: 150px;
  height: 40px;
  position: relative;
  outline: none;
  cursor: default;
  user-select: none; }
  .bp3-slider:hover {
    cursor: pointer; }
  .bp3-slider:active {
    cursor: grabbing; }
  .bp3-slider.bp3-disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .bp3-slider.bp3-slider-unlabeled {
    height: 16px; }

.bp3-slider-track,
.bp3-slider-progress {
  top: 5px;
  right: 0;
  left: 0;
  height: 6px;
  position: absolute; }

.bp3-slider-track {
  border-radius: 3px;
  overflow: hidden; }

.bp3-slider-progress {
  background: rgba(92, 112, 128, 0.2); }
  .bp3-dark .bp3-slider-progress {
    background: rgba(0, 0, 0, 0.5); }
  .bp3-slider-progress.bp3-intent-primary {
    background-color: #137cbd; }
  .bp3-slider-progress.bp3-intent-success {
    background-color: #0f9960; }
  .bp3-slider-progress.bp3-intent-warning {
    background-color: #d9822b; }
  .bp3-slider-progress.bp3-intent-danger {
    background-color: #db3737; }

.bp3-slider-handle {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 16px;
  height: 16px; }
  .bp3-slider-handle:hover {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .bp3-slider-handle:active, .bp3-slider-handle.bp3-active {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .bp3-slider-handle:disabled, .bp3-slider-handle.bp3-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .bp3-slider-handle:disabled.bp3-active, .bp3-slider-handle:disabled.bp3-active:hover, .bp3-slider-handle.bp3-disabled.bp3-active, .bp3-slider-handle.bp3-disabled.bp3-active:hover {
      background: rgba(206, 217, 224, 0.7); }
  .bp3-slider-handle:focus {
    z-index: 1; }
  .bp3-slider-handle:hover {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5;
    z-index: 2;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.2);
    cursor: grab; }
  .bp3-slider-handle.bp3-active {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #d8e1e8;
    background-image: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(0, 0, 0, 0.1);
    cursor: grabbing; }
  .bp3-disabled .bp3-slider-handle {
    box-shadow: none;
    background: #bfccd6;
    pointer-events: none; }
  .bp3-dark .bp3-slider-handle {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
    background-color: #394b59;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    color: #f5f8fa; }
    .bp3-dark .bp3-slider-handle:hover, .bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
      color: #f5f8fa; }
    .bp3-dark .bp3-slider-handle:hover {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
      background-color: #30404d; }
    .bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6), inset 0 1px 2px rgba(0, 0, 0, 0.2);
      background-color: #202b33;
      background-image: none; }
    .bp3-dark .bp3-slider-handle:disabled, .bp3-dark .bp3-slider-handle.bp3-disabled {
      box-shadow: none;
      background-color: rgba(57, 75, 89, 0.5);
      background-image: none;
      color: rgba(191, 204, 214, 0.5); }
      .bp3-dark .bp3-slider-handle:disabled.bp3-active, .bp3-dark .bp3-slider-handle.bp3-disabled.bp3-active {
        background: rgba(57, 75, 89, 0.7); }
    .bp3-dark .bp3-slider-handle .bp3-button-spinner .bp3-spinner-head {
      background: rgba(0, 0, 0, 0.5);
      stroke: #8a9ba8; }
    .bp3-dark .bp3-slider-handle, .bp3-dark .bp3-slider-handle:hover {
      background-color: #394b59; }
    .bp3-dark .bp3-slider-handle.bp3-active {
      background-color: #293742; }
  .bp3-dark .bp3-disabled .bp3-slider-handle {
    border-color: #5c7080;
    box-shadow: none;
    background: #5c7080; }
  .bp3-slider-handle .bp3-slider-label {
    margin-left: 8px;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.2);
    background: #394b59;
    color: #f5f8fa; }
    .bp3-dark .bp3-slider-handle .bp3-slider-label {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4);
      background: #e1e8ed;
      color: #394b59; }
    .bp3-disabled .bp3-slider-handle .bp3-slider-label {
      box-shadow: none; }
  .bp3-slider-handle.bp3-start, .bp3-slider-handle.bp3-end {
    width: 8px; }
  .bp3-slider-handle.bp3-start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bp3-slider-handle.bp3-end {
    margin-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .bp3-slider-handle.bp3-end .bp3-slider-label {
      margin-left: 0; }

.bp3-slider-label {
  transform: translate(-50%, 20px);
  display: inline-block;
  position: absolute;
  padding: 2px 5px;
  vertical-align: top;
  line-height: 1;
  font-size: 12px; }

.bp3-slider.bp3-vertical {
  width: 40px;
  min-width: 40px;
  height: 150px; }
  .bp3-slider.bp3-vertical .bp3-slider-track,
  .bp3-slider.bp3-vertical .bp3-slider-progress {
    top: 0;
    bottom: 0;
    left: 5px;
    width: 6px;
    height: auto; }
  .bp3-slider.bp3-vertical .bp3-slider-progress {
    top: auto; }
  .bp3-slider.bp3-vertical .bp3-slider-label {
    transform: translate(20px, 50%); }
  .bp3-slider.bp3-vertical .bp3-slider-handle {
    top: auto; }
    .bp3-slider.bp3-vertical .bp3-slider-handle .bp3-slider-label {
      margin-top: -8px;
      margin-left: 0; }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end, .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
      margin-left: 0;
      width: 16px;
      height: 8px; }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
      border-top-left-radius: 0;
      border-bottom-right-radius: 3px; }
      .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start .bp3-slider-label {
        transform: translate(20px); }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end {
      margin-bottom: 8px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }

@keyframes pt-spinner-animation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.bp3-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  vertical-align: middle; }
  .bp3-spinner svg {
    display: block; }
  .bp3-spinner path {
    fill-opacity: 0; }
  .bp3-spinner .bp3-spinner-head {
    transform-origin: center;
    transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
    stroke: rgba(92, 112, 128, 0.8);
    stroke-linecap: round; }
  .bp3-spinner .bp3-spinner-track {
    stroke: rgba(92, 112, 128, 0.2); }

.bp3-spinner-animation {
  animation: pt-spinner-animation 500ms linear infinite; }
  .bp3-no-spin > .bp3-spinner-animation {
    animation: none; }

.bp3-dark .bp3-spinner .bp3-spinner-head {
  stroke: #8a9ba8; }

.bp3-dark .bp3-spinner .bp3-spinner-track {
  stroke: rgba(0, 0, 0, 0.5); }

.bp3-spinner.bp3-intent-primary .bp3-spinner-head {
  stroke: #137cbd; }

.bp3-spinner.bp3-intent-success .bp3-spinner-head {
  stroke: #0f9960; }

.bp3-spinner.bp3-intent-warning .bp3-spinner-head {
  stroke: #d9822b; }

.bp3-spinner.bp3-intent-danger .bp3-spinner-head {
  stroke: #db3737; }

/*
Tabs

Markup:
<div class="bp3-tabs">
    <ul class="bp3-tab-list {{.modifier}}" role="tablist">
        <li class="bp3-tab" role="tab" aria-selected="true">Selected tab</li>
        <li class="bp3-tab" role="tab">Another tab</li>
        <li class="bp3-tab" role="tab" aria-disabled="true">Disabled tab</li>
    </ul>
    <div class="bp3-tab-panel" role="tabpanel">Selected panel</div>
    <div class="bp3-tab-panel" role="tabpanel" aria-hidden="true">Another panel</div>
    <div class="bp3-tab-panel" role="tabpanel" aria-hidden="true">Disabled panel</div>
</div>

.bp3-large - Large tabs

Styleguide tabs
*/
.bp3-tabs.bp3-vertical {
  display: flex; }
  .bp3-tabs.bp3-vertical > .bp3-tab-list {
    flex-direction: column;
    align-items: flex-start; }
    .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab {
      border-radius: 3px;
      width: 100%;
      padding: 0 10px; }
      .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"] {
        box-shadow: none;
        background-color: rgba(19, 124, 189, 0.2); }
    .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab-indicator-wrapper .bp3-tab-indicator {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 3px;
      background-color: rgba(19, 124, 189, 0.2);
      height: auto; }
  .bp3-tabs.bp3-vertical > .bp3-tab-panel {
    margin-top: 0;
    padding-left: 20px; }

.bp3-tab-list {
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
  position: relative;
  margin: 0;
  border: none;
  padding: 0;
  list-style: none; }
  .bp3-tab-list > *:not(:last-child) {
    margin-right: 20px; }

.bp3-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  vertical-align: top;
  line-height: 30px;
  color: #182026;
  font-size: 14px; }
  .bp3-tab a {
    display: block;
    text-decoration: none;
    color: inherit; }
  .bp3-tab-indicator-wrapper ~ .bp3-tab {
    box-shadow: none !important;
    background-color: transparent !important; }
  .bp3-tab[aria-disabled="true"] {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .bp3-tab[aria-selected="true"] {
    border-radius: 0;
    box-shadow: inset 0 -3px 0 #106ba3; }
  .bp3-tab[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover {
    color: #106ba3; }
  .bp3-tab:focus {
    -moz-outline-radius: 0; }
  .bp3-large > .bp3-tab {
    line-height: 40px;
    font-size: 16px; }

.bp3-tab-panel {
  margin-top: 20px; }
  .bp3-tab-panel[aria-hidden="true"] {
    display: none; }

.bp3-tab-indicator-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0), translateY(0);
  transition: height, transform, width;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  pointer-events: none; }
  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #106ba3;
    height: 3px; }
  .bp3-tab-indicator-wrapper.bp3-no-animation {
    transition: none; }

.bp3-dark .bp3-tab {
  color: #f5f8fa; }
  .bp3-dark .bp3-tab[aria-disabled="true"] {
    color: rgba(191, 204, 214, 0.5); }
  .bp3-dark .bp3-tab[aria-selected="true"] {
    box-shadow: inset 0 -3px 0 #48aff0; }
  .bp3-dark .bp3-tab[aria-selected="true"], .bp3-dark .bp3-tab:not([aria-disabled="true"]):hover {
    color: #48aff0; }

.bp3-dark .bp3-tab-indicator {
  background-color: #48aff0; }

.bp3-flex-expander {
  flex: 1 1; }

/*
Tags

Markup:
<span class="bp3-tag {{.modifier}}">125</span>
<span class="bp3-tag {{.modifier}}">Done</span>
<span class="bp3-tag {{.modifier}}">
  Tracking
  <button class="bp3-tag-remove"></button>
</span>
<span class="bp3-tag {{.modifier}}">
  Crushed
  <button class="bp3-tag-remove"></button>
</span>
<span class="bp3-tag {{.modifier}}">
  A rather long string of text that wraps to multiple lines
  demonstrates the position of the remove button.
  <button class="bp3-tag-remove"></button>
</span>

.bp3-large - Large
.bp3-minimal - Minimal appearance
.bp3-round - Rounded corners, ideal for badges
.bp3-interactive - Hover and active effects
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger  - Danger intent

Styleguide tag
*/
.bp3-tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  background-color: #5c7080;
  min-width: 20px;
  max-width: 100%;
  min-height: 20px;
  padding: 2px 6px;
  line-height: 16px;
  color: #f5f8fa;
  font-size: 12px; }
  .bp3-tag.bp3-interactive {
    cursor: pointer; }
    .bp3-tag.bp3-interactive:hover {
      background-color: rgba(92, 112, 128, 0.85); }
    .bp3-tag.bp3-interactive.bp3-active, .bp3-tag.bp3-interactive:active {
      background-color: rgba(92, 112, 128, 0.7); }
  .bp3-tag > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-tag > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-tag::before,
  .bp3-tag > * {
    margin-right: 4px; }
  .bp3-tag:empty::before,
  .bp3-tag > :last-child {
    margin-right: 0; }
  .bp3-tag:focus {
    outline: rgba(19, 124, 189, 0.6) auto 2px;
    outline-offset: 0;
    -moz-outline-radius: 6px; }
  .bp3-tag.bp3-round {
    border-radius: 30px;
    padding-right: 8px;
    padding-left: 8px; }
  .bp3-dark .bp3-tag {
    background-color: #bfccd6;
    color: #182026; }
    .bp3-dark .bp3-tag.bp3-interactive {
      cursor: pointer; }
      .bp3-dark .bp3-tag.bp3-interactive:hover {
        background-color: rgba(191, 204, 214, 0.85); }
      .bp3-dark .bp3-tag.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-interactive:active {
        background-color: rgba(191, 204, 214, 0.7); }
    .bp3-dark .bp3-tag > .bp3-icon, .bp3-dark .bp3-tag .bp3-icon-standard, .bp3-dark .bp3-tag .bp3-icon-large {
      fill: currentColor; }
  .bp3-tag > .bp3-icon, .bp3-tag .bp3-icon-standard, .bp3-tag .bp3-icon-large {
    fill: #fff; }
  .bp3-tag.bp3-large,
  .bp3-large .bp3-tag {
    min-width: 30px;
    min-height: 30px;
    padding: 0 10px;
    line-height: 20px;
    font-size: 14px; }
    .bp3-tag.bp3-large::before,
    .bp3-tag.bp3-large > *,
    .bp3-large .bp3-tag::before,
    .bp3-large .bp3-tag > * {
      margin-right: 7px; }
    .bp3-tag.bp3-large:empty::before,
    .bp3-tag.bp3-large > :last-child,
    .bp3-large .bp3-tag:empty::before,
    .bp3-large .bp3-tag > :last-child {
      margin-right: 0; }
    .bp3-tag.bp3-large.bp3-round,
    .bp3-large .bp3-tag.bp3-round {
      padding-right: 12px;
      padding-left: 12px; }
  .bp3-tag.bp3-intent-primary {
    background: #137cbd;
    color: #fff; }
    .bp3-tag.bp3-intent-primary.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-primary.bp3-interactive:hover {
        background-color: rgba(19, 124, 189, 0.85); }
      .bp3-tag.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-primary.bp3-interactive:active {
        background-color: rgba(19, 124, 189, 0.7); }
  .bp3-tag.bp3-intent-success {
    background: #0f9960;
    color: #fff; }
    .bp3-tag.bp3-intent-success.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-success.bp3-interactive:hover {
        background-color: rgba(15, 153, 96, 0.85); }
      .bp3-tag.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-success.bp3-interactive:active {
        background-color: rgba(15, 153, 96, 0.7); }
  .bp3-tag.bp3-intent-warning {
    background: #d9822b;
    color: #fff; }
    .bp3-tag.bp3-intent-warning.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-warning.bp3-interactive:hover {
        background-color: rgba(217, 130, 43, 0.85); }
      .bp3-tag.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-warning.bp3-interactive:active {
        background-color: rgba(217, 130, 43, 0.7); }
  .bp3-tag.bp3-intent-danger {
    background: #db3737;
    color: #fff; }
    .bp3-tag.bp3-intent-danger.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-danger.bp3-interactive:hover {
        background-color: rgba(219, 55, 55, 0.85); }
      .bp3-tag.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-danger.bp3-interactive:active {
        background-color: rgba(219, 55, 55, 0.7); }
  .bp3-tag.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-tag.bp3-minimal > .bp3-icon, .bp3-tag.bp3-minimal .bp3-icon-standard, .bp3-tag.bp3-minimal .bp3-icon-large {
    fill: #5c7080; }
  .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
    background-color: rgba(138, 155, 168, 0.2);
    color: #182026; }
    .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
        background-color: rgba(92, 112, 128, 0.3); }
      .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
        background-color: rgba(92, 112, 128, 0.4); }
    .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
      color: #f5f8fa; }
      .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
          background-color: rgba(191, 204, 214, 0.3); }
        .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
          background-color: rgba(191, 204, 214, 0.4); }
      .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) > .bp3-icon, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-large {
        fill: #bfccd6; }
  .bp3-tag.bp3-minimal.bp3-intent-primary {
    background-color: rgba(19, 124, 189, 0.15);
    color: #106ba3; }
    .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
        background-color: rgba(19, 124, 189, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
        background-color: rgba(19, 124, 189, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-primary > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-large {
      fill: #137cbd; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary {
      background-color: rgba(19, 124, 189, 0.25);
      color: #48aff0; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
          background-color: rgba(19, 124, 189, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
          background-color: rgba(19, 124, 189, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.15);
    color: #0d8050; }
    .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
        background-color: rgba(15, 153, 96, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
        background-color: rgba(15, 153, 96, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-success > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-large {
      fill: #0f9960; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success {
      background-color: rgba(15, 153, 96, 0.25);
      color: #3dcc91; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
          background-color: rgba(15, 153, 96, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
          background-color: rgba(15, 153, 96, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.15);
    color: #bf7326; }
    .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
        background-color: rgba(217, 130, 43, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
        background-color: rgba(217, 130, 43, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-warning > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-large {
      fill: #d9822b; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning {
      background-color: rgba(217, 130, 43, 0.25);
      color: #ffb366; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
          background-color: rgba(217, 130, 43, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
          background-color: rgba(217, 130, 43, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.15);
    color: #c23030; }
    .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
        background-color: rgba(219, 55, 55, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
        background-color: rgba(219, 55, 55, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-danger > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-large {
      fill: #db3737; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger {
      background-color: rgba(219, 55, 55, 0.25);
      color: #ff7373; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
          background-color: rgba(219, 55, 55, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
          background-color: rgba(219, 55, 55, 0.45); }

.bp3-tag-remove {
  display: flex;
  opacity: 0.5;
  margin-top: -2px;
  margin-right: -6px !important;
  margin-bottom: -2px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px;
  padding-left: 0;
  color: inherit; }
  .bp3-tag-remove:hover {
    opacity: 0.8;
    background: none;
    text-decoration: none; }
  .bp3-tag-remove:active {
    opacity: 1; }
  .bp3-tag-remove:empty::before {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "\E6D7"; }
  .bp3-large .bp3-tag-remove {
    margin-right: -10px !important;
    padding: 5px;
    padding-left: 0; }
    .bp3-large .bp3-tag-remove:empty::before {
      line-height: 1;
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-tag-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: text;
  height: auto;
  min-height: 30px;
  padding-right: 0;
  padding-left: 5px;
  line-height: inherit; }
  .bp3-tag-input > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-tag-input > .bp3-tag-input-values {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-tag-input .bp3-tag-input-icon {
    margin-top: 7px;
    margin-right: 7px;
    margin-left: 2px;
    color: #5c7080; }
  .bp3-tag-input .bp3-tag-input-values {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-self: stretch;
    margin-top: 5px;
    margin-right: 7px; }
    .bp3-tag-input .bp3-tag-input-values > * {
      flex-grow: 0;
      flex-shrink: 0; }
    .bp3-tag-input .bp3-tag-input-values > .bp3-fill {
      flex-grow: 1;
      flex-shrink: 1; }
    .bp3-tag-input .bp3-tag-input-values::before,
    .bp3-tag-input .bp3-tag-input-values > * {
      margin-right: 5px; }
    .bp3-tag-input .bp3-tag-input-values:empty::before,
    .bp3-tag-input .bp3-tag-input-values > :last-child {
      margin-right: 0; }
    .bp3-tag-input .bp3-tag-input-values:first-child .bp3-input-ghost:first-child {
      padding-left: 5px; }
    .bp3-tag-input .bp3-tag-input-values > * {
      margin-bottom: 5px; }
  .bp3-tag-input .bp3-tag {
    overflow-wrap: break-word; }
    .bp3-tag-input .bp3-tag.bp3-active {
      outline: rgba(19, 124, 189, 0.6) auto 2px;
      outline-offset: 0;
      -moz-outline-radius: 6px; }
  .bp3-tag-input .bp3-input-ghost {
    flex: 1 1 auto;
    width: 80px;
    line-height: 20px; }
    .bp3-tag-input .bp3-input-ghost:disabled, .bp3-tag-input .bp3-input-ghost.bp3-disabled {
      cursor: not-allowed; }
  .bp3-tag-input .bp3-button,
  .bp3-tag-input .bp3-spinner {
    margin: 3px;
    margin-left: 0; }
  .bp3-tag-input .bp3-button {
    min-width: 24px;
    min-height: 24px;
    padding: 0 7px; }
  .bp3-tag-input.bp3-large {
    height: auto;
    min-height: 40px; }
    .bp3-tag-input.bp3-large::before,
    .bp3-tag-input.bp3-large > * {
      margin-right: 10px; }
    .bp3-tag-input.bp3-large:empty::before,
    .bp3-tag-input.bp3-large > :last-child {
      margin-right: 0; }
    .bp3-tag-input.bp3-large .bp3-tag-input-icon {
      margin-top: 10px;
      margin-left: 5px; }
    .bp3-tag-input.bp3-large .bp3-input-ghost {
      line-height: 30px; }
    .bp3-tag-input.bp3-large .bp3-button {
      min-width: 30px;
      min-height: 30px;
      padding: 5px 10px;
      margin: 5px;
      margin-left: 0; }
    .bp3-tag-input.bp3-large .bp3-spinner {
      margin: 8px;
      margin-left: 0; }
  .bp3-tag-input.bp3-active {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #fff; }
  .bp3-dark .bp3-tag-input .bp3-tag-input-icon, .bp3-tag-input.bp3-dark .bp3-tag-input-icon {
    color: #bfccd6; }
  .bp3-dark .bp3-tag-input .bp3-input-ghost, .bp3-tag-input.bp3-dark .bp3-input-ghost {
    color: #f5f8fa; }
    .bp3-dark .bp3-tag-input .bp3-input-ghost::placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost::placeholder {
      color: rgba(191, 204, 214, 0.5); }
  .bp3-dark .bp3-tag-input.bp3-active, .bp3-tag-input.bp3-dark.bp3-active {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(0, 0, 0, 0.3), inset 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.3); }

.bp3-input-ghost {
  border: none;
  box-shadow: none;
  background: none;
  padding: 0; }
  .bp3-input-ghost::placeholder {
    opacity: 1;
    color: #6c757d; }
  .bp3-input-ghost:focus {
    outline: none !important; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-toast {
  display: flex;
  align-items: flex-start;
  position: relative !important;
  margin: 20px 0 0;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  min-width: 300px;
  max-width: 350px;
  pointer-events: all; }
  .bp3-toast.bp3-toast-enter, .bp3-toast.bp3-toast-appear {
    transform: translateY(-40px); }
  .bp3-toast.bp3-toast-enter-active, .bp3-toast.bp3-toast-appear-active {
    transform: translateY(0);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .bp3-toast.bp3-toast-enter ~ .bp3-toast, .bp3-toast.bp3-toast-appear ~ .bp3-toast {
    transform: translateY(-40px); }
  .bp3-toast.bp3-toast-enter-active ~ .bp3-toast, .bp3-toast.bp3-toast-appear-active ~ .bp3-toast {
    transform: translateY(0);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .bp3-toast.bp3-toast-exit {
    opacity: 1;
    filter: blur(0); }
  .bp3-toast.bp3-toast-exit-active {
    opacity: 0;
    filter: blur(10px);
    transition-property: opacity, filter;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .bp3-toast.bp3-toast-exit ~ .bp3-toast {
    transform: translateY(0); }
  .bp3-toast.bp3-toast-exit-active ~ .bp3-toast {
    transform: translateY(-40px);
    transition-property: transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 50ms; }
  .bp3-toast .bp3-button-group {
    flex: 0 0 auto;
    padding: 5px;
    padding-left: 0; }
  .bp3-toast > .bp3-icon {
    margin: 12px;
    margin-right: 0;
    color: #5c7080; }
  .bp3-toast.bp3-dark,
  .bp3-dark .bp3-toast {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4);
    background-color: #394b59; }
    .bp3-toast.bp3-dark > .bp3-icon,
    .bp3-dark .bp3-toast > .bp3-icon {
      color: #bfccd6; }
  .bp3-toast[class*="bp3-intent-"] a {
    color: rgba(255, 255, 255, 0.7); }
    .bp3-toast[class*="bp3-intent-"] a:hover {
      color: #fff; }
  .bp3-toast[class*="bp3-intent-"] > .bp3-icon {
    color: #fff; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button, .bp3-toast[class*="bp3-intent-"] .bp3-button::before,
  .bp3-toast[class*="bp3-intent-"] .bp3-button .bp3-icon, .bp3-toast[class*="bp3-intent-"] .bp3-button:active {
    color: rgba(255, 255, 255, 0.7) !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:focus {
    outline-color: rgba(255, 255, 255, 0.5); }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
    color: #fff !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:active {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: #fff !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button::after {
    background: rgba(255, 255, 255, 0.3) !important; }
  .bp3-toast.bp3-intent-primary {
    background-color: #137cbd;
    color: #fff; }
  .bp3-toast.bp3-intent-success {
    background-color: #0f9960;
    color: #fff; }
  .bp3-toast.bp3-intent-warning {
    background-color: #d9822b;
    color: #fff; }
  .bp3-toast.bp3-intent-danger {
    background-color: #db3737;
    color: #fff; }

.bp3-toast-message {
  flex: 1 1 auto;
  padding: 11px; }

.bp3-toast-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 40;
  overflow: hidden;
  padding: 0 20px 20px;
  pointer-events: none; }
  .bp3-toast-container.bp3-toast-container-top {
    top: 0;
    bottom: auto; }
  .bp3-toast-container.bp3-toast-container-bottom {
    flex-direction: column-reverse;
    top: auto;
    bottom: 0; }
  .bp3-toast-container.bp3-toast-container-left {
    align-items: flex-start; }
  .bp3-toast-container.bp3-toast-container-right {
    align-items: flex-end; }

.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active),
.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active) ~ .bp3-toast,
.bp3-toast-container-bottom .bp3-toast.bp3-toast-leave-active ~ .bp3-toast {
  transform: translateY(60px); }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-tooltip {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.2);
  transform: scale(1); }
  .bp3-tooltip .bp3-popover-arrow {
    position: absolute;
    width: 22px;
    height: 22px; }
    .bp3-tooltip .bp3-popover-arrow::before {
      margin: 4px;
      width: 14px;
      height: 14px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip {
    margin-top: -11px;
    margin-bottom: 11px; }
    .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
      bottom: -8px; }
      .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(-90deg); }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip {
    margin-left: 11px; }
    .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
      left: -8px; }
      .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(0); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip {
    margin-top: 11px; }
    .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
      top: -8px; }
      .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(90deg); }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip {
    margin-right: 11px;
    margin-left: -11px; }
    .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
      right: -8px; }
      .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(180deg); }
  .bp3-tether-element-attached-middle > .bp3-tooltip > .bp3-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .bp3-tether-element-attached-center > .bp3-tooltip > .bp3-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
    top: -0.22183px; }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
    right: -0.22183px; }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
    left: -0.22183px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
    bottom: -0.22183px; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-tooltip {
    transform-origin: top left; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-tooltip {
    transform-origin: top center; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-tooltip {
    transform-origin: top right; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-tooltip {
    transform-origin: center left; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-tooltip {
    transform-origin: center center; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-tooltip {
    transform-origin: center right; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-tooltip {
    transform-origin: bottom left; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-tooltip {
    transform-origin: bottom center; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-tooltip {
    transform-origin: bottom right; }
  .bp3-tooltip .bp3-popover-content {
    background: #394b59;
    color: #f5f8fa; }
  .bp3-tooltip .bp3-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2); }
  .bp3-tooltip .bp3-popover-arrow-border {
    fill: #000;
    fill-opacity: 0.1; }
  .bp3-tooltip .bp3-popover-arrow-fill {
    fill: #394b59; }
  .bp3-popover-enter > .bp3-tooltip, .bp3-popover-appear > .bp3-tooltip {
    transform: scale(0.8); }
  .bp3-popover-enter-active > .bp3-tooltip, .bp3-popover-appear-active > .bp3-tooltip {
    transform: scale(1);
    transition-property: transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .bp3-popover-exit > .bp3-tooltip {
    transform: scale(1); }
  .bp3-popover-exit-active > .bp3-tooltip {
    transform: scale(0.8);
    transition-property: transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .bp3-tooltip .bp3-popover-content {
    padding: 10px 12px; }
  .bp3-tooltip.bp3-dark,
  .bp3-dark .bp3-tooltip {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4); }
    .bp3-tooltip.bp3-dark .bp3-popover-content,
    .bp3-dark .bp3-tooltip .bp3-popover-content {
      background: #e1e8ed;
      color: #394b59; }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow::before,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4); }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow-border,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow-border {
      fill: #000;
      fill-opacity: 0.2; }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow-fill,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow-fill {
      fill: #e1e8ed; }
  .bp3-tooltip.bp3-intent-primary .bp3-popover-content {
    background: #137cbd;
    color: #fff; }
  .bp3-tooltip.bp3-intent-primary .bp3-popover-arrow-fill {
    fill: #137cbd; }
  .bp3-tooltip.bp3-intent-success .bp3-popover-content {
    background: #0f9960;
    color: #fff; }
  .bp3-tooltip.bp3-intent-success .bp3-popover-arrow-fill {
    fill: #0f9960; }
  .bp3-tooltip.bp3-intent-warning .bp3-popover-content {
    background: #d9822b;
    color: #fff; }
  .bp3-tooltip.bp3-intent-warning .bp3-popover-arrow-fill {
    fill: #d9822b; }
  .bp3-tooltip.bp3-intent-danger .bp3-popover-content {
    background: #db3737;
    color: #fff; }
  .bp3-tooltip.bp3-intent-danger .bp3-popover-arrow-fill {
    fill: #db3737; }

.bp3-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
Trees

Markup:
<div class="bp3-tree bp3-elevation-0">
  <ul class="bp3-tree-node-list bp3-tree-root">
    <li class="bp3-tree-node bp3-tree-node-expanded">
      <div class="bp3-tree-node-content">
        <span class="bp3-tree-node-caret bp3-tree-node-caret-open bp3-icon-standard"></span>
        <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-folder-close"></span>
        <span class="bp3-tree-node-label">Label</span>
        <span class="bp3-tree-node-secondary-label">Secondary label</span>
      </div>
      <ul class="bp3-tree-node-list">
        <li class="bp3-tree-node">
          <div class="bp3-tree-node-content">
            <span class="bp3-tree-node-caret-none bp3-icon-standard"></span>
            <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-document"></span>
          <span class="bp3-tree-node-label">A Document</span>
          </div>
        </li>
        <li class="bp3-tree-node">
          <div class="bp3-tree-node-content">
            <span class="bp3-tree-node-caret-none bp3-icon-standard"></span>
            <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-document"></span>
            <span class="bp3-tree-node-label">Another Document</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

Styleguide tree
*/
.bp3-tree .bp3-icon, .bp3-tree .bp3-icon-standard, .bp3-tree .bp3-icon-large {
  color: #5c7080; }
  .bp3-tree .bp3-icon.bp3-intent-primary, .bp3-tree .bp3-icon-standard.bp3-intent-primary, .bp3-tree .bp3-icon-large.bp3-intent-primary {
    color: #137cbd; }
  .bp3-tree .bp3-icon.bp3-intent-success, .bp3-tree .bp3-icon-standard.bp3-intent-success, .bp3-tree .bp3-icon-large.bp3-intent-success {
    color: #0f9960; }
  .bp3-tree .bp3-icon.bp3-intent-warning, .bp3-tree .bp3-icon-standard.bp3-intent-warning, .bp3-tree .bp3-icon-large.bp3-intent-warning {
    color: #d9822b; }
  .bp3-tree .bp3-icon.bp3-intent-danger, .bp3-tree .bp3-icon-standard.bp3-intent-danger, .bp3-tree .bp3-icon-large.bp3-intent-danger {
    color: #db3737; }

.bp3-tree-node-list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.bp3-tree-root {
  position: relative;
  background-color: transparent;
  cursor: default;
  padding-left: 0; }

.bp3-tree-node-content-0 {
  padding-left: 0px; }

.bp3-tree-node-content-1 {
  padding-left: 23px; }

.bp3-tree-node-content-2 {
  padding-left: 46px; }

.bp3-tree-node-content-3 {
  padding-left: 69px; }

.bp3-tree-node-content-4 {
  padding-left: 92px; }

.bp3-tree-node-content-5 {
  padding-left: 115px; }

.bp3-tree-node-content-6 {
  padding-left: 138px; }

.bp3-tree-node-content-7 {
  padding-left: 161px; }

.bp3-tree-node-content-8 {
  padding-left: 184px; }

.bp3-tree-node-content-9 {
  padding-left: 207px; }

.bp3-tree-node-content-10 {
  padding-left: 230px; }

.bp3-tree-node-content-11 {
  padding-left: 253px; }

.bp3-tree-node-content-12 {
  padding-left: 276px; }

.bp3-tree-node-content-13 {
  padding-left: 299px; }

.bp3-tree-node-content-14 {
  padding-left: 322px; }

.bp3-tree-node-content-15 {
  padding-left: 345px; }

.bp3-tree-node-content-16 {
  padding-left: 368px; }

.bp3-tree-node-content-17 {
  padding-left: 391px; }

.bp3-tree-node-content-18 {
  padding-left: 414px; }

.bp3-tree-node-content-19 {
  padding-left: 437px; }

.bp3-tree-node-content-20 {
  padding-left: 460px; }

.bp3-tree-node-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-right: 5px; }
  .bp3-tree-node-content:hover {
    background-color: rgba(191, 204, 214, 0.4); }

.bp3-tree-node-caret,
.bp3-tree-node-caret-none {
  min-width: 30px; }

.bp3-tree-node-caret {
  color: #5c7080;
  transform: rotate(0deg);
  cursor: pointer;
  padding: 7px;
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tree-node-caret:hover {
    color: #182026; }
  .bp3-dark .bp3-tree-node-caret {
    color: #bfccd6; }
    .bp3-dark .bp3-tree-node-caret:hover {
      color: #f5f8fa; }
  .bp3-tree-node-caret.bp3-tree-node-caret-open {
    transform: rotate(90deg); }
  .bp3-tree-node-caret.bp3-icon-standard::before {
    content: "\E695"; }

.bp3-tree-node-icon {
  position: relative;
  margin-right: 7px; }

.bp3-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  position: relative;
  user-select: none; }
  .bp3-tree-node-label span {
    display: inline; }

.bp3-tree-node-secondary-label {
  padding: 0 5px;
  user-select: none; }
  .bp3-tree-node-secondary-label .bp3-popover-wrapper,
  .bp3-tree-node-secondary-label .bp3-popover-target {
    display: flex;
    align-items: center; }

.bp3-tree-node.bp3-disabled .bp3-tree-node-content {
  background-color: inherit;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.bp3-tree-node.bp3-disabled .bp3-tree-node-caret,
.bp3-tree-node.bp3-disabled .bp3-tree-node-icon {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #137cbd; }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-standard, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-large {
    color: #fff; }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret::before {
    color: rgba(255, 255, 255, 0.7); }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret:hover::before {
    color: #fff; }

.bp3-dark .bp3-tree-node-content:hover {
  background-color: rgba(92, 112, 128, 0.3); }

.bp3-dark .bp3-tree .bp3-icon, .bp3-dark .bp3-tree .bp3-icon-standard, .bp3-dark .bp3-tree .bp3-icon-large {
  color: #bfccd6; }

.bp3-dark .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #137cbd; }

.upp__sidebar-wrapper {
  width: 280px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -280px;
  z-index: 999;
  transition: left .3s ease, width .3s ease;
  /** HIDE SCROLLBAR: https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll */ }
  .upp__sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .upp__sidebar-wrapper a {
    text-decoration: none;
    transition: color .3s ease; }
  .upp__sidebar-wrapper .upp__sidebar-item {
    transition: all .3s linear; }
  .upp__sidebar-wrapper .upp__sidebar-content {
    max-height: calc(100% -35px);
    height: calc(100% - 35px);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative; }
    .upp__sidebar-wrapper .upp__sidebar-content::-webkit-scrollbar {
      display: none; }
    .upp__sidebar-wrapper .upp__sidebar-content.desktop {
      overflow-y: hidden; }
  .upp__sidebar-wrapper .badge {
    border-radius: 0; }

.upp__sidebar-bg .upp__sidebar-wrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .upp__sidebar-bg .upp__sidebar-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.upp__sidebar-wrapper .upp__sidebar-header {
  padding: 20px;
  overflow: hidden; }
  .upp__sidebar-wrapper .upp__sidebar-header .user-pic {
    width: 60px;
    padding: 2px;
    margin-right: 15px;
    overflow: hidden; }
    .upp__sidebar-wrapper .upp__sidebar-header .user-pic img {
      object-fit: cover;
      height: 100%;
      width: 100%; }
  .upp__sidebar-wrapper .upp__sidebar-header .user-info {
    overflow: hidden; }
    .upp__sidebar-wrapper .upp__sidebar-header .user-info > span {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .upp__sidebar-wrapper .upp__sidebar-header .user-info .user-role {
      font-size: 12px; }
    .upp__sidebar-wrapper .upp__sidebar-header .user-info .user-status {
      font-size: 11px;
      margin-top: 4px; }
      .upp__sidebar-wrapper .upp__sidebar-header .user-info .user-status i {
        font-size: 8px;
        margin-right: 4px;
        color: #5cb85c; }

.upp__sidebar-wrapper .upp__sidebar-menu {
  padding-bottom: 10px; }
  .upp__sidebar-wrapper .upp__sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block; }
  .upp__sidebar-wrapper .upp__sidebar-menu ul li a {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    position: relative;
    padding: 8px 30px 8px 20px;
    width: 100%; }
    .upp__sidebar-wrapper .upp__sidebar-menu ul li a:hover > i::before {
      display: inline-block;
      animation: swing ease-in-out .5s 1 alternate; }
    .upp__sidebar-wrapper .upp__sidebar-menu ul li a i {
      margin-right: 10px;
      font-size: 12px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      flex-shrink: 0; }
    .upp__sidebar-wrapper .upp__sidebar-menu ul li a .menu-text {
      flex-grow: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-shrink: 1;
      overflow: hidden; }
  .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown > a:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\F105";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 14px;
    transition: transform .3s ease; }
  .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown .upp__sidebar-submenu {
    display: none; }
    .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown .upp__sidebar-submenu ul {
      padding: 5px 0; }
    .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown .upp__sidebar-submenu li {
      padding-left: 25px;
      font-size: 13px; }
      .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown .upp__sidebar-submenu li a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown .upp__sidebar-submenu li a:before {
          content: "\F111";
          font-family: "Font Awesome 5 Free";
          font-weight: 400;
          font-style: normal;
          display: inline-block;
          text-align: center;
          text-decoration: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 10px;
          font-size: 8px; }
        .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown .upp__sidebar-submenu li a .badge,
        .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown .upp__sidebar-submenu li a .label {
          margin-left: auto; }
  .upp__sidebar-wrapper .upp__sidebar-menu .upp__sidebar-dropdown.active > a:after {
    transform: rotate(90deg);
    right: 15px; }

.upp__sidebar-wrapper .upp__sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex; }
  .upp__sidebar-wrapper .upp__sidebar-footer > div {
    flex-grow: 1;
    text-align: center;
    height: 35px;
    line-height: 35px;
    position: static;
    display: flex; }
    .upp__sidebar-wrapper .upp__sidebar-footer > div > a {
      flex-grow: 1; }
    .upp__sidebar-wrapper .upp__sidebar-footer > div a .notification {
      position: absolute;
      top: 0; }
    .upp__sidebar-wrapper .upp__sidebar-footer > div.pinned-footer {
      display: none; }
  .upp__sidebar-wrapper .upp__sidebar-footer .dropdown-menu {
    bottom: 36px;
    left: 0 !important;
    top: initial !important;
    right: 0;
    transform: none !important;
    border-radius: 0;
    font-size: .9rem; }

.messages .dropdown-item {
  padding: .25rem 1rem; }

.messages .messages-header {
  padding: 0 1rem; }

.messages .message-content {
  display: flex; }
  .messages .message-content .pic {
    width: 40px;
    height: 40px;
    overflow: hidden; }
    .messages .message-content .pic img {
      object-fit: cover;
      height: 100%; }
  .messages .message-content .content {
    line-height: 1.6;
    padding-left: 5px;
    width: calc(100% - 40px); }
    .messages .message-content .content .message-title {
      font-size: 13px; }
    .messages .message-content .content .message-detail {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.notifications .dropdown-item {
  padding: .25rem 1rem; }

.notifications .notifications-header {
  padding: 0 1rem; }

.notifications .notification-content {
  display: flex; }
  .notifications .notification-content .icon {
    width: 40px;
    height: 40px; }
    .notifications .notification-content .icon i {
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px; }
  .notifications .notification-content .content {
    line-height: 1.6;
    padding-left: 5px;
    width: calc(100% - 40px); }
    .notifications .notification-content .content .notification-time {
      font-size: .7rem;
      color: #828282; }
    .notifications .notification-content .content .notification-detail {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0; }
  .badge-sonar:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: sonar 1.5s infinite; }

.upp__hamburger-container {
  border-right: 1px solid #dee2e6;
  cursor: pointer; }

.upp__hamburger {
  width: 30px;
  height: 20px;
  position: relative;
  display: inline-block;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  .upp__hamburger.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  .upp__hamburger.open span:nth-child(2) {
    opacity: 0;
    left: -60px; }
  .upp__hamburger.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg); }
  .upp__hamburger span {
    position: absolute;
    height: 5px;
    width: 100%;
    background: #f19a2d;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .upp__hamburger span:nth-child(1) {
      top: 0px; }
    .upp__hamburger span:nth-child(2) {
      top: 10px; }
    .upp__hamburger span:nth-child(3) {
      top: 20px; }

.upp__hamburger-menu {
  font-size: 1.4em; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield !important; }

.customPageNumber {
  width: 35px;
  height: 24px;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: middle; }

.customPageNumberContainer {
  height: 20px !important;
  margin-top: 2px;
  padding-top: 1px; }

.goBtn {
  height: 17px !important;
  width: 17px !important;
  overflow: hidden;
  padding: 0 !important; }

.goIcon {
  line-height: 10px; }

.upp__body {
  min-height: 500px; }
  .upp__body .upp__body-top {
    border-bottom: 1px solid #dee2e6;
    min-height: 60px; }
  .upp__body.toggled .upp__sidebar-wrapper {
    left: 0px; }
  @media screen and (min-width: 768px) {
    .upp__body.toggled .upp__body-content {
      padding-left: 280px; } }
  @media screen and (max-width: 768px) {
    .upp__body.toggled .upp__body-content .overlay {
      display: block; } }
  @media screen and (min-width: 768px) {
    .upp__body.toggled.pinned .upp__body-content {
      padding-left: 80px; } }

.upp__body-content {
  padding: 0; }

.upp__login-container {
  margin-top: 70px; }
  .upp__login-container .logo {
    width: 100%;
    height: auto; }

.upp__span-navbar-brand {
  font-weight: 700;
  color: #495057; }

.clickable-thead {
  cursor: pointer !important; }
